export const basePath = process.env.REACT_APP_SERVER_URL
export const scrapServerBasepath = process.env.REACT_APP_SCRAPING_SERVER_HOST
export const app_url = process.env.REACT_APP_APP_URL

export const ANALYTICS_HOST = process.env.REACT_APP_ANALYTICS_HOST

export const paymentBasePath = "https://api.lemonsqueezy.com/v1/";

export const lemon_path = "https://quick-chapters.lemonsqueezy.com/";

// export const lemon_squeezy_store_id = process.env.NODE_ENV == 'development' ? 39021 : 19506
export const lemon_squeezy_store_id = process.env.REACT_APP_LEMONSQUEEZY_STOREID

// export const monthly_packeges = process.env.NODE_ENV == 'development' ? ["107229", "107227", "107228"] : ["112563", "112565", "142660"]
export const monthly_packeges = process.env.REACT_APP_MONTHLY_PACKEGES

// export const annual_packeges = process.env.NODE_ENV == 'development' ? ["108904", "107227", "108906"] : ["112568", "112567", "142672"]
export const annual_packeges = process.env.REACT_APP_ANNUAL_PACKEGES;
export const NEXT_PUBLIC_PATH = process.env.REACT_APP_PORTAL_PATH;

export const apiKey =
  process.env.NODE_ENV === "development"
    ? "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5NGQ1OWNlZi1kYmI4LTRlYTUtYjE3OC1kMjU0MGZjZDY5MTkiLCJqdGkiOiI5ZmY1ODJlMGMxYzI2Y2I2ZTMxN2IzMGM1N2Y2Mjg3ZWVlNTk1NTc4ZTFkYjYxMDY5YmUxNGUwMTMyYmIwODg1ZDAzYzBmMjU3NDZmZTEyMSIsImlhdCI6MTY5MzczNzkzOC43NDg2NTIsIm5iZiI6MTY5MzczNzkzOC43NDg2NTQsImV4cCI6MTcyNTM2MDMzOC43MzE2MTksInN1YiI6Ijg5MTIzMCIsInNjb3BlcyI6W119.x6GpeqXLsXrrirg4ky-xyHvI7wbUE07BVeg6eFKYkO8ggb70_2Ppz7iTbdCFGOH1xPyKz8EDAtpPkvVVfIns876PPxzq7XgRQd_4By2_mxb9JCNJXBVvuLtMOfqukVBqChmJMagDQ7bTOAwOcancnJ_rCuLD8s_6MXtP5knCC8WJJpQ79XtV4Eq2JFvbXwyoZTi2twAxe-1KceaxSpjLaRXxSeacm53taQprP3L6REUPXcPi0nn_yamNkGJKFftWi3w6Mg5aOB0IEr3hAlL0hg7rSzC2InwRLR2pntn5O8U3fjuUMxAYrU8mCVhxjvfb3oWrYktqKgpeb9nqN9TzsDc_OQjbDM-BeHkc7YNqk-LjtFGVB6JErcBshizxVF55wBf5Nj70_lKZh3hgKZ12nqL330JvE8LnDceF0zObAdqNaqw-yNPxvozOlspyLpU2aOzjl5WR-ikFX48NhioUUL4pDb_1rikdCRgL8iCIvNB6V-g4wxm6FDI7lPd0yLcg"
    : "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5NGQ1OWNlZi1kYmI4LTRlYTUtYjE3OC1kMjU0MGZjZDY5MTkiLCJqdGkiOiI5ZmY1ODJlMGMxYzI2Y2I2ZTMxN2IzMGM1N2Y2Mjg3ZWVlNTk1NTc4ZTFkYjYxMDY5YmUxNGUwMTMyYmIwODg1ZDAzYzBmMjU3NDZmZTEyMSIsImlhdCI6MTY5MzczNzkzOC43NDg2NTIsIm5iZiI6MTY5MzczNzkzOC43NDg2NTQsImV4cCI6MTcyNTM2MDMzOC43MzE2MTksInN1YiI6Ijg5MTIzMCIsInNjb3BlcyI6W119.x6GpeqXLsXrrirg4ky-xyHvI7wbUE07BVeg6eFKYkO8ggb70_2Ppz7iTbdCFGOH1xPyKz8EDAtpPkvVVfIns876PPxzq7XgRQd_4By2_mxb9JCNJXBVvuLtMOfqukVBqChmJMagDQ7bTOAwOcancnJ_rCuLD8s_6MXtP5knCC8WJJpQ79XtV4Eq2JFvbXwyoZTi2twAxe-1KceaxSpjLaRXxSeacm53taQprP3L6REUPXcPi0nn_yamNkGJKFftWi3w6Mg5aOB0IEr3hAlL0hg7rSzC2InwRLR2pntn5O8U3fjuUMxAYrU8mCVhxjvfb3oWrYktqKgpeb9nqN9TzsDc_OQjbDM-BeHkc7YNqk-LjtFGVB6JErcBshizxVF55wBf5Nj70_lKZh3hgKZ12nqL330JvE8LnDceF0zObAdqNaqw-yNPxvozOlspyLpU2aOzjl5WR-ikFX48NhioUUL4pDb_1rikdCRgL8iCIvNB6V-g4wxm6FDI7lPd0yLcg";


export const APIurls = {
    // auth
    login: "auth/login",
    register: "auth/register",
    authenticate: "auth/authenticate",
    logout: "auth/logout",
    reset: "auth/reset-password",
    forget: "auth/forgot-password",
    history: "scrap-history",
    refreshTokens: "/auth/refresh-tokens",
    user: "/users",
    subscription: "/subscription",
    contact: "/contact",
    downloads:"/accounts",
    profileScraps:"/scrap-history",
    settings:'/settings'
  };


export const paymentAPIurls = {
  getStores: "stores",
  getProducts: "products",
  checkouts: "checkouts",
  customers: "customers",
};
