import React, { useEffect, useState } from 'react';

import questionInfoIcon from '../assets/images/icons/info-question-mark-icon.png';
import cameraYellowIcon from '../assets/images/icons/camera-yelloe-icon.png';
import videoTapeIcon from '../assets/images/icons/video-tape-icon.png';
import bagBlueIcon from '../assets/images/icons/bag-blue-icon.png';
import playGreenIcon from '../assets/images/icons/play-green-icon.png';
import smsPurpleIcon from '../assets/images/icons/sms-purple-icon.png';
import shareGreenIcon from '../assets/images/icons/share-green-icon.png';
import bookmarkIcon from '../assets/images/icons/bookmark-cyan-icon.png';
import heartIcon from '../assets/images/icons/heart-red-icon.png';
import ShortenNumber from '../utilities/ShortenNumber';
import TotalVideosChart from './TotalVideosChart';
import VideoPerDaySmallChart from './VidePerDaySmallChart';
import VideoPerWeekSmallChart from './VidePerWeekSmallChart';
import VideoPerMonthSmallChart from './VidePerMonthSmallChart';
import TotalCommmentsChart from './TotalComentsChart30d';
import TotalSharesChart from './TotalSharesChart30e';
import TotalBookMarksChart from './TotalBookmarksChart30d';
import TotalLikesChart from './TotalLikesChart30d';
import SliderChartsModal from './SliderChartsModal30d';
import Spinner from 'react-bootstrap/Spinner';
import captureIcon from "../assets/images/icons/capture-icon.png";

export default function DashboardAverageCards({ analyticsData, analyticsDataLoading }) {
    const [totalVideosLast30Days, setTotalVideosLast30Days] = useState(0);
    const [averageVideosPerDay, setAverageVideosPerDay] = useState(0);
    const [averageVideosPerWeek, setAverageVideosPerWeek] = useState(0);
    const [averageVideosPerMonth, setAverageVideosPerMonth] = useState(0);
    const [averageCommentsPerVideo, setAverageCommentsPerVideo] = useState(0);
    const [averageLikesPerVideo, setAverageLikesPerVideo] = useState(0);
    const [averageSharesPerVideo, setAverageSharesPerVideo] = useState(0);
    const [averageBookmarksPerVideo, setAverageBookmarksPerVideo] = useState(0);
    const [sliderModalShow, setSliderModalShow] = useState(false);

    useEffect(() => {
        if (!analyticsData) return;

        const videosTimeseries = analyticsData?.videos_timeseries || [];
        const commentsTimeseries = analyticsData?.comments_timeseries || [];
        const likesTimeseries = analyticsData?.likes_timeseries || [];
        const sharesTimeseries = analyticsData?.shares_timeseries || [];
        const bookmarksTimeseries = analyticsData?.bookmarks_timeseries || [];
        const getTotalVideosInLast30Days = () => {
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Reset the time to midnight to avoid any time-related issues

            const last30DaysVideosData = videosTimeseries.filter(data => {
                const datePosted = new Date(data['Date Posted']);
                datePosted.setHours(0, 0, 0, 0); // Reset the time to midnight for accurate day comparison

                // Calculate the difference in days
                const diffTime = today - datePosted;
                const diffDays = diffTime / (1000 * 60 * 60 * 24);

                // Include videos posted within the last 30 days
                return diffDays >= 0 && diffDays <= 30;
            });

            return last30DaysVideosData.reduce((total, data) => total + data.Videos, 0);
        };


        const getAverageVideosPerDay = () => {
            if (!videosTimeseries.length) return 0;

            const firstDate = new Date(videosTimeseries[0]['Date Posted']);
            const lastDate = new Date(videosTimeseries[videosTimeseries.length - 1]['Date Posted']);
            const diffTime = Math.abs(lastDate - firstDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; // +1 to include both start and end dates

            const totalVideos = videosTimeseries.reduce((total, data) => total + data.Videos, 0);
            return totalVideos / diffDays;
        };

        const getAverageVideosPerMonth = () => {
            const videosPerMonthData = videosTimeseries.reduce((acc, data) => {
                const monthYear = data['Date Posted'].substring(0, 7); // Extracting year and month
                acc[monthYear] = (acc[monthYear] || 0) + data.Videos;
                return acc;
            }, {});
            const totalMonths = Object.keys(videosPerMonthData).length;
            const totalVideos = Object.values(videosPerMonthData).reduce((total, videos) => total + videos, 0);
            return totalVideos / totalMonths;
        };

        const getAverageVideosPerWeek = () => {
            const videosPerWeekData = videosTimeseries.reduce((acc, data) => {
                const date = new Date(data['Date Posted']);
                const week = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-W${Math.ceil((date.getDate() + 6 - date.getDay()) / 7)}`;
                acc[week] = (acc[week] || 0) + data.Videos;
                return acc;
            }, {});
            const totalWeeks = Object.keys(videosPerWeekData).length;
            const totalVideos = Object.values(videosPerWeekData).reduce((total, videos) => total + videos, 0);
            return totalVideos / totalWeeks;
        };

        const getAverageCommentsPerVideo = () => {
            const totalComments = commentsTimeseries.reduce((total, data) => total + data.Comments, 0);
            const totalVideos = commentsTimeseries.length;
            return totalComments / totalVideos;
        };

        const getAverageLikesPerVideo = () => {
            const totalLikes = likesTimeseries.reduce((total, data) => total + data.Likes, 0);
            const totalVideos = likesTimeseries.length;
            return totalLikes / totalVideos;
        };

        const getAverageSharesPerVideo = () => {
            const totalShares = sharesTimeseries.reduce((total, data) => total + data.Shares, 0);
            const totalVideos = sharesTimeseries.length;
            return totalShares / totalVideos;
        };

        const getAverageBookmarksPerVideo = () => {
            const totalBookmarks = bookmarksTimeseries.reduce((total, data) => total + data.Bookmarks, 0);
            const totalVideos = bookmarksTimeseries.length;
            return totalBookmarks / totalVideos;
        };

        const totalVideosLast30Days = getTotalVideosInLast30Days();
        const averageVideosPerDay = getAverageVideosPerDay(totalVideosLast30Days);
        const averageVideosPerWeek = getAverageVideosPerWeek();
        const averageVideosPerMonth = getAverageVideosPerMonth();
        const averageCommentsPerVideo = getAverageCommentsPerVideo();
        const averageLikesPerVideo = getAverageLikesPerVideo();
        const averageSharesPerVideo = getAverageSharesPerVideo();
        const averageBookmarksPerVideo = getAverageBookmarksPerVideo();

        setTotalVideosLast30Days(totalVideosLast30Days);
        setAverageVideosPerDay(Math.ceil(averageVideosPerDay));
        setAverageVideosPerMonth(Math.ceil(averageVideosPerMonth));
        setAverageVideosPerWeek(Math.ceil(averageVideosPerWeek));
        setAverageCommentsPerVideo(Math.ceil(averageCommentsPerVideo));
        setAverageLikesPerVideo(Math.ceil(averageLikesPerVideo));
        setAverageSharesPerVideo(Math.ceil(averageSharesPerVideo));
        setAverageBookmarksPerVideo(Math.ceil(averageBookmarksPerVideo));
    }, [analyticsData]);

    return (
        <>
            <div className="average-text-div-wrapper mb-3">
                <div className="d-flex gap-4">
                    <strong className="white">Average</strong>
                    {analyticsDataLoading && <span className='white d-inline-flex gap-2'>  <Spinner animation="grow" size="sm" /><span className=' d-inline-block white'>Analyzing...</span></span>}

                </div>
                <button onClick={() => setSliderModalShow(true)}><img src={captureIcon} alt="captureIcon"
                    className='captureIcon' /></button>
            </div>
            <div className='dashboard-average-cards-wrapper'>
                <div className="average-card">
                    <div className="title">
                        <img src={cameraYellowIcon} alt="cameraYellowIcon" />
                        <span className="ex-small">Total Videos (Last30 days)</span>
                    </div>
                    <h2>{ShortenNumber(totalVideosLast30Days)}</h2>
                    {/* <div className="average-text">
                        <div className="dot"></div>
                        <span className="ex-small">Average</span>
                        <img src={questionInfoIcon} alt="questionInfoIcon" />
                    </div> */}
                    <TotalVideosChart analyticsData={analyticsData} />
                </div>
                <div className="average-card">
                    <div className="title">
                        <img src={videoTapeIcon} alt="cameraYellowIcon" />
                        <span className="ex-small">Videos per day</span>
                    </div>
                    <h2>{ShortenNumber(averageVideosPerDay)}</h2>
                    {/* <div className="average-text">
                        <div className="dot"></div>
                        <span className="ex-small">Average</span>
                        <img src={questionInfoIcon} alt="questionInfoIcon" />
                    </div> */}
                    <VideoPerDaySmallChart analyticsData={analyticsData} />
                </div>
                <div className="average-card">
                    <div className="title">
                        <img src={bagBlueIcon} alt="cameraYellowIcon" />
                        <span className="ex-small">Videos per week</span>
                    </div>
                    <h2>{ShortenNumber(averageVideosPerWeek)}</h2>
                    {/* <div className="average-text">
                        <div className="dot"></div>
                        <span className="ex-small">Average</span>
                        <img src={questionInfoIcon} alt="questionInfoIcon" />
                    </div> */}
                    <VideoPerWeekSmallChart analyticsData={analyticsData} />

                </div>
                <div className="average-card">
                    <div className="title">
                        <img src={playGreenIcon} alt="playGreenIcon" />
                        <span className="ex-small">Videos per month</span>
                    </div>
                    <h2>{ShortenNumber(averageVideosPerMonth)}</h2>
                    {/* <div className="average-text">
                        <div className="dot"></div>
                        <span className="ex-small">Average</span>
                        <img src={questionInfoIcon} alt="questionInfoIcon" />
                    </div> */}
                    <VideoPerMonthSmallChart analyticsData={analyticsData} />
                </div>
                <div className="average-card">
                    <div className="title">
                        <img src={smsPurpleIcon} alt="smsPurpleIcon" />
                        <span className="ex-small">Comments per video</span>
                    </div>
                    <h2>{ShortenNumber(averageCommentsPerVideo)}</h2>
                    {/* <div className="average-text">
                        <div className="dot"></div>
                        <span className="ex-small">Average</span>
                        <img src={questionInfoIcon} alt="questionInfoIcon" />
                    </div> */}
                    <TotalCommmentsChart analyticsData={analyticsData} />
                </div>
                <div className="average-card">
                    <div className="title">
                        <img src={shareGreenIcon} alt="shareGreenIcon" />
                        <span className="ex-small">Shares per video</span>
                    </div>
                    <h2>{ShortenNumber(averageSharesPerVideo)}</h2>
                    {/* <div className="average-text">
                        <div className="dot"></div>
                        <span className="ex-small">Average</span>
                        <img src={questionInfoIcon} alt="questionInfoIcon" />
                    </div> */}
                    <TotalSharesChart analyticsData={analyticsData} />
                </div>
                <div className="average-card">
                    <div className="title">
                        <img src={bookmarkIcon} alt="bookmarkIcon" />
                        <span className="ex-small">Bookmarks per video</span>
                    </div>
                    <h2>{ShortenNumber(averageBookmarksPerVideo)}</h2>
                    {/* <div className="average-text">
                        <div className="dot"></div>
                        <span className="ex-small">Average</span>
                        <img src={questionInfoIcon} alt="questionInfoIcon" />
                    </div> */}
                    <TotalBookMarksChart analyticsData={analyticsData} />
                </div>
                <div className="average-card">
                    <div className="title">
                        <img src={heartIcon} alt="heartIcon" />
                        <span className="ex-small">Likes per video</span>
                    </div>
                    <h2>{ShortenNumber(averageLikesPerVideo)}</h2>
                    {/* <div className="average-text">
                        <div className="dot"></div>
                        <span className="ex-small">Average</span>
                        <img src={questionInfoIcon} alt="questionInfoIcon" />
                    </div> */}
                    <TotalLikesChart analyticsData={analyticsData} />
                </div>
            </div>
            <SliderChartsModal sliderModalShow={sliderModalShow} setSliderModalShow={setSliderModalShow}
                analyticsData={analyticsData} totalVideosPerDay={averageVideosPerDay}
                totalVideosPerMonth={averageVideosPerMonth} totalVideosPerWeek={averageVideosPerWeek} totalVideosLast30Days={ShortenNumber(totalVideosLast30Days) } averageCommentsPerVideo={averageCommentsPerVideo} averageSharesPerVideo={averageSharesPerVideo} averageBookmarksPerVideo={averageBookmarksPerVideo} averageLikesPerVideo={averageLikesPerVideo}/>
        </>
    );
}
