import React from 'react';
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaFileDownload } from "react-icons/fa";
import { HiMiniBellAlert } from "react-icons/hi2";
import { AiFillThunderbolt } from "react-icons/ai";
import { RiWallet3Fill } from "react-icons/ri";
import { socket } from '../components/HeaderComponent';
export default function NotificationsComponent({ notificationActive, data,isNewFeed, setIsNewFeed, loadingNotifications,paginationModal, setPaginationModal, hasMore }) {
    const  markAllRead = () => {
        // socket.emit("check_all_notifications");
    }
    function getTimeAgo(timestamp) {
    const now = new Date();
    const time = new Date(timestamp);

    const seconds = Math.floor((now - time) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) {
        return `${seconds} secs ago`;
    } else if (minutes < 60) {
        return `${minutes} mins ago`;
    } else if (hours < 24) {
        return `${hours} hrs ago`;
    } else if (days < 30) {
        return `${days} days ago`;
    } else if (months < 12) {
        return `${months} months ago`;
    } else {
        return `${years} yrs ago`;
    }
}
    return (
        <div className={`notifications-main-wrapper ${notificationActive ? 'active' : ''} `}>
            <div className="notifications-div">
                <div className="title">
                    <strong className="white">Notifications {isNewFeed && <span className="total-notifications">{data?.find((feed) => feed.isRead === false)?.length}</span>}
                    </strong>
                    <div className="button">
                        <button onClick={() => markAllRead()}>Mark all as read</button>
                        <button><BsThreeDotsVertical /></button>
                    </div>
                </div>
                <div className="notifications-wrapper">
                    {/* <div className="notification active">
                        <div className="img-wrapper">
                            <FaFileDownload/>
                        </div>
                        <div className="content">
                            <strong className='white'>Download Ready</strong>
                            <span className=''>Your video download is ready! <a href="#">Click here</a> to download your videos now.</span>
                            <span className='strong'>8 min ago</span>
                        </div>
                    </div>
                    <div className="notification active">
                        <div className="img-wrapper">
                            <HiMiniBellAlert/>
                        </div>
                        <div className="content">
                            <strong className='white'>Subscription Reminder</strong>
                            <span className=''>Your subscription is about to expire in 3 days. Renew now to keep enjoying uninterrupted access to all features!</span>
                            <span className='strong'>30 min ago</span>
                        </div>
                    </div>
                    <div className="notification">
                        <div className="img-wrapper">
                            <AiFillThunderbolt/>
                        </div>
                        <div className="content">
                            <strong className='white'>Feature Updates</strong>
                            <span className=''>We've added new features to help you track your video performance better. Check them out!</span>
                            <span className='strong'>30 min ago</span>
                        </div>
                    </div>
                    <div className="notification">
                        <div className="img-wrapper">
                            <RiWallet3Fill/>
                        </div>
                        <div className="content">
                            <strong className='white'>Successful Payment Confirmation</strong>
                            <span className=''>Thank you for your payment! Your TokBackup Unlimited plan subscription is now active.</span>
                            <span className='strong'>30 min ago</span>
                        </div>
                    </div>
                    <div className="notification">
                        <div className="img-wrapper">
                            <FaFileDownload/>
                        </div>
                        <div className="content">
                            <strong className='white'>Download Failed</strong>
                            <span className=''>Oops! There was a problem with your download. Please try again.</span>
                            <span className='strong'>30 min ago</span>
                        </div>
                    </div> */}
                    {
                      data?.length > 0 ?  data?.map((item, index) => {
                            return (
                                <div key={index + 1} className="notification active">
                                    <div className="img-wrapper">
                                        <FaFileDownload />
                                    </div>
                                    <div className="content">
                                        <strong className='white'>{item?.title}</strong>
                                        <span className='' dangerouslySetInnerHTML={{ __html: item?.content }}></span>
                                        <span className='strong'>{getTimeAgo(item?.createdAt)}</span>
                                    </div>
                                </div>
                            )
                        }) : loadingNotifications ? <p style={{textAlign:'center', marginTop:10}}>Loading...</p> : <p style={{textAlign:'center', marginTop:10}}>No notifications</p>
                    }
                    <div className="load-more-wrapper mt-4 text-center">
                    {hasMore && <a style={{cursor:'pointer' }} className='btn-style small ' onClick={() => setPaginationModal({page: paginationModal?.page + 1, pageLimit:paginationModal?.pageLimit})}>{loadingNotifications ? 'Loading' : 'Load more'}</a>}
                    </div>
                </div>
            </div>

        </div>
    )
}