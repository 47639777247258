import React from "react";
import { Line } from 'react-chartjs-2';
export default function DoubleLineChart({margin}){

    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Dataset 1',
                data: [65, 59, 80, 81, 56, 55, 40],
                borderColor: 'rgba(25, 31, 47, 1)',
                backgroundColor: 'rgba(0,0,0,0)',
                fill: true,
                tension: 0.4, // Smooth curve,
                 pointRadius: 0, // Hide points
            },
            {
                label: 'Dataset 2',
                data: [28, 48, 40, 19, 86, 27, 90],
                borderColor: 'rgb(69, 78, 93)',
                backgroundColor: 'rgba(0,0,0,0)',
                fill: true,
                tension: 0.4, // Smooth curve,
                 pointRadius: 0, // Hide points
            },
        ],
    };

    const options = {
        maintainAspectRatio: true,
        aspectRatio: 0,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                display: false, // Hide X-axis labels
                title: {
                    display: false,
                    text: 'Months',
                },
            },
            y: {
                display: false, // Hide X-axis labels
                title: {
                    display: false,
                    text: 'Value',
                },
                beginAtZero: true,
            },
        },
    };

    return(
        <div className='double-line-chart' style={{background: 'rgba(39, 46, 62, 1)', ...(margin ? { margin: '-20px 0 0 47px',  width: '91%'} : {})}} >
            <Line data={data} options={options} />;
        </div>
    )
}