// import React, { useEffect, useState } from 'react';
// import Chart from 'chart.js/auto'; // Import Chart object

// export default function VideoPerWeekSmallChart({analyticsData  }) {

//     const [perWeekVideosSeries, setPerWeekVideosSeries] = useState([])
//     useEffect(() => {
//         if (!analyticsData) return;
        
//         const likesTimeSeries = analyticsData?.videos_timeseries || [];

//         // Function to process per week data
//         const processPerWeekData = (likesTimeSeries) => {
//             let weeklyData = {};

//             likesTimeSeries.forEach((likes) => {
//                 let date = new Date(likes['Date Posted']);
//                 let weekStart = new Date(date.setDate(date.getDate() - date.getDay())).toLocaleDateString('default', {
//                     year: 'numeric',
//                     month: 'short',
//                     day: 'numeric'
//                 });

//                 if (!weeklyData[weekStart]) {
//                     weeklyData[weekStart] = { 'Date Posted': weekStart, Videos: 0 };
//                 }
//                 weeklyData[weekStart].Videos += likes.Videos || 0;
//             });

//             return Object.values(weeklyData);
//         };

//         const processedWeeklyLikesTimeSeries = processPerWeekData(likesTimeSeries);
//         console.log('processedWeeklyLikesTimeSeries', processedWeeklyLikesTimeSeries)
//         setPerWeekVideosSeries(processedWeeklyLikesTimeSeries);

//     }, [analyticsData]);

//     useEffect(() => {
//         // Create gradient
//         const canvas = document.getElementById('VideoPerWeekSmallChart');
//         const ctx = canvas.getContext('2d');
//         const gradient = ctx.createLinearGradient(0, 0, 0, 100); // Adjust according to your chart size
//         gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
//         gradient.addColorStop(0, 'rgba(49, 43, 83, 1)'); // A djust as needed

//         // Apply gradient as background color
//         const chartInstance = new Chart(ctx, {
//             type: 'line',
//             data: {
//                 labels: perWeekVideosSeries.map((share) => share['Date Posted']),
//                 datasets: [
//                     {
//                         label: 'Dataset',
//                         backgroundColor: gradient,
//                         borderColor: '#BA88FF',
//                         borderWidth: 2,
//                         fill: true,
//                         pointBackgroundColor: 'rgba(0, 0, 0, 0)',
//                         pointBorderColor: 'rgba(0, 0, 0, 0)',
//                         pointHoverBackgroundColor: '#fff',
//                         pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
//                         // data: [30,15,40,20,35,30,40]
//                         data: perWeekVideosSeries?.map((like) =>  (like?.Videos?.toFixed(0))),
//                         lineTension: 0.4 // Adjust the tension value for smoothness
//                     }
//                 ]
//             },
//             options: {
//                 plugins: {
//                     legend: {
//                         display: false,
//                     }
//                 },
//                 scales: {
//                     // yAxes: [{
//                     //     ticks: {
//                     //         beginAtZero: true
//                     //     }
//                     // }],
//                     x: {
//                         ticks: {
//                             display: false // Hide the ticks (labels) on the x-axis
//                         },
//                         grid: {
//                             display: false
//                         }
//                     },
//                     y: {
//                         ticks: {
//                             display: false, // Hide the ticks (labels) on the x-axis
//                             beginAtZero: true
//                         },
//                         grid: {
//                             display: false
//                         }
//                     }
//                 }
//             }
//         });

//         return () => {
//             chartInstance.destroy(); // Cleanup chart instance when component unmounts
//         };
//     }, [analyticsData, perWeekVideosSeries]);

//     return (
//         <div className='graph-chart-wrapper'>
//             <canvas id="VideoPerWeekSmallChart" height={25} width='100%' />
//         </div>
//     );
// }




import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto'; // Import Chart object

export default function VideoPerWeekSmallChart({ analyticsData }) {

    const [perWeekVideosSeries, setPerWeekVideosSeries] = useState([]);

    useEffect(() => {
        if (!analyticsData) return;

        const likesTimeSeries = analyticsData?.videos_timeseries || [];

        // Helper function to get the start date of the week
        function getWeekStartDate(date) {
            const day = date.getDay();
            const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
            return new Date(date.setDate(diff));
        }

        // Function to process per week data
        const processPerWeekData = (likesTimeSeries) => {
            let weeklyData = {};

            // Calculate the date three months ago
            const mostRecentDate = new Date(Math.max(...likesTimeSeries.map(likes => new Date(likes['Date Posted']))));
            const threeMonthsAgo = new Date(mostRecentDate.setMonth(mostRecentDate.getMonth() - 3));

            // Filter likesTimeSeries to include only the last 3 months
            const filteredTimeSeries = likesTimeSeries.filter(likes => new Date(likes['Date Posted']) >= threeMonthsAgo);

            filteredTimeSeries.forEach((likes) => {
                let date = new Date(likes['Date Posted']);
                let weekStart = getWeekStartDate(new Date(date.getTime()));
                let weekStartFormatted = weekStart.toLocaleDateString('default', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                });

                if (!weeklyData[weekStartFormatted]) {
                    weeklyData[weekStartFormatted] = { 'Date Posted': weekStartFormatted, Videos: 0 };
                }
                weeklyData[weekStartFormatted].Videos += likes.Videos || 0;
            });

            return Object.values(weeklyData);
        };

        const processedWeeklyLikesTimeSeries = processPerWeekData(likesTimeSeries);
        console.log('processedWeeklyLikesTimeSeries', processedWeeklyLikesTimeSeries);
        setPerWeekVideosSeries(processedWeeklyLikesTimeSeries);

    }, [analyticsData]);

    useEffect(() => {
        // Create gradient
        const canvas = document.getElementById('VideoPerWeekSmallChart');
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 100); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, '#0DABEA66'); // Adjust as needed
        // rgba(49, 43, 83, 1)

        // Apply gradient as background color
        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: perWeekVideosSeries.map((share) => share['Date Posted']),
                datasets: [
                    {
                        label: 'Dataset',
                        backgroundColor: gradient,
                        borderColor: '#0DABEA',
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
                        data: perWeekVideosSeries?.map((like) => (like?.Videos?.toFixed(0))),
                        lineTension: 0.4 // Adjust the tension value for smoothness
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false, // Disable the tooltip
                    },
                },
                scales: {
                    x: {
                        ticks: {
                            display: false // Hide the ticks (labels) on the x-axis
                        },
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        ticks: {
                            display: false, // Hide the ticks (labels) on the y-axis
                            beginAtZero: true
                        },
                        grid: {
                            display: false
                        }
                    }
                }
            }
        });

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [analyticsData, perWeekVideosSeries]);

    return (
        <div className='graph-chart-wrapper'>
            <canvas id="VideoPerWeekSmallChart" height={25} width='100%' />
        </div>
    );
}
