import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto'; // Import Chart object
import ShortenNumber from '../utilities/ShortenNumber';

export default function TotalCommmentsChart({ analyticsData }) {

    const [commentsTimeSeries, setCommentsTimeSeries] = useState([])
    // useEffect(() => {
    //   if (!analyticsData) return
    //   var commentsTimeSeries = (analyticsData?.comments_timeseries)
    //   //if commentsTimeSeries length is bigger than 100, calculate the total views  for each month, instad hcing the data for each day 
    //   if (commentsTimeSeries?.length > 100) {
  
    //     var commentsTimeSeriesByMonth = {}
    //     commentsTimeSeries.forEach((comments) => {
  
    //       var date = new Date(comments['Date Posted'])
    //       var month = date.getMonth()
    //       //get month word 
    //       month = new Date(date.getFullYear(), month, 1).toLocaleString('default', { month: 'short' })
    //       var year = date.getFullYear()
    //       var monthYear = month + '-' + year
    //       if (commentsTimeSeriesByMonth[year]) {
    //         commentsTimeSeriesByMonth[year].Comments += comments.Comments
    //       } else {
    //         commentsTimeSeriesByMonth[year] = {
    //           Comments: comments.Comments,
    //           'Date Posted': year
    //         }
    //       }
    //     }
    //     )
  
    //     commentsTimeSeries = Object.values(commentsTimeSeriesByMonth)
    //   }
    //   setCommentsTimeSeries(commentsTimeSeries)
  
  
  
    // }, [analyticsData])

    useEffect(() => {
        if (!analyticsData) return;
        let commentsTimeSeries = analyticsData?.comments_timeseries;
      
        // If commentsTimeSeries length is greater than 100, calculate the total comments for each month
        if (commentsTimeSeries?.length > 100) {
          const commentsTimeSeriesByMonth = {};
      
          commentsTimeSeries.forEach((comments) => {
            const date = new Date(comments['Date Posted']);
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.getFullYear();
            const monthYear = `${month}-${year}`;
      
            if (commentsTimeSeriesByMonth[monthYear]) {
              commentsTimeSeriesByMonth[monthYear].Comments += comments.Comments;
            } else {
              commentsTimeSeriesByMonth[monthYear] = {
                Comments: comments.Comments,
                'Date Posted': monthYear
              };
            }
          });
      
          commentsTimeSeries = Object.values(commentsTimeSeriesByMonth);
        }
      
        setCommentsTimeSeries(commentsTimeSeries);
      }, [analyticsData]);
      
    useEffect(() => {
        // Create gradient
        const canvas = document.getElementById('totalComments');
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 100); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, '#A162F700'); // A djust as needed
        // rgba(27, 60, 65, 1)

        // Apply gradient as background color
        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                // labels: [10,20,30,40,50,60,70],
                labels: commentsTimeSeries?.map((share) => share['Date Posted']),
                datasets: [
                    {
                        label: 'Dataset',
                        backgroundColor: gradient,
                        borderColor: '#A162F7',
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
                        // data: [20,40,30,25,20,30,20],
                        data: commentsTimeSeries?.map((comment) => (comment?.Comments?.toFixed(0))),
                        lineTension: 0.4 // Adjust the tension value for smoothness
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false, // Disable the tooltip
                    },
                },
                scales: {
                    // yAxes: [{
                    //     ticks: {
                    //         beginAtZero: true
                    //     }
                    // }],
                    x: {
                        ticks: {
                            display: false // Hide the ticks (labels) on the x-axis
                        },
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        ticks: {
                            display: false, // Hide the ticks (labels) on the x-axis
                            beginAtZero: true,
                            callback: function (value) {
                                return ShortenNumber(value);
                            },
                        },
                        grid: {
                            display: false
                        }
                    }
                }
            }
        });

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [analyticsData, commentsTimeSeries]);

    return (
        <div className='graph-chart-wrapper'>
            <canvas id="totalComments" height={25} width='100%' />
        </div>
    );
}