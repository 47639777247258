import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto'; // Import Chart object
import ShortenNumber from '../utilities/ShortenNumber';

export default function TotalVideosChart({ analyticsData }) {
    const [sharesTimeSeries, setSharesTimeSeries] = useState([])
    useEffect(() => {
      if (!analyticsData) return
      var sharesTimeSeries = (analyticsData?.videos_timeseries)
      //if sharesTimeSeries length is bigger than 100, calculate the total views  for each month, instad hcing the data for each day 
      if (sharesTimeSeries?.length > 100) {
  
        var sharesTimeSeriesByMonth = {}
        sharesTimeSeries.forEach((shares) => {
  
          var date = new Date(shares['Date Posted'])
          var month = date.getMonth()
          //get month word 
          month = new Date(date.getFullYear(), month, 1).toLocaleString('default', { month: 'short' })
          var year = date.getFullYear()
          var monthYear = month + '-' + year
          if (sharesTimeSeriesByMonth[year]) {
            sharesTimeSeriesByMonth[year].Videos += shares.Videos
          } else {
            sharesTimeSeriesByMonth[year] = {
                Videos: shares.Videos,
              'Date Posted': year
            }
          }
        }
        )
  
        sharesTimeSeries = Object.values(sharesTimeSeriesByMonth)
      }
      setSharesTimeSeries(sharesTimeSeries)
  
  
  
    }, [analyticsData])

    useEffect(() => {
        // Create gradient
        const canvas = document.getElementById('totalVideos');
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 100); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, 'rgba(231, 173, 90, .4)'); // A djust as needed

        // Apply gradient as background color
        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: sharesTimeSeries?.map((share) => share['Date Posted']),
                datasets: [
                    {
                        label: 'My second dataset',
                        backgroundColor: gradient,
                        borderColor: 'rgba(231, 173, 90, 1)',
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
                        // data: [20,15,30,40,34,30,20],
                        data: sharesTimeSeries?.map((share) => (share?.Videos?.toFixed(0))),
                        lineTension: 0.4 // Adjust the tension value for smoothness
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false, // Disable the tooltip
                    },
                },
                scales: {
                    // yAxes: [{
                    //     ticks: {
                    //         beginAtZero: true
                    //     }
                    // }],
                    x: {
                        ticks: {
                            display: false, // Hide the ticks (labels) on the x-axis
                        },
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        ticks: {
                            display: false, // Hide the ticks (labels) on the x-axis
                            beginAtZero: true,
                            callback: function (value) {
                                return ShortenNumber(value);
                            },
                        },
                        grid: {
                            display: false
                        }
                    }
                }
            }
        });

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [analyticsData,sharesTimeSeries]);

    return (
        <div className='graph-chart-wrapper'>
            <canvas id="totalVideos" height={25} width='100%' />
        </div>
    );
}