import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto'; // Import Chart object
import ShortenNumber from '../utilities/ShortenNumber';

export default function TotalViewChart({ analyticsData }) {
    

  const [viewsTimeSeries, setViewsTimeSeries] = useState([])
  useEffect(() => {
    if (!analyticsData) return
    var viewsTimeSeries = (analyticsData?.views_timeseries)
    //if viewsTimeSeries length is bigger than 100, calculate the total views  for each month, instad hcing the data for each day 
    if (viewsTimeSeries?.length > 100) {

      var viewsTimeSeriesByMonth = {}
      viewsTimeSeries.forEach((views) => {

        var date = new Date(views['Date Posted'])
        var month = date.getMonth()
        //get month word 
        month = new Date(date.getFullYear(), month, 1).toLocaleString('default', { month: 'short' })
        var year = date.getFullYear()
        var monthYear = month + '-' + year
        if (viewsTimeSeriesByMonth[monthYear]) {
          viewsTimeSeriesByMonth[monthYear].Views += views.Views
        } else {
          viewsTimeSeriesByMonth[monthYear] = {
            Views: views.Views,
            'Date Posted': monthYear
          }
        }
      }
      )
      viewsTimeSeries = Object.values(viewsTimeSeriesByMonth)
    }
    setViewsTimeSeries(viewsTimeSeries)



  }, [analyticsData])


    useEffect(() => {
        // Create gradient
        const canvas = document.getElementById('myChart');
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 100); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, 'rgba(66, 30, 56, 1)'); // A djust as needed

        // Apply gradient as background color
        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: viewsTimeSeries?.map((share) => share['Date Posted']),
                datasets: [
                    {
                        label: 'Dataset',
                        backgroundColor: gradient,
                        borderColor: 'rgba(255, 22, 93, 1)',
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
                        data: viewsTimeSeries?.map((views) => (views?.Views?.toFixed(0))),
                        lineTension: 0.4 // Adjust the tension value for smoothness
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    // yAxes: [{
                    //     ticks: {
                    //         beginAtZero: true
                    //     }
                    // }],
                    x: {
                        ticks: {
                            display: false, // Hide the ticks (labels) on the x-axis
                            
                        },
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        ticks: {
                            display: false, // Hide the ticks (labels) on the x-axis
                            beginAtZero: true,
                            callback: function (value) {
                                return ShortenNumber(value);
                            },
                        },
                        grid: {
                            display: false
                        }
                    }
                }
            }
        });

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [analyticsData, viewsTimeSeries]);

    return (
        <div className='graph-chart-wrapper'>
            <canvas id="myChart" height={25} width='100%' />
        </div>
    );
}