import React from "react";
import tableHeadingIcon from "../assets/images/icons/table-heading-icon.png";

import avatar from "../assets/images/icons/avatar-icon.png";

import tikTockIcon from "../assets/images/icons/tick-tock-icon.png";
import youtubeIcon from "../assets/images/icons/youtube-icon.png";
import instagramIcon from "../assets/images/icons/instagram-reels-icon.png";
import ShortenNumber from "../utilities/ShortenNumber";
import videoImg1 from "../assets/images/video-img-1.png";

export default function VideosListView({ data, platform, selectedVideos, setSelectedVideos }) {
    function formatShotDate(mongoDate) {
        if (!(mongoDate instanceof Date)) {
          return "Invalid date";
        }
      
        const year = mongoDate.getFullYear();
        const month = mongoDate.getMonth() + 1; // Month is zero-based, so add 1
        const day = mongoDate.getDate();
      
        const formattedYear = String(year).slice(-2).padStart(2, "0");
      
        const formattedMonth = String(month).padStart(2, "0");
        const formattedDay = String(day).padStart(2, "0");
      
        const formattedDate = `${formattedMonth}/${formattedDay}/${formattedYear}`;
      
        return formattedDate;
      }
    return (
        <div className="videos-list-view">
            <div className="container-fluid">
                <div className="table-wrapper gapped list-view">
                    <table>
                        <thead>
                            <tr>
                                <th style={{ minWidth: 45 }}>
                                    {/*<div className="check-box-wrapper"><label className="label"><input type="checkbox"/><span className="checkmark"></span></label></div>*/}
                                </th>
                                <th style={{ minWidth: 45 }}>
                                    {/*<div className="check-box-wrapper"><label className="label"><input type="checkbox"/><span className="checkmark"></span></label></div>*/}
                                </th>
                                <th style={{ minWidth: '400px' }}>
                                    <span className='strong'>Caption</span>
                                </th>
                                {/* <th style={{ minWidth: '100px' }}>
                                    <span className='strong'>Platform</span>
                                </th> */}
                                <th style={{ minWidth: '100px' }}>
                                    <span className='strong'>Views</span>
                                </th>
                                <th style={{ minWidth: 120 }}>
                                    <span className='strong'>Comments</span>
                                </th>
                                <th style={{ minWidth: 120 }}>
                                    <span className='strong'>Likes</span>
                                </th>
                                <th style={{ minWidth: 100 }}>
                                    <span className='strong'>Shares</span>
                                </th>
                                <th style={{ minWidth: 120 }}>
                                    <span className='strong'>Bookmarks</span>
                                </th>
                                <th style={{ minWidth: 100 }}>
                                    <span className='strong'>Duration</span>
                                </th>
                                <th style={{ minWidth: 120 }}>
                                    <span className='strong'>Date posted</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* <tr>
                                <td style={{ width: 45 }}>
                                    <div className="check-box-wrapper"><label className="label"><input
                                        type="checkbox" /><span
                                            className="checkmark"></span></label></div>
                                </td>
                                <td style={{ minWidth: 400 }}>
                                    <div className="main-wrapper gap-4">
                                        <img src={videoImg1} alt="videoImg1" className='tall' />
                                        <span
                                            className="white">When you finally nail that dance move 💃🕺#dancechallenge</span>
                                    </div>
                                </td>
                                <td style={{ minWidth: 100 }}><span className='white'>1000</span></td>
                                <td style={{ minWidth: 100 }}><span className='white'>500</span></td>
                                <td style={{ minWidth: 100 }}><span className='white'>38,342</span></td>
                                <td style={{ minWidth: 100 }}><span className='white'>8,392</span></td>
                                <td style={{ minWidth: 100 }}><span className='white'>100,000</span></td>
                                <td style={{ minWidth: 100 }}><span className='white'>30s</span></td>
                                <td style={{ minWidth: 120 }}><span className="white">May 4, 2024</span></td>
                            </tr> */}
                            {data?.map((item, index) => {
                                return (
                                    <tr key={index + 1}>
                                        <td style={{ minWidth: '70px' }}>
                                            <div className="check-box-wrapper"><label className="label"><input
                                                type="checkbox" checked={selectedVideos.has(
                                                    platform == "instagram" ? item["Video ID"] : `${item.id}`
                                                )} onChange={(e) => {
                                                    const updatedSelection = new Set(selectedVideos);
                                                    if (e.target.checked) {
                                                        updatedSelection.add(
                                                            platform == "instagram" ? item["Video ID"] : `${item.id}`
                                                        );
                                                    } else {
                                                        updatedSelection.delete(
                                                            platform == "instagram" ? item["Video ID"] : `${item.id}`
                                                        );
                                                    }
                                                    setSelectedVideos(updatedSelection);
                                                }} /><span
                                                    className="checkmark"></span></label></div>
                                        </td>
                                        <td style={{ minWidth: '70px' }}>
                                            <div className='avatar-icon'><img src={platform === "instagram"
                                                ? `https://instagramserver.tokbackup.com/proxy?media_url=${encodeURIComponent(
                                                    item?.thumbnail
                                                )}`
                                                : item?.thumbnail || avatar} alt="avatar" /></div>
                                        </td>
                                        <td style={{ minWidth: 400 }}>
                                            <div className="main-wrapper gap-4">
                                                {/* <img src={videoImg1} alt="videoImg1" className='tall' /> */}
                                                <span
                                                    className="white">{item?.title}</span>
                                            </div>
                                        </td>
                                        {/* <td style={{ minWidth: 120 }}><img
                                            src={platform === 'tiktok' ? tikTockIcon : platform === 'youtube' ? youtubeIcon : platform === 'instagram' ? instagramIcon : ' '}
                                            alt="tikTockIcon" /></td> */}
                                        <td style={{ minWidth: 100 }}><p className='white'>{ShortenNumber(item.views || item?.Views)}</p>
                                        </td>
                                        <td style={{ minWidth: 100 }}><p className='white'>{ShortenNumber(
                                            platform == "instagram" ? item.Comments : item.comments
                                        )}</p></td>
                                        
                                        <td style={{ minWidth: 100 }}><p className='white'>{ShortenNumber(
                                            platform == "instagram" ? item.Likes : item.likes
                                        )}</p></td>
                                        <td style={{ minWidth: 100 }}><p className='white'>{ShortenNumber(item.shares)}</p>
                                        </td>
                                        <td style={{ minWidth: 100 }}><p className='white'>{ShortenNumber(item.bookmarks)}</p>
                                        </td>
                                        <td style={{ minWidth: 100 }}><p className='white'>{item?.duration || item?.Duration}</p>
                                        </td>
                                        <td style={{ minWidth: 120 }}><span className="white">{new Date(item?.date || item['Date Posted'])?.toLocaleDateString()}</span></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}