export const calculateEngagementTimeseries = (likes, comments, shares, bookmarks) => {
    const engagementTimeseries = [];

    const dates = likes.map(item => item["Date Posted"]);

    dates?.forEach(date => {
        const likesData = likes.find(item => item["Date Posted"] === date) || { Likes: 0 };
        const commentsData = comments.find(item => item["Date Posted"] === date) || { Comments: 0 };
        const sharesData = shares.find(item => item["Date Posted"] === date) || { Shares: 0 };
        const bookmarksData = bookmarks.find(item => item["Date Posted"] === date) || { Bookmarks: 0 };

        const totalEngagement = (likesData.Likes || 0) +
            (commentsData.Comments || 0) +
            (sharesData.Shares || 0) +
            (bookmarksData.Bookmarks || 0);

        engagementTimeseries.push({
            "Date Posted": date,
            Engagement: totalEngagement
        });
    });

    return engagementTimeseries;
}