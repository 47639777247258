// import React, { useEffect, useState } from 'react';
// import Chart from 'chart.js/auto'; // Import Chart object

// export default function VideoPerMonthSmallChart({analyticsData  }) {

//     const [perMonthVideosSeries, setPerMonthVideosSeries] = useState([])

//     useEffect(() => {
//         if (!analyticsData) return;
        
//         const likesTimeSeries = analyticsData?.videos_timeseries || [];

//         // Function to process per month data
//         const processPerMonthData = (likesTimeSeries) => {
//             let monthlyData = {};

//             likesTimeSeries.forEach((likes) => {
//                 let date = new Date(likes['Date Posted']);
//                 let monthStart = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString('default', {
//                     year: 'numeric',
//                     month: 'short'
//                 });

//                 if (!monthlyData[monthStart]) {
//                     monthlyData[monthStart] = { 'Date Posted': monthStart, Videos: 0 };
//                 }
//                 monthlyData[monthStart].Videos += likes.Videos || 0;
//             });

//             return Object.values(monthlyData);
//         };

//         const processedMonthlyLikesTimeSeries = processPerMonthData(likesTimeSeries);
//         setPerMonthVideosSeries(processedMonthlyLikesTimeSeries);

//     }, [analyticsData]);

//     useEffect(() => {
//         // Create gradient
//         const canvas = document.getElementById('VideoPerMonthSmallChart');
//         const ctx = canvas.getContext('2d');
//         const gradient = ctx.createLinearGradient(0, 0, 0, 100); // Adjust according to your chart size
//         gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
//         gradient.addColorStop(0, 'rgba(49, 43, 83, 1)'); // A djust as needed

//         // Apply gradient as background color
//         const chartInstance = new Chart(ctx, {
//             type: 'line',
//             data: {
//                 labels: perMonthVideosSeries.map((share) => share['Date Posted']),
//                 datasets: [
//                     {
//                         label: 'My second dataset',
//                         backgroundColor: gradient,
//                         borderColor: '#BA88FF',
//                         borderWidth: 2,
//                         fill: true,
//                         pointBackgroundColor: 'rgba(0, 0, 0, 0)',
//                         pointBorderColor: 'rgba(0, 0, 0, 0)',
//                         pointHoverBackgroundColor: '#fff',
//                         pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
//                         // data: [30,15,40,20,35,30,40]
//                         data: perMonthVideosSeries?.map((like) => (like?.Videos?.toFixed(0))),
//                         lineTension: 0.4 // Adjust the tension value for smoothness
//                     }
//                 ]
//             },
//             options: {
//                 plugins: {
//                     legend: {
//                         display: false,
//                     }
//                 },
//                 scales: {
//                     // yAxes: [{
//                     //     ticks: {
//                     //         beginAtZero: true
//                     //     }
//                     // }],
//                     x: {
//                         ticks: {
//                             display: false // Hide the ticks (labels) on the x-axis
//                         },
//                         grid: {
//                             display: false
//                         }
//                     },
//                     y: {
//                         ticks: {
//                             display: false, // Hide the ticks (labels) on the x-axis
//                             beginAtZero: true,
//                         },
//                         grid: {
//                             display: false
//                         }
//                     }
//                 }
//             }
//         });

//         return () => {
//             chartInstance.destroy(); // Cleanup chart instance when component unmounts
//         };
//     }, [analyticsData, perMonthVideosSeries]);

//     return (
//         <div className='graph-chart-wrapper'>
//             <canvas id="VideoPerMonthSmallChart" height={25} width='100%' />
//         </div>
//     );
// }





import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto'; // Import Chart object

export default function VideoPerMonthSmallChart({ analyticsData }) {

    const [perMonthVideosSeries, setPerMonthVideosSeries] = useState([]);

    useEffect(() => {
        if (!analyticsData) return;

        const likesTimeSeries = analyticsData?.videos_timeseries || [];

        // Function to process per month data
        const processPerMonthData = (likesTimeSeries) => {
            // Get the most recent date in the likesTimeSeries
            const mostRecentDate = new Date(Math.max(...likesTimeSeries.map(likes => new Date(likes['Date Posted']))));
            const sixMonthsAgo = new Date(mostRecentDate.setMonth(mostRecentDate.getMonth() - 6));

            // Filter likesTimeSeries to include only the last 6 months
            const filteredTimeSeries = likesTimeSeries.filter(likes => new Date(likes['Date Posted']) >= sixMonthsAgo);

            let monthlyData = {};

            filteredTimeSeries.forEach((likes) => {
                let date = new Date(likes['Date Posted']);
                let monthStart = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString('default', {
                    year: 'numeric',
                    month: 'short'
                });

                if (!monthlyData[monthStart]) {
                    monthlyData[monthStart] = { 'Date Posted': monthStart, Videos: 0 };
                }
                monthlyData[monthStart].Videos += likes.Videos || 0;
            });

            return Object.values(monthlyData);
        };

        const processedMonthlyLikesTimeSeries = processPerMonthData(likesTimeSeries);
        setPerMonthVideosSeries(processedMonthlyLikesTimeSeries);

    }, [analyticsData]);

    useEffect(() => {
        // Create gradient
        const canvas = document.getElementById('VideoPerMonthSmallChart');
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 100); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, 'rgba(27, 60, 65, 1)'); // Adjust as needed

        // Apply gradient as background color
        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: perMonthVideosSeries.map((share) => share['Date Posted']),
                datasets: [
                    {
                        label: 'My second dataset',
                        backgroundColor: gradient,
                        borderColor: '#1EB185',
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
                        data: perMonthVideosSeries?.map((like) => (like?.Videos?.toFixed(0))),
                        lineTension: 0.4 // Adjust the tension value for smoothness
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false, // Disable the tooltip
                    },
                },
                scales: {
                    x: {
                        ticks: {
                            display: false // Hide the ticks (labels) on the x-axis
                        },
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        ticks: {
                            display: false, // Hide the ticks (labels) on the y-axis
                            beginAtZero: true,
                        },
                        grid: {
                            display: false
                        }
                    }
                }
            }
        });

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [perMonthVideosSeries]);

    return (
        <div className='graph-chart-wrapper'>
            <canvas id="VideoPerMonthSmallChart" height={25} width='100%' />
        </div>
    );
}
