// // SettingsContext.js
// import React, { createContext, useContext, useState, useEffect } from 'react';
// import { ApiRequests } from '../services/apiRequests';

// const SettingsContext = createContext();

// export const SettingsProvider = ({ children }) => {
//   const [userSettings, setUserSettings] = useState(null);
//   const [profileData, setProfileData] = useState(null);
//   const accessToken = localStorage.getItem("app-access-token");


//   useEffect(() => {
//     const fetchUserData = async () => {
//       // Fetch user profile data
//       try {
//         const userProfile = await ApiRequests.getUserProfile({
//           headers: {
//             "Authorization": `Bearer ${accessToken}`
//           }
//         }); // Modify as needed
//         if (userProfile.status == 200) {
//           setProfileData(userProfile.data)
//         }
//         else {
//           setProfileData({ error: true })
//           window.location.href = `${process.env.REACT_APP_APP_URL}/sign-in`
//           return null
//         }
//       } catch (error) {
//         console.log('error getting user profile', error)
//         setProfileData({ error: true })
//         window.location.href = `${process.env.REACT_APP_APP_URL}/sign-in`
//         return null
//       }

//       // Fetch user settings
//       try {
//         const settings = await ApiRequests.getSettings({
//           id: profileData?.id,
//           headers: {
//             "Authorization": `Bearer ${accessToken}`
//           }
//         }); // Modify as needed
//         if (settings && settings.status == 200) {
//           setUserSettings(settings.data)
//         } else {

//           setProfileData({ error: true })
//           window.location.href = `${process.env.REACT_APP_APP_URL}/sign-in`
//           return null
//         }
//       } catch (error) {
//         console.log('error getting user settings ', error)
//         setProfileData({ error: true })
//         window.location.href = `${process.env.REACT_APP_APP_URL}/sign-in`
//         return null
//       }
//     };

//     fetchUserData();
//   }, []);

//   // useEffect(() => {
//   //   const fetchUserSettings = async () => {
//   //     // Fetch user settings here
//   //     const userSettings = await getSettings(); // Modify the function parameters as needed
//   //     setUserSettings(userSettings);
//   //   };

//   //   fetchUserSettings();
//   // }, []);

//   return (
//     <SettingsContext.Provider value={{ profileData, userSettings }}>
//       {children}
//     </SettingsContext.Provider>
//   );
// };

// export const useSettings = () => useContext(SettingsContext);


// SettingsContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../store/settingsActions';
import { ApiRequests } from '../services/apiRequests';

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("app-access-token");
  const userSettings = useSelector(state => state.settings.userSettings);
  const [user, setUser] = useState({})
  console.log('userSettings', userSettings)
  // if(!accessToken) {
  //   window.location.href = `${process.env.REACT_APP_APP_URL}/sign-in`;
  // }

  // useEffect(() => {
  //   async function fetchCurrentUser() {
  //     try {
  //     const resp = await ApiRequests.getCurrentUser()
  //     if(resp?.status == 200){

  //       // setUser(user.data)
  //       const {user, tokens} = resp?.data
  //       localStorage.setItem(
  //         "app-access-token",
  //         tokens?.access.token
  //       );
  //       localStorage.setItem(
  //         "app-access",
  //         JSON.stringify(tokens?.access)
  //       );
  //       localStorage.setItem(
  //         "app-refresh",
  //         JSON.stringify(tokens?.refresh)
  //       );
  //       localStorage.setItem(
  //         "app-refresh-token",
  //         tokens?.refresh?.token
  //       );
  //       localStorage.setItem("user", JSON.stringify(user));
  //       localStorage.setItem("isLoggedIn", JSON.stringify(true));
  //     }
  //     } catch (error) {
  //       console.log('error fetching current user ', error)
  //     }
  //   }
  //   fetchCurrentUser()
  // },[])

  // console.log('user is ', user)
  useEffect(() => {
    dispatch(fetchSettings({accessToken}));
  }, [dispatch]);

  return (
    <SettingsContext.Provider value={{ userSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);
