// // import React from 'react';
// // import ReactApexChart from 'react-apexcharts';

// // export default function HorizontalBarChart2Duration({ durationData }) {
// //     const dataArray = Array.isArray(durationData) ? durationData : Object.values(durationData);
// //     const maxValue = Math.max(...dataArray) * 1.3;

// //     const data = dataArray.map(item => Math.ceil(item));
// //     const labels = Object.keys(durationData).map(key => {
// //         const [start, end] = key.replace('duration_', '').split('_').map(Number);
// //         return `${start}-${end}s`;
// //     });

// //     const mappedData = [
// //         durationData.duration_600_900  ? Math.ceil(durationData.duration_600_900) : 0, // 10-15m
// //         durationData.duration_300_600 ? Math.ceil(durationData.duration_300_600) : 0, // 5-10m
// //         durationData.duration_60_300 ? Math.ceil(durationData.duration_60_300) : 0, // 1-5m
// //         durationData.duration_30_60 ? Math.ceil(durationData.duration_30_60) : 0, // 30-60s
// //         durationData.duration_15_30 ? Math.ceil(durationData.duration_15_30) : 0, // 15-30s
// //         durationData.duration_0_15 ? Math.ceil(durationData.duration_0_15) : 0, // 0-15s
// //     ];

// //     // const barColors = ['#D9D9D9', '#D9D9D9', '#EAB9FF', '#858DE2', '#EB55B4', '#B58AE4'];
// //     const barColors = ['rgba(217, 217, 217, 1)','rgba(217, 217, 217, 1)', 'rgba(234, 185, 255, 1)', 'rgba(133, 141, 226, 1)', 'rgba(235, 85, 180, 1)', 'rgba(181, 138, 228, 1)'];


// //     const options = {
// //         chart: {
// //             type: 'bar',
// //             height: 200,
// //             width: '100%',
// //             toolbar: {
// //                 show: false,
// //                 tools: {
// //                     download: false
// //                 }
// //             },
// //         },
// //         plotOptions: {
// //             bar: {
// //                 horizontal: true,
// //                 columnWidth: '100%',
// //                 barHeight: '60%',
// //                 borderRadius: 5,
// //                 dataLabels: {
// //                     position: 'center',
// //                     style: {
// //                         fontSize: '12px',
// //                         colors: ['#646F7C']
// //                     }
// //                 },
// //             }
// //         },
// //         colors: barColors,
// //         dataLabels: {
// //             enabled: true,
// //             textAnchor: 'center',
// //             style: {
// //                 fontSize: '10px',
// //                 colors: ['#fff']
// //             },
// //             formatter: function (val, opt) {
// //                 const minutes = Math.floor(val / 60);
// //                 const seconds = val % 60;
// //                 return `${seconds}`;
// //             },
// //             dropShadow: {
// //                 enabled: true
// //             }
// //         },
// //         xaxis: {
// //             // categories: labels,
// //             categories: ['10-15m', '5-10m', '1-5m', '30-60s', '15-30s', '0-15s'],
// //             max: maxValue,
// //             axisBorder: {
// //                 show: false
// //             },
// //             axisTicks: {
// //                 show: false
// //             },
// //             labels: {
// //                 show: false,
// //             }
// //         },
// //         yaxis: {
// //             axisBorder: {
// //                 show: false
// //             },
// //             axisTicks: {
// //                 show: false
// //             },
// //             labels: {
// //                 show: true,
// //                 align: 'left',
// //                 offsetX: -10,
// //                 style: {
// //                     colors: '#646F7C',
// //                     fontSize: '12px',
// //                 }
// //             }
// //         },
// //         grid: {
// //             show: false
// //         },
// //         tooltip: {
// //             enabled: false,
// //         },
// //     };

// //     const series = [{
// //         name: 'Sales',
// //         // data: data.map((value, index) => ({
// //         //     x: labels[index],
// //         //     y: value,
// //         //     fillColor: barColors[index]
// //         // }))
// //         data: mappedData
// //     }];

// //     return (
// //         <div className='horizontal-bar-chart'>
// //             <ReactApexChart options={options} series={series} type="bar" height={230} />
// //         </div>
// //     );
// // }



// import React from 'react';
// import ReactApexChart from 'react-apexcharts';
// import { Bar } from "react-chartjs-2";
// import ChartDataLabels from "chartjs-plugin-datalabels";
// import { useSelector } from 'react-redux';

// /*export default function HorizontalBarChart2Duration({ durationData }) {
//     const dataArray = Array.isArray(durationData) ? durationData : Object.values(durationData);
//     const maxValue = Math.max(...dataArray) * 1.3;

//     const data = dataArray.map(item => Math.ceil(item));
//     const labels = Object.keys(durationData).map(key => {
//         const [start, end] = key.replace('duration_', '').split('_').map(Number);
//         return `${start}-${end}s`;
//     });

//     const mappedData = [
//         durationData.duration_600_900 ? Math.floor(durationData.duration_600_900) : 0, // 10-15m
//         durationData.duration_300_600 ? Math.floor(durationData.duration_300_600) : 0, // 5-10m
//         durationData.duration_60_300 ? Math.floor(durationData.duration_60_300) : 0, // 1-5m
//         durationData.duration_30_60 ? Math.floor(durationData.duration_30_60) : 0, // 30-60s
//         durationData.duration_15_30 ? Math.floor(durationData.duration_15_30) : 0, // 15-30s
//         durationData.duration_0_15 ? Math.floor(durationData.duration_0_15) : 0, // 0-15s
//     ];

//     const barColors = ['rgba(217, 217, 217, 1)', 'rgba(217, 217, 217, 1)', 'rgba(234, 185, 255, 1)', 'rgba(133, 141, 226, 1)', 'rgba(235, 85, 180, 1)', 'rgba(181, 138, 228, 1)'];

//     const options = {
//         chart: {
//             type: 'bar',
//             height: 200,
//             width: '100%',
//             toolbar: {
//                 show: false,
//                 tools: {
//                     download: false
//                 }
//             },
//         },
//         plotOptions: {
//             bar: {
//                 horizontal: true,
//                 columnWidth: '100%',
//                 barHeight: '60%',
//                 borderRadius: 5,
//                 dataLabels: {
//                     position: 'center',
//                     style: {
//                         fontSize: '12px',
//                         colors: ['#646F7C']
//                     }
//                 },
//             }
//         },
//         colors: barColors,
//         dataLabels: {
//             enabled: true,
//             textAnchor: 'center',
//             style: {
//                 fontSize: '10px',
//                 colors: ['#fff']
//             },
//             formatter: function (val, opt) {
//                 const minutes = Math.floor(val / 60);
//                 const seconds = val % 60;
//                 return `${seconds}%`;
//             },
//             dropShadow: {
//                 enabled: true
//             }
//         },
//         xaxis: {
//             categories: ['10-15m', '5-10m', '1-5m', '30-60s', '15-30s', '0-15s'],
//             max: maxValue,
//             axisBorder: {
//                 show: false
//             },
//             axisTicks: {
//                 show: false
//             },
//             labels: {
//                 show: false,
//             }
//         },
//         yaxis: {
//             axisBorder: {
//                 show: false
//             },
//             axisTicks: {
//                 show: false
//             },
//             labels: {
//                 show: true,
//                 align: 'left',
//                 offsetX: -10,
//                 style: {
//                     colors: '#646F7C',
//                     fontSize: '12px',
//                 }
//             }
//         },
//         grid: {
//             show: false
//         },
//         tooltip: {
//             enabled: false,
//         },
//     };

//     const series = [{
//         name: 'Duration',
//         data: mappedData.map((value, index) => ({
//             x: options.xaxis.categories[index],
//             y: value,
//             fillColor: barColors[index]
//         }))
//     }];

//     return (
//         <div className='horizontal-bar-chart'>
//             <ReactApexChart options={options} series={series} type="bar" height={230} />
//         </div>
//     );
// }*/



// const HorizontalBarChart2Duration = ({ durationData }) => {

//     const mappedData = [
//         durationData.duration_0_15 ? durationData?.duration_0_15?.toFixed(2) : 0, // 0-15s
//         durationData.duration_15_30 ? durationData?.duration_15_30?.toFixed(2) : 0, // 15-30s
//         durationData.duration_30_60 ? durationData?.duration_30_60?.toFixed(2) : 0, // 30-60s
//         durationData.duration_60_180 ? durationData?.duration_60_180?.toFixed(2) : 0, // 1-5m
//         durationData.duration_180_600 ? durationData?.duration_180_600?.toFixed(2) : 0, // 5-10m
//         durationData.duration_600_900 ? durationData?.duration_600_900?.toFixed(2) : 0, // 10-15m
//     ];

//     // const videosData = useSelector((state) => state?.reels?.reelsData);

//     // // Define duration categories
//     // const durationCategories = [
//     //     { label: '0-15s', min: 0, max: 15 },
//     //     { label: '15-30s', min: 15, max: 30 },
//     //     { label: '30-60s', min: 30, max: 60 },
//     //     { label: '1-5m', min: 60, max: 300 },
//     //     { label: '5-10m', min: 300, max: 600 },
//     //     { label: '10-15m', min: 600, max: 900 }
//     // ];

//     // // Initialize counters for each category
//     // const durationCounts = durationCategories.map(() => 0);

//     // // Count videos based on their durations
//     // videosData.forEach(video => {
//     //     const duration = video.duration; // Assuming duration is in seconds
//     //     for (let i = 0; i < durationCategories.length; i++) {
//     //         const category = durationCategories[i];
//     //         if (duration >= category.min && duration < category.max) {
//     //             durationCounts[i]++;
//     //             break;
//     //         }
//     //     }
//     // });

//     // // Map the counts to the `mappedData` array
//     // const mappedData = durationCounts;

//     const barColors = ['rgba(217, 217, 217, 1)', 'rgba(217, 217, 217, 1)', 'rgba(234, 185, 255, 1)', 'rgba(133, 141, 226, 1)', 'rgba(235, 85, 180, 1)', 'rgba(181, 138, 228, 1)'].reverse();

//     const data = {
//         labels: ['10-15m', '5-10m', '1-5m', '30-60s', '15-30s', '0-15s'].reverse(),
//         datasets: [
//             {
//                 label: 'Time Spent',
//                 // data: [25, 1000, 400, 90, 56, 200, 300],
//                 data: mappedData,
//                 backgroundColor: barColors,
//                 borderRadius: 10,
//                 barThickness: 'flex',
//                 maxBarThickness: 15, // Adjusted value
//             },
//         ],
//     };

//     const options = {
//         // maintainAspectRatio: false,
//         indexAxis: 'y',
//         maintainAspectRatio: true,
//         responsive: true,
//         aspectRatio: 0,
//         devicePixelRatio: 4,
//         tooltips: {
//             enabled: false,
//         },
//         scales: {
//             x: {
//                 beginAtZero: true,
//                 ticks: {
//                     callback: (value) => `${value}`,
//                     color: '#646F7C',
//                     display: false,
//                 },
//                 font: {
//                     size: 5, // Font size for x-axis labels
//                 },
//                 grid: {
//                     display: false,
//                 },
//             },
//             y: {
//                 beginAtZero: true,
//                 ticks: {
//                     color: '#646F7C',
//                     // align: 'left',
//                     crossAlign: "far",
//                     font: {
//                         size: 12, // Font size for x-axis labels
//                     },
//                 },
//                 grid: {
//                     display: false,
//                 },
//             },
//         },
//         plugins: {
//             legend: {
//                 display: false,
//             },
//             tooltip: {
//                 enabled: false,
//                 callbacks: {
//                     label: (context) => `${context.raw}s`,
//                 },
//             },
//             datalabels: {
//                 align: 'end',
//                 anchor: 'end',
//                 color: 'white',
//                 clip: true, // Ensuring labels stay within chart area
//                 formatter: (value) => `${value}`,
//                 padding: {
//                     // right: 10 // Adding some padding to avoid overlap
//                 },
//                 font: {
//                     size: 10, // Font size for data labels
//                 },
//             },
//         },
//         layout: {
//             padding: {
//                 right: 30, // Adding padding to ensure enough space for the labels

//             }
//         },
//     };

//     return (
//         <div style={{ position: 'relative', height: '250px', width: '100%', padding: '20px 0 0' }}>
//             <Bar data={data} options={options} plugins={[ChartDataLabels]} />
//         </div>
//     );
// };

// export default HorizontalBarChart2Duration;











import React from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useSelector } from 'react-redux';

const HorizontalBarChart2Duration = ({ durationData, daysFilter }) => {
    const videosData = useSelector((state) => state?.reels?.reelsData);
    
    const durationCategories = [
        { label: '0-15s', min: 0, max: 15 },
        { label: '15-30s', min: 15, max: 30 },
        { label: '30-60s', min: 30, max: 60 },
        { label: '1-5m', min: 60, max: 300 },
        { label: '5-10m', min: 300, max: 600 },
        { label: '10-15m', min: 600, max: 900 }
    ];

    const filterVideosByDate = (videos, days) => {
        if (!days) return videos;
        const now = new Date();
        return videos.filter(video => {
            const videoDate = new Date(video.date || video?.['Date Posted']);
            const timeDifference = (now - videoDate) / (1000 * 60 * 60 * 24); // convert to days
            return timeDifference <= days;
        });
    };

    const filteredVideosData = filterVideosByDate(videosData, daysFilter);


    const durationCounts = durationCategories.map(() => 0);

    filteredVideosData.forEach(video => {
        const duration = video?.duration == 0 ? 0 : video?.duration || video?.Duration;
        for (let i = 0; i < durationCategories.length; i++) {
            const category = durationCategories[i];
            if (duration >= category.min && duration < category.max) {
                durationCounts[i]++;
                break;
            }
        }
    });

    const mappedData = durationCounts;

    const barColors = ['rgba(217, 217, 217, 1)', 'rgba(217, 217, 217, 1)', 'rgba(234, 185, 255, 1)', 'rgba(133, 141, 226, 1)', 'rgba(235, 85, 180, 1)', 'rgba(181, 138, 228, 1)'].reverse();

    const data = {
        labels: ['10-15m', '5-10m', '1-5m', '30-60s', '15-30s', '0-15s'].reverse(),
        datasets: [
            {
                label: 'Time Spent',
                data: mappedData,
                backgroundColor: barColors,
                borderRadius: 10,
                barThickness: 'flex',
                maxBarThickness: 15,
            },
        ],
    };

    const options = {
        indexAxis: 'y',
        maintainAspectRatio: true,
        responsive: true,
        aspectRatio: 0,
        devicePixelRatio: 4,
        scales: {
            x: {
                beginAtZero: true,
                ticks: {
                    color: '#646F7C',
                    display: false,
                },
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    color: '#646F7C',
                    crossAlign: 'far',
                    font: {
                        size: 12,
                    },
                },
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                callbacks: {
                    label: (context) => `${context.raw}s`,
                },
            },
            datalabels: {
                align: 'end',
                anchor: 'end',
                color: 'white',
                clip: false,
                formatter: (value) => `${value}`,
                padding: {
                    right: 10, // Adding padding to ensure labels are not clipped
                },
                font: {
                    size: 10,
                },
            },
        },
        layout: {
            padding: {
                right: 30,
            },
        },
    };

    return (
        <div style={{ position: 'relative', height: '250px', width: '100%', padding: '20px 0 0' }}>
            <Bar data={data} options={options} plugins={[ChartDataLabels]} />
        </div>
    );
};

export default HorizontalBarChart2Duration;
