import { useEffect, useState } from "react"
import { useSelector } from "react-redux";

export default function PostsGroupedByDuration({platform }) {
    const videosData = useSelector((state) => state?.reels?.reelsData) || [];
    const [greaterThanOne, setGreaterThanOne] = useState({})
    const [greaterThan45, setGreaterThan45] = useState({})
    const [greaterThan30, setGreaterThan30] = useState({})
    const [greaterThan15, setGreaterThan15] = useState({})
    const [greaterThan1, setGreaterThan1] = useState({})
    useEffect(() => {
        const greaterThanOneMin = {range:'1+ min', count:0, views:0, likes:0, comments:0, shares:0,bookmarks:0}
        const greaterThan45Sec = {range: '46 - 60s', count:0, views:0, likes:0, comments:0, shares:0,bookmarks:0}
        const greaterThan30Sec = {range: '32 - 45s', count:0, views:0, likes:0, comments:0, shares:0,bookmarks:0}
        const greaterThan15Sec = {range: '16 - 30s', count:0, views:0, likes:0, comments:0, shares:0,bookmarks:0}
        const greaterThan1Sec = {range: '1 - 15s', count:0, views:0, likes:0, comments:0, shares:0,bookmarks:0}
        videosData?.forEach((item) => {
            if (platform == 'instagram' ? item?.Duration > 60 : item.duration > 60) {
                greaterThanOneMin.count += 1;
                greaterThanOneMin.views += platform == 'instagram' ? item?.Views : item.views
                greaterThanOneMin.likes += platform == 'instagram' ? item?.Likes : item.likes
                greaterThanOneMin.comments += platform == 'instagram' ? item?.Comments : item.comments
                greaterThanOneMin.shares += platform == 'instagram' ? item?.Shares : item.shares
                greaterThanOneMin.bookmarks += platform == 'instagram' ? item?.Bookmarks : item.bookmarks
            }else if(platform == 'instagram' ? item?.Duration > 45 : item.duration > 45){
                greaterThan45Sec.count += 1;
                greaterThan45Sec.views += platform == 'instagram' ? item?.Views : item.views
                greaterThan45Sec.likes += platform == 'instagram' ? item?.Likes : item.likes
                greaterThan45Sec.comments += platform == 'instagram' ? item?.Comments : item.comments
                greaterThan45Sec.shares += platform == 'instagram' ? item?.Shares : item.shares
                greaterThan45Sec.bookmarks += platform == 'instagram' ? item?.Bookmarks : item.bookmarks
            }else if(platform == 'instagram' ? item?.Duration > 30 : item.duration > 30){
                greaterThan30Sec.count += 1;
                greaterThan30Sec.views += platform == 'instagram' ? item?.Views : item.views
                greaterThan30Sec.likes += platform == 'instagram' ? item?.Likes : item.likes
                greaterThan30Sec.comments += platform == 'instagram' ? item?.Comments : item.comments
                greaterThan30Sec.shares += platform == 'instagram' ? item?.Shares : item.shares
                greaterThan30Sec.bookmarks += platform == 'instagram' ? item?.Bookmarks : item.bookmarks
            }else if(platform == 'instagram' ? item?.Duration > 15 : item.duration > 15){
                greaterThan15Sec.count += 1;
                greaterThan15Sec.views += platform == 'instagram' ? item?.Views : item.views
                greaterThan15Sec.likes += platform == 'instagram' ? item?.Likes : item.likes
                greaterThan15Sec.comments += platform == 'instagram' ? item?.Comments : item.comments
                greaterThan15Sec.shares += platform == 'instagram' ? item?.Shares : item.shares
                greaterThan15Sec.bookmarks += platform == 'instagram' ? item?.Bookmarks : item.bookmarks
            }else if(platform == 'instagram' ? item?.Duration > 1 : item.duration > 1){
                greaterThan1Sec.count += 1;
                greaterThan1Sec.views += platform == 'instagram' ? item?.Views : item.views
                greaterThan1Sec.likes += platform == 'instagram' ? item?.Likes : item.likes
                greaterThan1Sec.comments += platform == 'instagram' ? item?.Comments : item.comments
                greaterThan1Sec.shares += platform == 'instagram' ? item?.Shares : item.shares
                greaterThan1Sec.bookmarks += platform == 'instagram' ? item?.Bookmarks : item.bookmarks
            }
        })
        setGreaterThanOne(greaterThanOneMin)
        setGreaterThan45(greaterThan45Sec)
        setGreaterThan30(greaterThan30Sec)
        setGreaterThan15(greaterThan15Sec)
        setGreaterThan1(greaterThan1Sec)

    },[videosData])
    return (
        <div className="table-wrapper dark gapped">
            <table>
                <thead>
                    <tr>
                        <th style={{ width: 70 }}></th>
                        <th style={{ minWidth: 100 }}><span className='strong'>Range    </span></th>
                        <th style={{ minWidth: 100 }}><span className='strong'>Count    </span></th>
                        <th style={{ minWidth: 150 }}><span className='strong'>Views    </span></th>
                        <th style={{ minWidth: 150 }}><span className='strong'>Likes    </span></th>
                        <th style={{ minWidth: 100 }}><span className='strong'>Comments </span></th>
                        <th style={{ minWidth: 100 }}><span className='strong'>Shares   </span></th>
                        <th style={{ minWidth: 100 }}><span className='strong'>Bookmarks</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ width: 70 }}><p className=''>1 </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{greaterThanOne?.range} </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{greaterThanOne?.count || 0} </p></td>
                        <td style={{ minWidth: 150 }}><p className='white'>{!isNaN(greaterThan1?.views) ? greaterThanOne?.views?.toLocaleString() : 'N/A'}</p></td>
                        <td style={{ minWidth: 150 }}><p className='white'>{!isNaN(greaterThan1?.likes) ? greaterThanOne?.likes?.toLocaleString() : 'N/A'} </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{!isNaN(greaterThan1?.comments) ? greaterThanOne?.comments?.toLocaleString() : 'N/A'} </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{!isNaN(greaterThan1?.shares) ? greaterThanOne?.shares?.toLocaleString() : 'N/A'} </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{!isNaN(greaterThan1?.bookmarks) ? greaterThanOne?.bookmarks?.toLocaleString() : 'N/A'} </p></td>
                    </tr>
                    <tr>
                        <td style={{ width: 70 }}><p className=''>2 </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{greaterThan45?.range}</p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{greaterThan45?.count || 0} </p></td>
                        <td style={{ minWidth: 150 }}><p className='white'>{!isNaN(greaterThan1?.views) ? greaterThan45?.views?.toLocaleString() : 'N/A'}</p></td>
                        <td style={{ minWidth: 150 }}><p className='white'>{!isNaN(greaterThan1?.likes) ? greaterThan45?.likes?.toLocaleString() : 'N/A'} </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{!isNaN(greaterThan1?.comments) ? greaterThan45?.comments?.toLocaleString() : 'N/A'} </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{!isNaN(greaterThan1?.shares) ? greaterThan45?.shares?.toLocaleString() : 'N/A'} </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{!isNaN(greaterThan1?.bookmarks) ? greaterThan45?.bookmarks?.toLocaleString() : 'N/A'} </p></td>
                    </tr>
                    <tr>
                        <td style={{ width: 70 }}><p className=''>2 </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{greaterThan30?.range} </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{greaterThan30?.count || 0} </p></td>
                        <td style={{ minWidth: 150 }}><p className='white'>{!isNaN(greaterThan1?.views) ? greaterThan30?.views?.toLocaleString() : 'N/A'}</p></td>
                        <td style={{ minWidth: 150 }}><p className='white'>{!isNaN(greaterThan1?.likes) ? greaterThan30?.likes?.toLocaleString() : 'N/A'} </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{!isNaN(greaterThan1?.comments) ? greaterThan30?.comments?.toLocaleString() : 'N/A'} </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{!isNaN(greaterThan1?.shares) ? greaterThan30?.shares?.toLocaleString() : 'N/A'} </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{!isNaN(greaterThan1?.bookmarks) ? greaterThan30?.bookmarks?.toLocaleString() : 'N/A'} </p></td>
                    </tr>
                    <tr>
                        <td style={{ width: 70 }}><p className=''>3 </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{greaterThan15?.range}</p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{greaterThan15?.count || 0} </p></td>
                        <td style={{ minWidth: 150 }}><p className='white'>{!isNaN(greaterThan1?.views) ? greaterThan15?.views?.toLocaleString() : 'N/A'}</p></td>
                        <td style={{ minWidth: 150 }}><p className='white'>{!isNaN(greaterThan1?.likes) ? greaterThan15?.likes?.toLocaleString() : 'N/A'} </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{!isNaN(greaterThan1?.comments) ? greaterThan15?.comments?.toLocaleString() : 'N/A'} </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{!isNaN(greaterThan1?.shares) ? greaterThan15?.shares?.toLocaleString() : 'N/A'} </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{!isNaN(greaterThan1?.bookmarks) ? greaterThan15?.bookmarks?.toLocaleString() : 'N/A'} </p></td>
                    </tr>
                    <tr>
                        <td style={{ width: 70 }}><p className=''>4 </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{greaterThan1?.range} </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{greaterThan1?.count || 0} </p></td>
                        <td style={{ minWidth: 150 }}><p className='white'>{!isNaN(greaterThan1?.views) ? greaterThan1?.views?.toLocaleString() : 'N/A'}</p></td>
                        <td style={{ minWidth: 150 }}><p className='white'>{!isNaN(greaterThan1?.likes) ? greaterThan1?.likes?.toLocaleString() : 'N/A'} </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{!isNaN(greaterThan1?.comments) ? greaterThan1?.comments?.toLocaleString() : 'N/A'} </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{!isNaN(greaterThan1?.shares) ? greaterThan1?.shares?.toLocaleString() : 'N/A'} </p></td>
                        <td style={{ minWidth: 100 }}><p className='white'>{!isNaN(greaterThan1?.bookmarks) ? greaterThan1?.bookmarks?.toLocaleString() : 'N/A'} </p></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}