
import { combineReducers } from 'redux';
import authSlice from './authSlice';
import settingsReducer from './settingsReducer';
import reelsReducer from './reelsSlice'
const rootReducer = combineReducers({
  auth: authSlice,
  settings: settingsReducer,
  reels: reelsReducer
});

export default rootReducer;