import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto'; // Import Chart object
import ShortenNumber from '../utilities/ShortenNumber';

export default function TotalLikesChart({ analyticsData }) {

    const [likesTimeSeries, setLikesTimeSeries] = useState([])
    //   useEffect(() => {
    //     if (!analyticsData) return
    //     var likesTimeSeries = (analyticsData?.likes_timeseries)
    //     //if likesTimeSeries length is bigger than 100, calculate the total views  for each month, instad hcing the data for each day 
    //     if (likesTimeSeries?.length > 100) {

    //       var likesTimeSeriesByMonth = {}
    //       likesTimeSeries.forEach((likes) => {

    //         var date = new Date(likes['Date Posted'])
    //         var month = date.getMonth()
    //         //get month word 
    //         month = new Date(date.getFullYear(), month, 1).toLocaleString('default', { month: 'short' })
    //         var year = date.getFullYear()
    //         var monthYear = month + '-' + year
    //         if (likesTimeSeriesByMonth[year]) {
    //           likesTimeSeriesByMonth[year].Likes += likes.Likes
    //         } else {
    //           likesTimeSeriesByMonth[year] = {
    //             Likes: likes.Likes,
    //             'Date Posted': year
    //           }
    //         }
    //       }
    //       )

    //       likesTimeSeries = Object.values(likesTimeSeriesByMonth)
    //     }
    //     setLikesTimeSeries(likesTimeSeries)



    //   }, [analyticsData])


    useEffect(() => {
        if (!analyticsData) return;

        let likesTimeSeries = analyticsData?.likes_timeseries || [];

        // Calculate the total likes for each day
        const likesTimeSeriesByDay = {};

        likesTimeSeries?.forEach((like) => {
            const date = new Date(like['Date Posted']);
            const day = date.getDate();
            const month = date.getMonth() + 1; // Months are zero-indexed in JS
            const year = date.getFullYear();

            // Format the date as 'DD-MM-YYYY'
            const dayMonthYear = `${String(day).padStart(2, '0')}-${String(month).padStart(2, '0')}-${year}`;

            if (likesTimeSeriesByDay[dayMonthYear]) {
                likesTimeSeriesByDay[dayMonthYear].Likes += like.Likes;
            } else {
                likesTimeSeriesByDay[dayMonthYear] = {
                    Likes: like.Likes,
                    'Date Posted': dayMonthYear,
                };
            }
        });

        likesTimeSeries = Object.values(likesTimeSeriesByDay);

        setLikesTimeSeries(likesTimeSeries);

    }, [analyticsData]);

    useEffect(() => {
        // Create gradient
        const canvas = document.getElementById('totalLikes');
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 100); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, 'rgba(255, 22, 93, 0)'); // A djust as needed
        // rgba(49, 43, 83, 1)

        // Apply gradient as background color
        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: likesTimeSeries?.map((share) => share['Date Posted']),
                datasets: [
                    {
                        label: 'Dataset',
                        backgroundColor: gradient,
                        borderColor: 'rgba(255, 22, 93, 1)',
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
                        // data: [30,15,40,20,35,30,40]
                        data: likesTimeSeries?.map((like) => (like?.Likes?.toFixed(0))),
                        lineTension: 0.4 // Adjust the tension value for smoothness
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false, // Disable the tooltip
                    },
                },
                scales: {
                    // yAxes: [{
                    //     ticks: {
                    //         beginAtZero: true
                    //     }
                    // }],
                    x: {
                        ticks: {
                            display: false // Hide the ticks (labels) on the x-axis
                        },
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        ticks: {
                            display: false, // Hide the ticks (labels) on the x-axis
                            beginAtZero: true,
                            callback: function (value) {
                                return ShortenNumber(value);
                            },
                        },
                        grid: {
                            display: false
                        }
                    }
                }
            }
        });

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [analyticsData, likesTimeSeries]);

    return (
        <div className='graph-chart-wrapper'>
            <canvas id="totalLikes" height={25} width='100%' />
        </div>
    );
}