import React from 'react'

async function GetLastDownloadedVideoId(platform, id,  appuser_id) {
    if(!appuser_id) return
    const token = localStorage.getItem("app-access-token")

    const res = await fetch(`${global.SERVER_HOST}/api/${platform}/videos/${id}/last/${appuser_id}`,
    {
        headers:{
            Authorization: token ? `Bearer ${token}` : '',
        }
    });
    const data = await res.json();
    return data.lastDownloadedVideo

}

export default GetLastDownloadedVideoId