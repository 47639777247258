import { toast } from "react-toastify";
import { ApiRequests } from "../services/apiRequests";
import { basePath } from "../services/constents";
const belladata = {
  platform: "tiktok",
  username: "bellapoarch",
  usernameInput: "bellapoarch",
  id: "6748458643983238149",
  name: "Bella Poarch",
  url: "https://tiktok.com/@bellapoarch",
  bio: "CRUSH OUT NOW!!!!🫶🏻💘",
  followerCount: 92900000,
  videoCount: 616,
  heartCount: -2012462451,
  followingCount: 613,
  avatar: require("../assets/bellaprofile.jpeg"),
  score: 9,
  profileType:"Trendsetting"
};

const GetBasicProfileData = async (
  username,
  platform,
  setLoading = () => { },
  dontSaveHistory
) => {
  setLoading(true);

  try {
    const token = localStorage.getItem("app-access-token");

    const user = JSON.parse(localStorage.getItem("user"));

    var userData = {};


    try {
      const userResponse = await fetch(
        `${basePath}/users/${user.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token ? `Bearer ${token}` : "",
          },
          method: "GET",
        }
      );

      if (userResponse.ok) {
        userData = await userResponse.json();
      } else {
        throw new Error(`HTTP error ${userResponse.status}`);
      }
    } catch (userError) {
      console.error("Error fetching user data:", userError.message);
    }

    // const getPublicIP = async () => {
    //   try {
    //     const response = await fetch('https://api.ipify.org?format=json');
    //     const data = await response.json();
    //     return data.ip;
    //   } catch (error) {
    //     console.error('Error fetching public IP:', error);
    //     return null;
    //   }
    // // };

    const response = await fetch(`${basePath}/basicdata`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${token}` : "",
      },
      method: "POST",
      body: JSON.stringify({ username, platform, user: userData?.id ? userData?.id : userData?._id || undefined }),
    });
    const data = await response.json();

    console.log('data is ',data)

    if (!data.id) {
      // toast.error(data.message);
      return data;
    }

    if (data && !data.error && data.username !== "bellapoarch" && user?.id && dontSaveHistory !== true) {
      const historyData = {
        username: data.username,
        platform: data.platform,
        followers: data.followerCount,
        likes: data.heartCount,
        url: data.url,
        bio: data.bio || " ",
        videos: data.videoCount,
        following: data.followingCount,
        userId: user.id || undefined,
        email: user?.email || undefined,
        profileAvatar: data?.avatar
      };
      await ApiRequests.SaveSearchHistory({ data: historyData, token });
    }

    return data;
  } catch (error) {
    console.error("Error fetching Tiktok basic profile data:", error.message);
  } finally {
    setLoading(false);
  }
};

export default GetBasicProfileData;
