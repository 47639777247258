import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';

import chartDownIcon from "../assets/images/icons/chart-down-circular-arrow-icon.png";

import LineChart from "../components/LineChart";
import cartUpIcon from "../assets/images/icons/chart-up-circular-arrow-icon.png";
import infoQuestionMark from "../assets/images/icons/info-question-mark-icon.png";
import ShortenNumber from "../utilities/ShortenNumber";
import LineChartBookmarks from "./LineChartBookmarks";
import LineChartViews from "./LineChartViews";
import LineChartShares from "./LineChartShares";
import LineChartsComments from "./LineChartComments";
import LineChartLikes from "./LineChartLikes";
import Dropdown from "react-bootstrap/Dropdown";



export default function TotalChartsZoomedModalBookmarks({showBookmarksModal, setShowBookmarksModal, analyticsData}) {
    const [selectedOption,setSelectedOption] = useState('total_bookmarks')

    const handleChangeOption = (e) => {
      setSelectedOption(e.target.value)
    }

    return (
        <Modal
            show={showBookmarksModal}
            onHide={() => setShowBookmarksModal(false)}
            dialogClassName="line-chart"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="select-detail-wrapper">
                    {/* <select className="form-select" aria-label="Default select example" style={{ color: '#FF6400' }} value={selectedOption} onChange={(e) => handleChangeOption(e)}>
                        <option value="total_views" selected>Total Views</option>
                        <option value="total_likes">Total Likes</option>
                        <option value="total_shares">Total Shares</option>
                        <option value="total_comments">Total Comments</option>
                        <option value="total_bookmarks">Total Bookmarks</option>
                    </select> */}
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ color: '#FF6400' }}>{selectedOption !== 'total_bookmarks' ? selectedOption : 'TOTAL BOOKMARKS'}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_views' } })}>TOTAL VIEWS</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_likes' } })}>TOTAL LIKES</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_shares' } })}>TOTAL SHARES</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_comments' } })}>TOTAL COMMENTS</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_bookmarks' } })}>TOTAL BOOKMARKS</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <div className="rating">
                        <h3>{ShortenNumber(analyticsData?.totals?.total_comments)}</h3>
                        <div className="text down">
                            <img src={cartUpIcon} alt="cartUpIcon" className='up-arrow' />
                            <img src={chartDownIcon} alt="cartUpIcon" className='down-arrow' />
                            <span className="ex-small">93 (8.0%)</span>
                        </div>
                    </div>
                    <div className="rank">
                        <div className="dot" style={{ background: '#00F2EA' }}></div>
                        <span className="small">Average</span>
                        <img src={infoQuestionMark} alt="infoQuestionMark" />
                        <div className="info-text">
                            <span className="white small">Graphs labeled as "Average" signify that your content is performing in line with typical expectations or industry standards.</span>
                        </div>
                    </div>
                </div>
                <div className="chart-wrapper">
                    {selectedOption === 'total_bookmarks' && <LineChartBookmarks />}
                    {selectedOption == 'total_views' && <LineChartViews analyticsData={analyticsData}/>}
                    {selectedOption == 'total_shares' && <LineChartShares analyticsData={analyticsData}/>}
                    {selectedOption == 'total_comments' && <LineChartsComments analyticsData={analyticsData}/>}
                    {selectedOption == 'total_likes' && <LineChartLikes analyticsData={analyticsData}/>}
                </div>
                <div className="content-wrapper">
                    <span>The "Total Views" graph is a vital analytics tool that
                        tracks the cumulative views your videos receive over time. It helps
                        identify trends, peak engagement periods, and the overall effectiveness
                        of your content strategy, enabling you to make informed decisions to
                        enhance visibility and impact.</span>
                </div>
            </Modal.Body>
        </Modal>
    )
}