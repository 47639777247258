// import React from 'react';
// import Form from 'react-bootstrap/Form';
// import { MdKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

// export default function NewPagination({ paginationModel, setPaginationModel, data }) {
//     console.log('pagination model is ', data)
//     // Destructure page and pageSize from paginationModal
//     const { page, pageSize } = paginationModel;

//     const totalPages = data?.totalPages;
//     const pageNumbers = [];
//     for (let i = 1; i <= totalPages; i++) {
//         pageNumbers.push(i);
//     }

//     const handlePageChange = (pageNumber) => {
//         setPaginationModel({ ...paginationModel, page: pageNumber });
//     };

//     const getPageNumbersToShow = () => {
//         const pagesToShow = 5;
//         const currentPageIndex = pageNumbers.indexOf(page);
//         const halfPagesToShow = Math.floor(pagesToShow / 2);
//         let startPageIndex = currentPageIndex - halfPagesToShow;
//         let endPageIndex = currentPageIndex + halfPagesToShow;

//         if (startPageIndex < 0) {
//             startPageIndex = 0;
//             endPageIndex = pagesToShow - 1;
//         }

//         if (endPageIndex >= pageNumbers.length) {
//             endPageIndex = pageNumbers.length - 1;
//             startPageIndex = endPageIndex - (pagesToShow - 1);
//         }

//         return pageNumbers.slice(startPageIndex, endPageIndex + 1);
//     };

//     const renderPageNumbers = () => {
//         const visiblePageNumbers = getPageNumbersToShow();
//         return visiblePageNumbers.map((number) => (
//             <li key={number} className={page === number ? 'active' : ''}>
//                 <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(number)}>{number}</a>
//             </li>
//         ));
//     };

//     return (
//         <div className='pagination-component'>
//             <div className="container-fluid">
//                 <div className="row">
//                     <div className="col-md-6 align-self-center">
//                         <div className="select-wrapper">
//                             <p>Rows per page: </p>
//                             <Form.Select
//                                 aria-label="Default select example"
//                                 value={pageSize}
//                                 onChange={(e) => setPaginationModel({ ...paginationModel, pageSize: e.target.value })}
//                             >
//                                 <option value="20">20</option>
//                                 <option value="30">30</option>
//                                 <option value="50">50</option>
//                                 <option value="100">100</option>
//                             </Form.Select>
//                         </div>
//                     </div>
//                     <div className="col-md-6 align-self-center">
//                         <ul className="pagination-list">
//                             <li><a onClick={() => handlePageChange(page - 1)} disabled={page === 1}><MdKeyboardArrowLeft /></a></li>
//                             {renderPageNumbers()}
//                             {pageNumbers.length > 5 && <li style={{color:"white"}}>...</li>}
//                             <li><a onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}><MdOutlineKeyboardArrowRight /></a></li>
//                         </ul>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }



import React from 'react';
import Form from 'react-bootstrap/Form';
import { MdKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

export default function NewPagination({ paginationModel, setPaginationModel, data }) {
    console.log('pagination model is ', data);
    // Destructure page and pageSize from paginationModel
    const { page, pageSize } = paginationModel;

    const totalPages = data?.totalPages || 0;
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setPaginationModel({ ...paginationModel, page: pageNumber });
        }
    };

    const getPageNumbersToShow = () => {
        const pagesToShow = 5;
        const currentPageIndex = pageNumbers.indexOf(page);
        const halfPagesToShow = Math.floor(pagesToShow / 2);
        let startPageIndex = currentPageIndex - halfPagesToShow;
        let endPageIndex = currentPageIndex + halfPagesToShow;

        if (startPageIndex < 0) {
            startPageIndex = 0;
            endPageIndex = pagesToShow - 1;
        }

        if (endPageIndex >= pageNumbers.length) {
            endPageIndex = pageNumbers.length - 1;
            startPageIndex = endPageIndex - (pagesToShow - 1);
        }

        if (startPageIndex < 0) {
            startPageIndex = 0;
        }

        return pageNumbers.slice(startPageIndex, endPageIndex + 1);
    };

    const renderPageNumbers = () => {
        const visiblePageNumbers = getPageNumbersToShow();
        return visiblePageNumbers.map((number) => (
            <li key={number} className={page === number ? 'active' : ''}>
                <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(number)}>{number}</a>
            </li>
        ));
    };

    return (
        <div className='pagination-component'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 align-self-center">
                        <div className="select-wrapper">
                            <p>Rows per page: </p>
                            <Form.Select
                                aria-label="Default select example"
                                value={pageSize}
                                onChange={(e) => setPaginationModel({ ...paginationModel, pageSize: Number(e.target.value), page: 1 })}
                            >
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </Form.Select>
                        </div>
                    </div>
                    <div className="col-md-6 align-self-center" style={{ textAlign: 'right' }}>
                        <ul className="pagination-list" style={{ display: 'inline-flex', listStyleType: 'none', padding: 0 }}>
                            <li style={{ margin: '0 5px' }}>
                                <a 
                                    onClick={() => handlePageChange(page - 1)} 
                                    style={{ cursor: page === 1 ? 'default' : 'pointer', color: page === 1 ? 'gray' : 'black' }}
                                >
                                    <MdKeyboardArrowLeft />
                                </a>
                            </li>
                            {renderPageNumbers()}
                            {pageNumbers.length > 5 && <li style={{color: "white", margin: '0 5px'}}>...</li>}
                            <li style={{ margin: '0 5px' }}>
                                <a 
                                    onClick={() => handlePageChange(page + 1)} 
                                    style={{ cursor: page === totalPages ? 'default' : 'pointer', color: page === totalPages ? 'gray' : 'black' }}
                                >
                                    <MdOutlineKeyboardArrowRight />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
