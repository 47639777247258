import { createSlice } from '@reduxjs/toolkit';

const reelsSlice = createSlice({
  name: 'reels',
  initialState: {
    reelsData: [],
    isLoading: false,
    loadingReels: false,
    error: null,
    reelsComplete: null
  },
  reducers: {
    setReelsData: (state, action) => {
      state.reelsData = [...state.reelsData, ...action?.payload];
    },
    setLoadingReels: (state, action) => {
      state.loadingReels = action?.payload;
    },
    setReelsError: (state, action) => {
      state.error = action?.payload;
    },
    setReelsFinished: (state, action) => {
      state.reelsComplete = action?.payload;
    },
    resetReels: (state, action) => {
      state.reelsData = [];
      state.loadingReels = false;
      state.error = null;
      state.reelsComplete = null;
    }
  },
});

export const { setReelsData, setReelsFinished, setLoadingReels, setReelsError,resetReels } = reelsSlice.actions;

export default reelsSlice.reducer;
