import React, { useEffect, useState } from "react";
import sIcon from "../assets/images/icons/s-img.png";
import videoImg1 from "../assets/images/video-img-1.png";
import { NavItem, ProgressBar, Spinner } from "react-bootstrap";
import pinIcon from "../assets/images/icons/pin-icon.png";
import { FaArrowRightLong } from "react-icons/fa6";
import ShortenNumber from "../utilities/ShortenNumber";
import { useSelector } from "react-redux";
import tikTockIcon from "../assets/images/icons/tick-tock-icon.png";


export default function ChartsVideoInfoTopCards({ analyticsData, videoChartTab, setVideoChartTab, profileData, analyticsDataLoading }) {
    const [topVideos, setTopVideos] = useState([])
    const [videosPerYear, setVideosPerYear] = useState([])
    const [seeFullPinned, setSeeFullPinned] = useState(false)
    var videosData = useSelector((state) => state?.reels?.reelsData);
    var pinnedVideos = videosData?.filter((item) => item?.isPinned)

    useEffect(() => {
        const { top_videos, videos_timeseries, views_timeseries } = analyticsData

        // if (top_videos) {
        //     setTopVideos(top_videos?.top_comments)
        // })

        if (videosData?.length > 0) {
            let topThreeVideos
            if (profileData?.platform == 'instagram') {
                topThreeVideos = [...videosData]
                    ?.sort((a, b) => b.Views - a.Views)
                    ?.slice(0, 3);
            } else {
                topThreeVideos = [...videosData]
                    ?.sort((a, b) => b.views - a.views)
                    ?.slice(0, 3);
            }

            setTopVideos(topThreeVideos)
        }

        if (videos_timeseries) {
            const videosPerYear = {};
            const viewsCountPerYear = {};

            // Iterate over the videos_timeseries array
            videos_timeseries.forEach(entry => {
                const datePosted = new Date(entry["Date Posted"]);
                const year = datePosted.getFullYear();

                // Initialize counters if not already present
                if (!videosPerYear[year]) {
                    videosPerYear[year] = 0;
                }

                // Count videos and accumulate views count for the year
                videosPerYear[year] += entry.Videos;
                // Accumulate views count for the year (assuming a field 'Views' exists)
            });


            views_timeseries.forEach(entry => {
                const datePosted = new Date(entry["Date Posted"]);
                const year = datePosted.getFullYear();

                // Initialize counters if not already present
                if (!viewsCountPerYear[year]) {
                    viewsCountPerYear[year] = 0;
                }

                // Count videos and accumulate views count for the year
                viewsCountPerYear[year] += entry.Views;
                // Accumulate views count for the year (assuming a field 'Views' exists)
            });


            // Convert the results to an array of objects
            const videosPerYearArray = Object.keys(videosPerYear).map(year => ({
                year: parseInt(year),
                totalVideos: videosPerYear[year],
                totalViews: viewsCountPerYear[year]
            }));

            setVideosPerYear(videosPerYearArray);
        }

    }, [analyticsData, videosData])

    console.log('topVideos', videosPerYear)
    return (
        <div className="chart-videos-info-wrapper">
            <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-6 mb-3 ">
                    <div className="main-info-wrapper">
                        <span className="white strong mb-2">Pinned Videos</span>
                        {/* <div className="info-wrapper">
                            <div className="main-wrapper">
                                <img src={sIcon} alt="sIcon" className='sIcon'/>
                                <div className="main-wrapper">
                                    <img src={tikTockIcon} alt="videoImg1" className='video-img'/>
                                    <div className="text-wrapper">
                                        <span className="white small strong">Apr/12/2024</span>
                                        <span className="ex-small strong">829k Comments</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main-wrapper">
                                <div className="progress-wrapper">
                                    <span className="small white">605k</span>
                                    <ProgressBar now={60}/>
                                </div>
                                <img src={pinIcon} alt="pinIcon" className='pinIcon'/>
                            </div>
                        </div> */}
                        {pinnedVideos?.length > 0 ?
                            seeFullPinned ? pinnedVideos?.slice(-3)?.map((item, index) => {
                                return (
                                    <a style={{ cursor: "pointer" }} className="info-wrapper" href={`${item?.Link || item?.link}`} target="_blank"
                                    >
                                        <div className="main-wrapper">
                                            <img src={sIcon} alt="sIcon" className='sIcon' />
                                            <div className="main-wrapper">
                                                <img src={item?.thumbnail || tikTockIcon} alt="videoImg1" className='video-img' />
                                                <div className="text-wrapper">
                                                    <span className="white small strong">{new Date(item?.date || item['Date Posted'])?.toLocaleDateString()}</span>
                                                    <span className="ex-small strong">{ShortenNumber(item?.comments || item?.Comments)} Comments</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="main-wrapper">
                                            <div className="progress-wrapper">
                                                <span className="small white">{ShortenNumber(item?.views || item?.views)}</span>
                                                <ProgressBar now={60} />
                                            </div>
                                            {/*<img src={pinIcon} alt="pinIcon" className='pinIcon' />*/}
                                        </div>
                                    </a>
                                )
                            }) : pinnedVideos?.slice(-3)?.map((item, index) => {
                                return (
                                    <a style={{ cursor: "pointer" }} className="info-wrapper" href={`${item?.Link || item?.link}`} target="_blank"
                                    >
                                        <div className="main-wrapper">
                                            <img src={sIcon} alt="sIcon" className='sIcon' />
                                            <div className="main-wrapper">
                                                <a href="#"> <img src={item?.thumbnail || tikTockIcon} alt="videoImg1" className='video-img' /></a>
                                                <div className="text-wrapper">
                                                    <span className="white small strong">{new Date(item?.date || item['Date Posted'])?.toLocaleDateString()}</span>
                                                    <span className="ex-small strong">{ShortenNumber(item?.comments || item?.Comments)} Comments</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="main-wrapper">
                                            <div className="progress-wrapper">
                                                <span className="small white">{ShortenNumber(item?.views || item?.views)}</span>
                                                <ProgressBar now={60} />
                                            </div>
                                            {/*<img src={pinIcon} alt="pinIcon" className='pinIcon' />*/}
                                        </div>
                                    </a>
                                )
                            }) : <p style={{ textAlign: 'center' }}>{(profileData?.platform == 'youtube' || profileData?.platform == 'instagram') ? 'This feature is not available.' : 'No pinned videos found'}</p>
                        }
                        {pinnedVideos?.length > 3 && <button onClick={() => setSeeFullPinned(!seeFullPinned)} className="btn-style simple dark">View Full Data <FaArrowRightLong /></button>}
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 mb-3 ">
                    <div className="main-info-wrapper">
                        <span className="white strong mb-2">Top performing videos</span>
                        {/* <div className="info-wrapper">
                            <div className="main-wrapper">
                                <img src={sIcon} alt="sIcon" className='sIcon'/>
                                <div className="main-wrapper">
                                    <img src={videoImg1} alt="videoImg1" className='video-img'/>
                                    <div className="text-wrapper">
                                        <span className="white small strong">Apr/12/2024</span>
                                        <span className="ex-small strong">829k Comments</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main-wrapper">
                                <div className="progress-wrapper">
                                    <span className="small white">605k</span>
                                    <ProgressBar now={60}/>
                                </div>
                            </div>
                        </div> */}
                        {topVideos?.slice(-3)?.map((item, index) => {
                            return (
                                <div style={{ cursor: "pointer" }} className="info-wrapper" key={index + 1} onClick={() => window.open(`${item?.link || item?.Link}`, '_blank')}
                                >
                                    <div className="main-wrapper">
                                        <img src={sIcon} alt="sIcon" className='sIcon' />
                                        <div className="main-wrapper">
                                            <a href="#"> <img src={profileData?.platform == 'instagram' ? `https://instagramserver.tokbackup.com/proxy?media_url=${encodeURIComponent(
                                                item.thumbnail
                                            )}` : item?.avatar || item?.thumbnail || tikTockIcon}
                                                alt="videoImg1" className='video-img' /></a>
                                            <div className="text-wrapper">
                                                <span className="white small strong">{new Date(item?.date || item['Date Posted'])?.toLocaleDateString()}</span>
                                                <span
                                                    className="ex-small strong">{ShortenNumber(item?.Comments || item?.comments)} Comments</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="main-wrapper">
                                        <div className="progress-wrapper">
                                            <span className="small white">{ShortenNumber(item?.Views || item?.views)}</span>
                                            <ProgressBar now={60} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <button onClick={() => { setVideoChartTab('dashboard') }} className="btn-style simple dark">View Full Data <FaArrowRightLong /></button>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 mb-3 ">
                    <div className="main-info-wrapper">
                        <span className="white strong mb-2">Videos posted by year</span>
                        {analyticsDataLoading && <span
                            className='white d-inline-flex gap-2'>  <Spinner
                                animation="grow" size="sm" /><span
                                    className=' d-inline-block white'>Analyzing...</span></span>}
                        {videosPerYear?.slice(-3)?.map((item, index) => {
                            return (
                                <div className="info-wrapper" key={index + 1}>
                                    <span className="small white">{item['year']}</span>
                                    <span className="small white">{ShortenNumber(item?.totalVideos)} Videos</span>
                                    <div className="main-wrapper">
                                        <div className="progress-wrapper">
                                            <span className="small white">{ShortenNumber(item?.totalViews)}</span>
                                            <ProgressBar now={60} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <button onClick={() => { setVideoChartTab('dashboard') }} className="btn-style simple dark">View Full Data <FaArrowRightLong /></button>
                    </div>
                </div>
            </div>
        </div>
    )
}
