import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
/*
export default function HorizontalBarChart2Hashtags({hashtagsData}){

    hashtagsData.sort((a, b) => parseInt(b.count) - parseInt(a.count));

    const topHashtags = hashtagsData.slice(0, 8);

    const hashtags = topHashtags.map(hashtag => `#${hashtag.hashtag}`) || [];
    const counts = topHashtags.map(hashtag => hashtag.count) || [];

    console.log('top hashtags ', counts)


    const options = {
        chart: {
            type: 'bar',
            height: 250,
            width: '100%',
            toolbar: {
                show: false,
                tools: {
                    download: false
                },
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                columnWidth: '20%',
                distributed: false, // Distribute bars with margins
                barHeight: '60%', // Adjust the height of the bars
                colors: {
                    ranges: [{
                        from: 0,
                        to: 100,
                        // barPercentage: 0.5,
                        color: '#EB55B4' // red color
                    }]
                },
                borderRadius: 5 ,
                dataLabels: {
                    position: 'center', // Position the labels on top of the bars
                    // offsetX: 100, // Adjust this value to move labels outside the bars
                    style: {
                        fontSize: '12px',
                        colors: ['#646F7C']
                    }
                },
            }
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'center',

            style: {
                fontSize: '10px',
                colors: ['#fff']
            },
            formatter: function (val, opt) {
                const minutes = Math.floor(val / 60);
                const seconds = val % 60;
                return `${seconds}`;

            },
            // offsetX: 20,
            dropShadow: {
                enabled: true
            }
        },
        xaxis: {
            // categories: ['10-15m', '5-10m', '1-5m', '30-60s', '15-30s', '0-15s'],
            categories: hashtags,
            dataLabels: {
                style: {
                    colors: ['#F44336', '#E91E63', '#9C27B0']
                }
            },
            ticks: {
                mirror: true
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            labels: {
                show: false,
            }
        },
        yaxis: {
            ticks: {
                mirror: true
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            labels: {
                show: true,
                align: 'left', // Align labels to the left
                offsetX: -10, // Add space after the labels
                style: {
                    colors: '#646F7C', // Change color of individual labels
                    fontSize: '12px',
                }
            }
        },
        grid: {
            show: false
        },
        tooltip: {
            enabled: false, // Disable the tooltip
        },
    };

    const series = [{
        name: 'Sales',
        data: counts
        // data: counts
    }];

    return(
        <div className='horizontal-bar-chart'>
            <ReactApexChart options={options} series={series} type="bar" height={255} />
        </div>
    )
}
*/


const HorizontalBarChart2Hashtags = ({hashtagsData}) => {
    hashtagsData?.sort((a, b) => parseInt(b.count) - parseInt(a.count));

    const topHashtags = hashtagsData?.slice(0, 8);

    const hashtags = topHashtags?.map(hashtag => `#${hashtag.hashtag}`) || [];
    const counts = topHashtags?.map(hashtag => hashtag.count) || [];

    const barColors = ['']

    const data = {
        labels: hashtags,
        datasets: [
            {
                label: 'Time Spent',
                // data: [25, 1000, 400, 90, 56, 200, 300],
                data: counts,
                backgroundColor: 'rgba(235, 85, 180, 1)',
                borderRadius: 10,
                barThickness: 'flex',
                maxBarThickness: 15, // Adjusted value
            },
        ],
    };

    const options = {
        // maintainAspectRatio: false,
        indexAxis: 'y',
        maintainAspectRatio: true,
        responsive: true,
        aspectRatio: 0,
        devicePixelRatio: 4,
        tooltips: {
            enabled: false,
        },
        scales: {
            x: {
                ticks: {
                    callback: (value) => `${value}`,
                    color: '#646F7C',
                    display: false,
                },
                font: {
                    size: 5, // Font size for x-axis labels
                },
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    color: '#646F7C',
                    // align: 'left',
                    crossAlign: "far",
                    font: {
                        size: 12, // Font size for x-axis labels
                    },
                },
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                // enabled: false,
                callbacks: {
                    label: (context) => `${context.raw}`,
                },
            },
            datalabels: {
                align: 'end',
                anchor: 'end',
                color: 'white',
                clip: true, // Ensuring labels stay within chart area
                formatter: (value) => `${value}`,
                padding: {
                    // right: 10 // Adding some padding to avoid overlap
                },
                font: {
                    size: 10, // Font size for data labels
                },
            },
        },
        layout: {
            padding: {
                right: 30, // Adding padding to ensure enough space for the labels

            }
        },
    };

    return (
        <div style={{ position: 'relative', height: '250px', width: '100%', padding:'20px 0 0' }}>
            {hashtagsData?.length > 0 ? <Bar data={data} options={options} plugins={[ChartDataLabels]} /> : <span>No hashtags found.</span>}
        </div>
    );
};

export default HorizontalBarChart2Hashtags;