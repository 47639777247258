import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto'; // Import Chart object
import ShortenNumber from '../utilities/ShortenNumber';

export default function LineChartBookmarks({ analyticsData }) {
    const [bookmarksTimeSeries, setBookmarksTimeSeries] = useState([]);
    const canvasRef = useRef(null);

    // useEffect(() => {
    //     if (!analyticsData) return;

    //     var bookmarksTimeSeries = analyticsData?.bookmarks_timeseries || [];
    //     var bookmarksTimeSeriesByYear = {};

    //     bookmarksTimeSeries?.forEach((bookmark) => {
    //         var date = new Date(bookmark['Date Posted']);
    //         var year = date.getFullYear();

    //         if (bookmarksTimeSeriesByYear[year]) {
    //             bookmarksTimeSeriesByYear[year].totalBookmarks += bookmark.Bookmarks;
    //             bookmarksTimeSeriesByYear[year].count += 1;
    //         } else {
    //             bookmarksTimeSeriesByYear[year] = {
    //                 totalBookmarks: bookmark.Bookmarks,
    //                 count: 1
    //             };
    //         }
    //     });

    //     var averagedBookmarksTimeSeries = Object.entries(bookmarksTimeSeriesByYear).map(([year, data]) => ({
    //         year: year,
    //         averageBookmarks: (data.totalBookmarks / data.count).toFixed(0)
    //     }));

    //     setBookmarksTimeSeries(averagedBookmarksTimeSeries);

    // }, [analyticsData]);

    useEffect(() => {
        if (!analyticsData) return;
    
        const bookmarksTimeSeries = analyticsData?.bookmarks_timeseries || [];
        const bookmarksTimeSeriesByDay = {};
    
        bookmarksTimeSeries?.forEach((bookmark) => {
            const date = new Date(bookmark['Date Posted']);
            const day = date.toISOString().split('T')[0]; // Get the date string in YYYY-MM-DD format
    
            if (bookmarksTimeSeriesByDay[day]) {
                bookmarksTimeSeriesByDay[day].totalBookmarks += bookmark.Bookmarks;
                bookmarksTimeSeriesByDay[day].count += 1;
            } else {
                bookmarksTimeSeriesByDay[day] = {
                    totalBookmarks: bookmark.Bookmarks,
                    count: 1
                };
            }
        });
    
        const averagedBookmarksTimeSeries = Object.entries(bookmarksTimeSeriesByDay).map(([day, data]) => ({
            year: day,
            averageBookmarks: (data.totalBookmarks / data.count).toFixed(0) // Average bookmarks per day
        }));
    
        setBookmarksTimeSeries(averagedBookmarksTimeSeries);
    
    }, [analyticsData]);
    

    useEffect(() => {
        // Create gradient
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 400); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, 'rgba(0, 242, 234, 0)'); // Adjust as needed
        // rgba(66, 43, 39, 1)

        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: bookmarksTimeSeries?.map((data) => data.year),
                datasets: [
                    {
                        label: 'Average Bookmarks per Year',
                        backgroundColor: gradient,
                        borderColor: 'rgba(0, 242, 234, 1)',
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
                        data: bookmarksTimeSeries.map((data) => data.averageBookmarks),
                        lineTension: 0.4, // Adjust the tension value for smoothness
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    x: {
                        ticks : {
                            color:'#D9D9D9',
                        },
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        ticks: {
                            color:'#D9D9D9',
                            beginAtZero: true,
                            callback: function (value) {
                                return ShortenNumber(value);
                            },
                            maxTicksLimit:5
                        },
                        grid: {
                            display: true,
                            color: 'rgba(100, 111, 124, .2)',
                        },
                    }
                },
            },
        });

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [bookmarksTimeSeries]);

    return (
        <div className='graph-chart-wrapper'>
            <canvas ref={canvasRef} />
        </div>
    );
}
