import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import chartDownIcon from "../assets/images/icons/chart-down-circular-arrow-icon.png";

import LineChart from "../components/LineChart";
import cartUpIcon from "../assets/images/icons/chart-up-circular-arrow-icon.png";
import infoQuestionMark from "../assets/images/icons/info-question-mark-icon.png";
import ShortenNumber from "../utilities/ShortenNumber";


import Chart from 'chart.js/auto'; // Import Chart object
import LineChartShares from "./LineChartShares";
import LineChartLikes from "./LineChartLikes";
import LineChartViews from "./LineChartViews";
import LineChartsComments from "./LineChartComments";
import LineChartBookmarks from "./LineChartBookmarks";
import Dropdown from "react-bootstrap/Dropdown";

export default function TotalChartsZoomedModalShares({ showSharesModal, setShowSharesModal, analyticsData }) {
    const [sharesTimeSeries, setSharesTimeSeries] = useState([])

    // useEffect(() => {
    //     if (!analyticsData) return
    //     var sharesTimeSeries = (analyticsData?.shares_timeseries)
    //     //if sharesTimeSeries length is bigger than 100, calculate the total views  for each month, instad hcing the data for each day 
    //     if (sharesTimeSeries?.length > 100) {

    //         var sharesTimeSeriesByMonth = {}
    //         sharesTimeSeries.forEach((shares) => {

    //             var date = new Date(shares['Date Posted'])
    //             var month = date.getMonth()
    //             //get month word 
    //             month = new Date(date.getFullYear(), month, 1).toLocaleString('default', { month: 'short' })
    //             var year = date.getFullYear()
    //             var monthYear = month + '-' + year
    //             if (sharesTimeSeriesByMonth[monthYear]) {
    //                 sharesTimeSeriesByMonth[monthYear].Shares += shares.Shares
    //             } else {
    //                 sharesTimeSeriesByMonth[monthYear] = {
    //                     Shares: shares.Shares,
    //                     'Date Posted': monthYear
    //                 }
    //             }
    //         }
    //         )

    //         sharesTimeSeries = Object.values(sharesTimeSeriesByMonth)
    //     }
    //     setSharesTimeSeries(sharesTimeSeries)



    // }, [analyticsData])

    // console.log('sharesTimeSeries?.map((shares) => (shares?.Shares?.toFixed(0)))',sharesTimeSeries?.map((share) => (share?.Shares?.toFixed(0))))


    // useEffect(() => {
    //     // Create gradient
    //     const canvas = document.getElementById('sharesChart');
    //     const ctx = canvas.getContext('2d');
    //     const gradient = ctx.createLinearGradient(0, 0, 0, '400'); // Adjust according to your chart size
    //     gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
    //     gradient.addColorStop(0, 'rgba(66, 30, 56, 1)'); // A djust as needed

    //     // Apply gradient as background color
    //     const chartInstance = new Chart(ctx, {
    //         type: 'line',
    //         data: {
    //             labels: [2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024],
    //             datasets: [
    //                 {
    //                     label: 'My second dataset',
    //                     backgroundColor: gradient,
    //                     borderColor: 'rgba(255, 22, 93, 1)',
    //                     borderWidth: 2,
    //                     fill: true,
    //                     pointBackgroundColor: 'rgba(0, 0, 0, 0)',
    //                     pointBorderColor: 'rgba(0, 0, 0, 0)',
    //                     pointHoverBackgroundColor: '#fff',
    //                     pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
    //                     data: sharesTimeSeries?.map((share) => (share?.Shares?.toFixed(0))),
    //                     lineTension: 0.4, // Adjust the tension value for smoothness
    //                     zIndex: 1,
    //                 }
    //             ]
    //         },
    //         options: {
    //             plugins: {
    //                 legend: {
    //                     display: false,
    //                 }
    //             },
    //             scales: {
    //                 yAxes: [{
    //                     ticks: {
    //                         beginAtZero: true
    //                     }
    //                 }],
    //                 xAxes: [{
    //                     gridLines: {
    //                         z: 99
    //                     }
    //                 }],
    //                 x: {
    //                     // ticks: {
    //                     //     display: false // Hide the ticks (labels) on the x-axis
    //                     // },
    //                     grid: {
    //                         display: false,
    //                     }
    //                 },
    //                 y: {
    //                     // ticks: {
    //                     //     display: false // Hide the ticks (labels) on the x-axis
    //                     // },
    //                     grid: {
    //                         display: true,
    //                         color: 'rgba(100, 111, 124, .2)',
    //                         zIndex: 9999,
    //                         drawOnChartArea: true,
    //                     },
    //                 }
    //             },

    //         },
    //     });

    //     return () => {
    //         chartInstance.destroy(); // Cleanup chart instance when component unmounts
    //     };
    // }, []);


    const [selectedOption, setSelectedOption] = useState('total_shares')

    const handleChangeOption = (e) => {
        setSelectedOption(e.target.value)
    }

    return (
        <Modal
            show={showSharesModal}
            onHide={() => setShowSharesModal(false)}
            dialogClassName="line-chart"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="select-detail-wrapper">
                    {/* <select className="form-select" aria-label="Default select example"
                        style={{ color: '#FDBD61' }} value={selectedOption}
                        onChange={(e) => handleChangeOption(e)}>
                        <option value="total_views" >Total Views</option>
                        <option value="total_likes">Total Likes</option>
                        <option value="total_shares" selected>Total Shares</option>
                        <option value="total_comments">Total Comments</option>
                        <option value="total_bookmarks">Total Bookmarks</option>
                    </select> */}
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ color: '#FDBD61' }}>{selectedOption !== 'total_shares' ? selectedOption : 'TOTAL SHARES'}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_views' } })}>TOTAL VIEWS</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_likes' } })}>TOTAL LIKES</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_shares' } })}>TOTAL SHARES</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_comments' } })}>TOTAL COMMENTS</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_bookmarks' } })}>TOTAL BOOKMARKS</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <div className="rating">
                        <h3>{ShortenNumber(analyticsData?.totals?.total_shares)}</h3>
                        <div className="text down">
                            <img src={cartUpIcon} alt="cartUpIcon" className='up-arrow' />
                            <img src={chartDownIcon} alt="cartUpIcon" className='down-arrow' />
                            <span className="ex-small">93 (8.0%)</span>
                        </div>
                    </div>
                    <div className="rank">
                        <div className="dot" style={{ background: '#00F2EA' }}></div>
                        <span className="small">Average</span>
                        <img src={infoQuestionMark} alt="infoQuestionMark" />
                        <div className="info-text">
                            <span className="white small">Graphs labeled as "Average" signify that your content is performing in line with typical expectations or industry standards.</span>
                        </div>
                    </div>
                </div>
                <div className="chart-wrapper">
                    {/* <LineChart data={sharesTimeSeries?.map((share) => (share?.Shares?.toFixed(0)))} analyticsData={analyticsData}/> */}
                    {/* <div className='graph-chart-wrapper'>
                        <canvas id="sharesChart" />
                    </div> */}
                    {selectedOption == 'total_shares' && <LineChartShares analyticsData={analyticsData} />}
                    {selectedOption == 'total_likes' && <LineChartLikes analyticsData={analyticsData} />}
                    {selectedOption == 'total_views' && <LineChartViews analyticsData={analyticsData} />}
                    {selectedOption == 'total_comments' && <LineChartsComments analyticsData={analyticsData} />}
                    {selectedOption == 'total_bookmarks' && <LineChartBookmarks analyticsData={analyticsData} />}
                </div>
                <div className="content-wrapper">
                    <span>The "Total Views" graph is a vital analytics tool that
                        tracks the cumulative views your videos receive over time. It helps
                        identify trends, peak engagement periods, and the overall effectiveness
                        of your content strategy, enabling you to make informed decisions to
                        enhance visibility and impact.</span>
                </div>
            </Modal.Body>
        </Modal>
    )
}