import React, {useState} from 'react'

// **************************** Dependencies *****************************

import {Link, useNavigate} from "react-router-dom";

// **************************** Images *****************************

import darkThemeLogo from "../assets/images/icons/logo-dark-theme.png";
import darkThemeLogoMask from "../assets/images/icons/logo-mask.png";
import searchIcon from "../assets/images/icons/search-icon.png";
import downloadIcon from "../assets/images/icons/download-icon.png";
import folderIcon from "../assets/images/icons/folder-icon.png";
import userIcon from "../assets/images/icons/user-icon.png";
import wheelIcon from "../assets/images/icons/wheel-icon.png";
import gearIcon from "../assets/images/icons/gear-icon.png";
import avatarIcon from "../assets/images/icons/avatar-icon.png";
import logOutIcon from "../assets/images/icons/log-out-icon.png";
import sidebarBoostImg from "../assets/images/icons/sidebar-boost-img.png";
import lightingIcon from "../assets/images/icons/lighting-icon.png";

import { useDispatch } from "react-redux";
import { userLogoutAsyncThunk } from '../store/authSlice';
import ProfileNotFound from './ProfileNotFound';


export default function SideBarComponent({isSidebarActive, isSidebarMobileActive, sidebarMobileCloseHandler}) {

    const[activePage , setActivePage] = useState(0)
    const [errorModelMessage, setErrorModelMessage] = useState("");
    const [successmessage, setSuccessmessage] = useState("");
    const [CTAText, setCTAText] = useState('')
    const [showCTA, setShowCTA] = useState(false);
    const [success, setSuccess] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const user = () => {
        try {
            let user = localStorage.getItem("user");
            if (user) user = JSON.parse(user);
            return user ?? null;
        } catch (error) {
            return null;
        }
    };

    const userData = user()

    console.log('userData' , userData)

    const handleActivePage = (page) => {
        setActivePage(page)
        localStorage.setItem('activePage', JSON.stringify(page))
    }

    const handleLogout = async () => {
        try {
            const {payload} = await dispatch(userLogoutAsyncThunk({ navigate,  cb: () => {
                // toast.success('Signin successfull')
                setErrorModelMessage(`Success! You've logged out!`)
                setCTAText('Log out Successfull')
                setSuccess(true)
                setTimeout(() => {
                    navigate('/sign-in')
                },1500)
            } }))

            if(payload?.response?.data && payload?.response?.data?.message){
                setErrorModelMessage(payload?.response?.data?.message)
                setSuccess(false)
                return
            }
        } catch (error) {
            console.log('error logging out', error)
        }
    }

    const currentPage = JSON.parse(localStorage.getItem('activePage')) || activePage

    return (
        <div className={`sidebar-outer ${isSidebarActive ? 'active' : ''} ${isSidebarMobileActive ? 'show' : ''}`}>
            <div className={`sidebar-main-wrapper `}>
                <div className="logo-wrapper">
                    <a className="logo"><img src={darkThemeLogo} alt="darkThemeLogo"/></a>
                    <a className="logo-mask"><img src={darkThemeLogoMask} alt="darkThemeLogoMask"/></a>
                </div>
                <div className="sidebar-menu">
                    <ul className="menu">
                        <li><Link to="/" className={`btn-style simple ${currentPage === 0 ? 'active' : ''} `} onClick={() => handleActivePage(0)}><img src={searchIcon} alt="searchIcon"/><span>Scan new profile</span></Link>
                        </li>
                        <li><Link to="/downloads2" className={`btn-style simple ${currentPage === 1 ? 'active' : ''} `} onClick={() => handleActivePage(1)}><img src={downloadIcon} alt="downloadIcon"/><span>My Downloads</span></Link>
                        </li>
                        {/*<li><a  className={`btn-style simple ${currentPage === 2 ? 'active' : ''} `} onClick={() => handleActivePage(2)}><img src={folderIcon} alt="folderIcon"/><span>My Folders</span></a></li>*/}
                        <li><Link to="/my-profiles" className={`btn-style simple ${currentPage === 3 ? 'active' : ''} `} onClick={() => handleActivePage(3)}><img src={userIcon} alt="userIcon"/><span>My Profiles</span></Link>
                        </li>
                    </ul>
                </div>
                <div className="sidebar-footer">
                    <ul className="menu">
                        <li><a className='btn-style simple'><img src={wheelIcon}
                                                                          alt="wheelIcon"/><span>Support</span></a>
                        </li>
                        <li><Link to="/setting" className={`btn-style simple ${currentPage === 4 ? 'active' : ''} `} onClick={() => handleActivePage(4)}><img src={gearIcon}
                                                                          alt="wheelIcon"/><span>Settings</span></Link>
                        </li>
                    </ul>
                    <div className="boost-img">
                        <img src={sidebarBoostImg} alt="sidebarBoostImg" className='boost'/>
                        <a className='btn-style simple lightingIcon '><img src={lightingIcon}
                                                                                    alt="wheelIcon"/></a>
                    </div>
                    {userData && <div className="user-detail">
                        <div className="img-wrapper ">
                            {/* <a ><img src={(userData?.avatar && userData?.avatar !== 'default.jpg') ? userData?.avatar :  avatarIcon} alt="avatarIcon"/></a> */}
                            <a ><img src={userData?.avatar?.includes('https') ? userData?.avatar : (userData?.avatar && userData?.avatar !== 'default.jpg') ? process.env.REACT_APP_ASSET_PATH + userData?.avatar :  userIcon} alt="avatarIcon"/></a>
                        </div>
                        <div className="text-wrapper">
                            <span className="strong">{userData.name}</span>
                            <span className="small">{userData.email}</span>
                        </div>
                        <a onClick={handleLogout} className="logout-icon"><img src={logOutIcon} alt="logOutIcon"/></a>
                    </div>}
                </div>
            </div>
            <div id="overLay" className={` ${isSidebarMobileActive ? 'active' : ''}`} onClick={sidebarMobileCloseHandler}></div>
            {errorModelMessage && <ProfileNotFound message={errorModelMessage} setErrorModelMessage={setErrorModelMessage} CTAText={CTAText} success={success}/>}

        </div>

    )
}