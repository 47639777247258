
import React, { useRef, useEffect } from 'react';
import { Chart, registerables } from 'chart.js/auto'; // Import Chart object
import zoomPlugin from 'chartjs-plugin-zoom';
import { CgZoomIn } from "react-icons/cg";
import { CgZoomOut } from "react-icons/cg";
import { TbZoomReset } from "react-icons/tb";
Chart.register(...registerables, zoomPlugin);

export default function LargeGraphChartDistribution({ data, startDate, endDate, daysFilter }) {
  const chartRef = useRef(null);
  const isDateInRange = (date, start, end) => {
    const currentDate = new Date(date);
    return currentDate >= new Date(start) && currentDate <= new Date(end);
  };

  const filterDataByDateRange = (data) => {
    if (startDate && endDate) {
      return data.filter(entry => isDateInRange(entry["Date Posted"], startDate, endDate));
    }
    if (daysFilter) {
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(endDate.getDate() - daysFilter);
      return data.filter(entry => isDateInRange(entry["Date Posted"], startDate, endDate));
    }
    return data;
  };

  console.log("🚀 ~ LargeGraphChart2 ~ data:", data);
  const likeSeriesData = filterDataByDateRange(data?.likes_timeseries || []);

  useEffect(() => {
    const likeRanges = {
      "10+": 0,
      "100+": 0,
      "1k+": 0,
      "2k+": 0,
      "10k+": 0,
      "50k+": 0,
      "100k+": 0,
      "500k+": 0,
      "1m+": 0,
      "2m+": 0,
      "10m+": 0,
      "50m+": 0,
      "100m+": 0,
    };

    likeSeriesData.forEach(item => {
      const likes = item.Likes;

      if (likes >= 100000000) {
        likeRanges["100m+"]++;
      } else if (likes >= 50000000) {
        likeRanges["50m+"]++;
      } else if (likes >= 10000000) {
        likeRanges["10m+"]++;
      } else if (likes >= 2000000) {
        likeRanges["2m+"]++;
      } else if (likes >= 1000000) {
        likeRanges["1m+"]++;
      } else if (likes >= 500000) {
        likeRanges["500k+"]++;
      } else if (likes >= 100000) {
        likeRanges["100k+"]++;
      } else if (likes >= 50000) {
        likeRanges["50k+"]++;
      } else if (likes >= 10000) {
        likeRanges["10k+"]++;
      } else if (likes >= 2000) {
        likeRanges["2k+"]++;
      } else if (likes >= 1000) {
        likeRanges["1k+"]++;
      } else if (likes >= 100) {
        likeRanges["100+"]++;
      } else if (likes >= 10) {
        likeRanges["10+"]++;
      }
    });

    // Create gradient
    const canvas = document?.getElementById("largeGraphChart2");
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 550); // Adjust according to your chart size
    gradient.addColorStop(1, "rgba(25, 31, 47, 1)");
    gradient.addColorStop(0, "rgba(66, 30, 56, 1)"); // Adjust as needed

    // Prepare data for chart
    const chartData = Object.values(likeRanges);
    const chartLabels = Object.keys(likeRanges);

    // Apply gradient as background color
    const chartInstance = new Chart(ctx, {
      type: "line",
      data: {
        labels: chartLabels,
        datasets: [
          {
            label: 'Distribution of Likes',
            backgroundColor: gradient,
            borderColor: 'rgba(255, 22, 93, 1)', // Red color outline
            borderWidth: 2,
            fill: true,
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(255, 22, 93, 1)',
            data: chartData,
            lineTension: 0.4,
            zIndex: 1,
          },
        ],
      },
      options: {
        maintainAspectRatio: true,
        aspectRatio: 0,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            ticks: {
              color: '#D9D9D9', // Set color for labels
            },
            grid: {
              display: false,
              z: 99,
            },
          },
          y: {
            ticks: {
                color: '#D9D9D9', // Set color for labels
              beginAtZero: true,
              maxTicksLimit: 5
            },
            grid: {
              display: true,
              color: 'rgba(100, 111, 124, .2)',
              zIndex: 9999,
              drawOnChartArea: true,
            },
            gridLines: {
              display: true,
              color: 'rgba(100, 111, 124, .2)',
              zIndex: 2, // Set a higher z-index
            },
          },
        },
      },
    });

    chartRef.current.chartInstance = chartInstance;

    return () => {
      chartInstance.destroy(); // Cleanup chart instance when component unmounts
    };
  }, [likeSeriesData, daysFilter, startDate, endDate]);

  return (
    <div className="graph-chart-wrapper with-magnifier-icons">
      <canvas id="largeGraphChart2" className='large-graph-chart-2' ref={chartRef}  />
      <ul className="magnifiers-list">
                <li><a onClick={() => chartRef.current.chartInstance.zoom(1.1)}><CgZoomIn/></a>                </li>
                <li><a onClick={() => chartRef.current.chartInstance.zoom(0.9)}><CgZoomOut/></a>                </li>
                <li><a onClick={() => chartRef.current.chartInstance.resetZoom()}><TbZoomReset/></a>                </li>
            </ul>
    </div>
  );
}
