import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto'; // Import Chart object
import ShortenNumber from '../utilities/ShortenNumber';

export default function LineChartShares({ analyticsData }) {
    const [sharesTimeSeries, setSharesTimeSeries] = useState([]);
    const canvasRef = useRef(null);

    // useEffect(() => {
    //     if (!analyticsData) return;

    //     var sharesTimeSeries = analyticsData?.shares_timeseries;
    //     var sharesTimeSeriesByYear = {};

    //     sharesTimeSeries?.forEach((shares) => {
    //         var date = new Date(shares['Date Posted']);
    //         var year = date.getFullYear();

    //         if (sharesTimeSeriesByYear[year]) {
    //             sharesTimeSeriesByYear[year].totalShares += shares.Shares;
    //             sharesTimeSeriesByYear[year].count += 1;
    //         } else {
    //             sharesTimeSeriesByYear[year] = {
    //                 totalShares: shares.Shares,
    //                 count: 1
    //             };
    //         }
    //     });

    //     var averagedSharesTimeSeries = Object.entries(sharesTimeSeriesByYear).map(([year, data]) => ({
    //         year: year,
    //         averageShares: (data.totalShares / data.count).toFixed(0)
    //     }));

    //     setSharesTimeSeries(averagedSharesTimeSeries);

    // }, [analyticsData]);


    useEffect(() => {
        if (!analyticsData) return;
    
        const sharesTimeSeries = analyticsData?.shares_timeseries;
        const sharesTimeSeriesByDay = {};
    
        sharesTimeSeries?.forEach((shares) => {
            const date = new Date(shares['Date Posted']);
            const day = date.toISOString().split('T')[0]; // Get the date string in YYYY-MM-DD format
    
            if (sharesTimeSeriesByDay[day]) {
                sharesTimeSeriesByDay[day].totalShares += shares.Shares;
                sharesTimeSeriesByDay[day].count += 1;
            } else {
                sharesTimeSeriesByDay[day] = {
                    totalShares: shares.Shares,
                    count: 1
                };
            }
        });
    
        const averagedSharesTimeSeries = Object.entries(sharesTimeSeriesByDay).map(([day, data]) => ({
            year: day,
            averageShares: (data.totalShares / data.count).toFixed(0) // Average shares per day
        }));
    
        setSharesTimeSeries(averagedSharesTimeSeries);
    
    }, [analyticsData]);
    
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 400); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, 'rgba(95, 199, 84, 0)'); // Adjust as needed
        // rgba(231, 173, 90, .4)

        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: sharesTimeSeries.map((data) => data.year),
                datasets: [
                    {
                        label: 'Average Shares per Year',
                        backgroundColor: gradient,
                        borderColor: '#5FC754',
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
                        data: sharesTimeSeries.map((data) => data.averageShares),
                        lineTension: 0.4, // Adjust the tension value for smoothness
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    x: {
                        ticks : {
                            color:'#D9D9D9',
                        },
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        ticks: {
                            color:'#D9D9D9',
                            beginAtZero: true,
                            callback: function (value) {
                                return ShortenNumber(value);
                            },
                            maxTicksLimit:5
                        },
                        grid: {
                            display: true,
                            color: 'rgba(100, 111, 124, .2)',
                        },
                    }
                },
            },
        });

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [sharesTimeSeries]);

    return (
        <div className='graph-chart-wrapper'>
            <canvas ref={canvasRef} />
        </div>
    );
}
