// import React, {useEffect, useRef, useState} from 'react';
// import Chart from 'chart.js/auto'; // Import Chart object

// export default function LineChartLikesPerMonthVideos({ analyticsData }) {

//     const canvasRef = useRef(null);
//     const [perMonthVideosSeries, setPerMonthVideosSeries] = useState([])
//     useEffect(() => {
//         if (!analyticsData) return;
    
//         var likesTimeSeries = analyticsData?.videos_timeseries || [];
    
//         var perMonthVideosSeries = likesTimeSeries.reduce((acc, videos) => {
//             var date = new Date(videos['Date Posted']);
//             var month = date.toLocaleString('default', { month: 'short' });
//             var year = date.getFullYear();
//             var monthYear = `${year}-${month}`;
            
//             if (!acc[monthYear]) {
//                 acc[monthYear] = {
//                     month: monthYear,
//                     count: 0
//                 };
//             }
//             acc[monthYear].count += 1;
//             return acc;
//         }, {});
    
//         var processedPerMonthVideosSeries = Object.values(perMonthVideosSeries);
    
//         setPerMonthVideosSeries(processedPerMonthVideosSeries);
    
//     }, [analyticsData]);
    

//     useEffect(() => {
//         // Create gradient
//         const canvas = canvasRef.current;
//         const ctx = canvas.getContext('2d');
//         const gradient = ctx.createLinearGradient(0, 0, 0, '400'); // Adjust according to your chart size
//         gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
//         gradient.addColorStop(0, 'rgba(92, 74, 133,1)'); // A djust as needed

//         // Apply gradient as background color
//         const chartInstance = new Chart(ctx, {
//             type: 'line',
//             data: {
//                 labels: perMonthVideosSeries.map((share) => share['month']),
//                 datasets: [
//                     {
//                         label: 'Dataset',
//                         backgroundColor: gradient,
//                         // borderColor: 'rgba(255, 22, 93, 1)',
//                         borderColor: '#BA88FF',
//                         borderWidth: 2,
//                         fill: true,
//                         pointBackgroundColor: 'rgba(0, 0, 0, 0)',
//                         pointBorderColor: 'rgba(0, 0, 0, 0)',
//                         pointHoverBackgroundColor: '#fff',
//                         pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
//                         data: perMonthVideosSeries?.map((like) => (like?.count?.toFixed(0))),
//                         lineTension: 0.4, // Adjust the tension value for smoothness
//                         zIndex: 1,
//                     }
//                 ]
//             },
//             options: {
//                 plugins: {
//                     legend: {
//                         display: false,
//                     }
//                 },
//                 scales: {
//                     x: {
//                         ticks: {
//                             color: '#ffffff',
//                         },
//                         grid: {
//                             display: false,
//                             z: 99
//                         }
//                     },
//                     y: {
//                         ticks: {
//                             color: '#ffffff',
//                             beginAtZero: true,
//                             maxTicksLimit:5
//                         },
//                         grid: {
//                             display: true,
//                             color: 'rgba(100, 111, 124, .2)',
//                             zIndex: 9999,
//                             drawOnChartArea: true,
//                         },
//                     }
//                 },

//             },
//         });

//         return () => {
//             chartInstance.destroy(); // Cleanup chart instance when component unmounts
//         };
//     }, [analyticsData, perMonthVideosSeries]);

//     return (
//         <div className='graph-chart-wrapper'>
//             <canvas ref={canvasRef}/>
//         </div>
//     );
// }


import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto'; // Import Chart object

export default function LineChartLikesPerMonthVideos({ analyticsData }) {
    const canvasRef = useRef(null);
    const [perMonthVideosSeries, setPerMonthVideosSeries] = useState([]);

    useEffect(() => {
        if (!analyticsData) return;

        var likesTimeSeries = analyticsData?.videos_timeseries || [];

        // Get the most recent date in the likesTimeSeries
        const mostRecentDate = new Date(Math.max(...likesTimeSeries.map(videos => new Date(videos['Date Posted']))));
        const sixMonthsAgo = new Date(mostRecentDate.setMonth(mostRecentDate.getMonth() - 6));

        // Filter likesTimeSeries to include only the last 6 months
        const filteredTimeSeries = likesTimeSeries.filter(videos => new Date(videos['Date Posted']) >= sixMonthsAgo);

        var perMonthVideosSeries = filteredTimeSeries.reduce((acc, videos) => {
            var date = new Date(videos['Date Posted']);
            var month = date.toLocaleString('default', { month: 'short' });
            var year = date.getFullYear();
            var monthYear = `${year}-${month}`;
            
            if (!acc[monthYear]) {
                acc[monthYear] = {
                    month: monthYear,
                    count: 0
                };
            }
            acc[monthYear].count += videos?.Videos;
            return acc;
        }, {});

        var processedPerMonthVideosSeries = Object.values(perMonthVideosSeries);

        setPerMonthVideosSeries(processedPerMonthVideosSeries);
    }, [analyticsData]);

    useEffect(() => {
        // Create gradient
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 400); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, 'rgba(27, 60, 65, 1)'); // Adjust as needed
        // rgba(92, 74, 133, 1)

        // Apply gradient as background color
        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: perMonthVideosSeries?.map((share) => share['month']),
                datasets: [
                    {
                        label: 'Dataset',
                        backgroundColor: gradient,
                        borderColor: '#1EB185',
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
                        data: perMonthVideosSeries?.map((like) => (like?.count?.toFixed(0))),
                        lineTension: 0.4, // Adjust the tension value for smoothness
                        zIndex: 1,
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color:'#D9D9D9',
                        },
                        grid: {
                            display: false,
                            z: 99
                        }
                    },
                    y: {
                        ticks: {
                            color:'#D9D9D9',
                            beginAtZero: true,
                            maxTicksLimit: 5
                        },
                        grid: {
                            display: true,
                            color: 'rgba(100, 111, 124, .2)',
                            zIndex: 9999,
                            drawOnChartArea: true,
                        },
                    }
                },

            },
        });

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [perMonthVideosSeries]);

    return (
        <div className='graph-chart-wrapper'>
            <canvas ref={canvasRef} />
        </div>
    );
}
