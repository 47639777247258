import React, { useRef, useEffect } from 'react';
import { Chart, registerables } from 'chart.js/auto'; 
import zoomPlugin from 'chartjs-plugin-zoom';
import { CgZoomIn } from "react-icons/cg";
import { CgZoomOut } from "react-icons/cg";
import { TbZoomReset } from "react-icons/tb";
Chart.register(...registerables, zoomPlugin);

export default function LargeGraphChartContentType({data, analyticsData,filterType, colors}) {
    const chartRef = useRef(null);
    console.log('data for chart', data)
    useEffect(() => {
        // Create gradient
        const canvas = document.getElementById('largeGraphChart');
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, '550'); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        // gradient.addColorStop(0, 'rgba(66, 30, 56, 1)'); // A djust as needed
        gradient.addColorStop(0, colors?.gradient); // A djust as needed

        // Apply gradient as background color
        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: data?.labels,
                datasets: [
                    {
                        label: 'My second dataset',
                        backgroundColor: gradient,
                        // borderColor: 'rgba(255, 22, 93, 1)',
                        borderColor: colors?.borderColor,
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: '#191F2F',
                        pointBorderColor: 'rgba(255, 22, 93, 1)', // Point border color
                        pointHoverBackgroundColor: '#191F2F',
                        pointHoverBorderColor: 'rgba(255, 22, 93, 1)',
                        // data: [20,15,30,40,34,30,20,20],
                        data: data?.data,
                        lineTension: 0.4, // Adjust the tension value for smoothness
                        zIndex: 1,
                        pointRadius: 0, // Set the radius of circular points
                        pointHoverRadius: 0,
                        // pointLabelFontSize: 14, // Font size of the point label
                        // pointLabelFontColor: 'rgba(255, 22, 93, 1)',
                        // pointBorderWidth: 2,
                        // pointHoverBorderWidth: 2,
                        // pointHitRadius: 10,
                        images: [
                            'https://via.placeholder.com/150',
                            'https://via.placeholder.com/150',
                            'https://via.placeholder.com/150',
                            'https://via.placeholder.com/150',
                            'https://via.placeholder.com/150',
                            'https://via.placeholder.com/150',
                        ],
                    },

                ]
            },
            options: {
                maintainAspectRatio: true,
                aspectRatio: 0,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    // yAxes: [{
                    //     ticks: {
                    //         beginAtZero: true,
                    //         color: '#ffffff', // Set color for labels
                    //     }
                    // }],
                    // xAxes: [{
                    //     gridLines: {
                    //         z: 99
                    //     }
                    // }],
                    x: {
                        ticks: {
                            // display: false // Hide the ticks (labels) on the x-axis
                            color: '#D9D9D9', // Set color for labels
                        },
                        grid: {
                            display: false,
                            z: 99
                        }
                    },
                    y: {
                        ticks: {
                            // display: false // Hide the ticks (labels) on the x-axis
                            color: '#D9D9D9', // Set color for labels
                            beginAtZero: true,
                            // color: '#ffffff', // Set color for labels
                            maxTicksLimit: 5
                        },
                        grid: {
                            display: true,
                            color: 'rgba(229, 230, 235, 0.15)',
                            zIndex: 9999,
                            drawOnChartArea: true,
                            //dashed grid lines
                            // borderDash: [5, 5], // set the dash style
                            offset: false
                        },
                        border: {
                            // dash: [2,4],
                        },
                        gridLines: {
                            display: true,
                            color: '#ffffff',
                            zIndex: 2, // Set a higher z-index
                        }
                    }
                },
              /*  pointLabels: {
                    display: true, // Display point labels
                    fontColor: 'rgba(255, 22, 93, 1)', // Color of point labels
                    // fontSize: 14, // Font size of point labels
                    // fontStyle: 'bold' // Font style of point labels
                },*/
             /*   elements: {
                    point: {
                        radius: 4, // Set the radius of circular points
                        hoverRadius: 6,
                    },
                    line: {
                        tension: 0 // Disable bezier curves
                    }
                },*/
            },  
        });

        chartRef.current.chartInstance = chartInstance;

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [data, analyticsData, filterType]);

    return (
        <div className='graph-chart-wrapper with-magnifier-icons'>
            <canvas id="largeGraphChart" className='content-characteristic-chart' ref={chartRef} />
            <ul className="magnifiers-list">
                <li><a onClick={() => chartRef.current.chartInstance.zoom(1.1)}><CgZoomIn/></a>                </li>
                <li><a onClick={() => chartRef.current.chartInstance.zoom(0.9)}><CgZoomOut/></a>                </li>
                <li><a onClick={() => chartRef.current.chartInstance.resetZoom()}><TbZoomReset/></a>                </li>
            </ul>
        </div>
    );
}