// import React, { useEffect, useState } from 'react';
// import Chart from 'chart.js/auto'; // Import Chart object

// export default function VideoPerDaySmallChart({analyticsData  }) {

//     const [perDayVideosSeries, serPerDayVideosSeries] = useState([])
//     useEffect(() => {
//         if (!analyticsData) return;
        
//         var likesTimeSeries = analyticsData?.videos_timeseries || [];

//         // If you don't want to aggregate by month and just use the per day data
//         var processedLikesTimeSeries = likesTimeSeries.map((likes) => {
//             // Example: Ensure date is in a specific format if needed
//             var date = new Date(likes['Date Posted']).toLocaleDateString('default', {
//                 year: 'numeric',
//                 month: 'short',
//                 day: 'numeric'
//             });
//             return {
//                 ...likes,
//                 'Date Posted': date
//             };
//         });

//         serPerDayVideosSeries(processedLikesTimeSeries);

//     }, [analyticsData]);

//     useEffect(() => {
//         // Create gradient
//         const canvas = document.getElementById('VideoPerDaySmallChart');
//         const ctx = canvas.getContext('2d');
//         const gradient = ctx.createLinearGradient(0, 0, 0, 100); // Adjust according to your chart size
//         gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
//         gradient.addColorStop(0, 'rgba(49, 43, 83, 1)'); // A djust as needed

//         // Apply gradient as background color
//         const chartInstance = new Chart(ctx, {
//             type: 'line',
//             data: {
//                 labels: perDayVideosSeries.map((share) => share['Date Posted']),
//                 datasets: [
//                     {
//                         label: 'Dataset',
//                         backgroundColor: gradient,
//                         borderColor: '#BA88FF',
//                         borderWidth: 2,
//                         fill: true,
//                         pointBackgroundColor: 'rgba(0, 0, 0, 0)',
//                         pointBorderColor: 'rgba(0, 0, 0, 0)',
//                         pointHoverBackgroundColor: '#fff',
//                         pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
//                         // data: [30,15,40,20,35,30,40]
//                         data: perDayVideosSeries?.map((like) => (like?.Videos?.toFixed(0))),
//                         lineTension: 0.4 // Adjust the tension value for smoothness
//                     }
//                 ]
//             },
//             options: {
//                 plugins: {
//                     legend: {
//                         display: false,
//                     }
//                 },
//                 scales: {
//                     // yAxes: [{
//                     //     ticks: {
//                     //         beginAtZero: true
//                     //     }
//                     // }],
//                     x: {
//                         ticks: {
//                             display: false // Hide the ticks (labels) on the x-axis
//                         },
//                         grid: {
//                             display: false
//                         }
//                     },
//                     y: {
//                         ticks: {
//                             display: false ,// Hide the ticks (labels) on the x-axis
//                             beginAtZero: true
//                         },
//                         grid: {
//                             display: false
//                         }
//                     }
//                 }
//             }
//         });

//         return () => {
//             chartInstance.destroy(); // Cleanup chart instance when component unmounts
//         };
//     }, [analyticsData, perDayVideosSeries]);

//     return (
//         <div className='graph-chart-wrapper'>
//             <canvas id="VideoPerDaySmallChart" height={25} width='100%' />
//         </div>
//     );
// }









import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto'; // Import Chart object

export default function VideoPerDaySmallChart({ analyticsData }) {
    const [perDayVideosSeries, setPerDayVideosSeries] = useState([]);

    useEffect(() => {
        if (!analyticsData) return;

        const likesTimeSeries = analyticsData?.videos_timeseries || [];

        // Calculate the date one month ago
        const mostRecentDate = new Date(Math.max(...likesTimeSeries.map(likes => new Date(likes['Date Posted']))));
        const oneMonthAgo = new Date(mostRecentDate.setMonth(mostRecentDate.getMonth() - 1));

        // Filter likesTimeSeries to include only the last month's data
        const filteredTimeSeries = likesTimeSeries.filter(likes => new Date(likes['Date Posted']) >= oneMonthAgo);

        // Process the filtered data for per day display
        const processedLikesTimeSeries = filteredTimeSeries.map((likes) => {
            const date = new Date(likes['Date Posted']).toLocaleDateString('default', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            });
            return {
                ...likes,
                'Date Posted': date
            };
        });

        setPerDayVideosSeries(processedLikesTimeSeries);
    }, [analyticsData]);

    useEffect(() => {
        // Create gradient
        const canvas = document.getElementById('VideoPerDaySmallChart');
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 100); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, 'rgba(66, 43, 39, 1)'); // Adjust as needed
        // rgba(49, 43, 83, 1)

        // Apply gradient as background color
        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: perDayVideosSeries.map((share) => share['Date Posted']),
                datasets: [
                    {
                        label: 'Dataset',
                        backgroundColor: gradient,
                        borderColor: '#FF7A37',
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
                        data: perDayVideosSeries?.map((like) => (like?.Videos?.toFixed(0))),
                        lineTension: 0.4 // Adjust the tension value for smoothness
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false, // Disable the tooltip
                    },
                },
                scales: {
                    x: {
                        ticks: {
                            display: false // Hide the ticks (labels) on the x-axis
                        },
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        ticks: {
                            display: false, // Hide the ticks (labels) on the y-axis
                            beginAtZero: true
                        },
                        grid: {
                            display: false
                        }
                    }
                }
            }
        });

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [perDayVideosSeries]);

    return (
        <div className='graph-chart-wrapper'>
            <canvas id="VideoPerDaySmallChart" height={25} width='100%' />
        </div>
    );
}
