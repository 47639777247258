import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Slider from "react-slick";
import chartDownIcon from "../assets/images/icons/chart-down-circular-arrow-icon.png";

import LineChart from "../components/LineChart";
import cartUpIcon from "../assets/images/icons/chart-up-circular-arrow-icon.png";
import infoQuestionMark from "../assets/images/icons/info-question-mark-icon.png";
import ShortenNumber from "../utilities/ShortenNumber";
import LineChartBookmarks from "./LineChartBookmarks30d";
import LineChartViews from "./LineChartViews";
import LineChartShares from "./LineChartShares30d";
import LineChartsComments from "./LineChartComments30d";
import LineChartLikes from "./LineChartLikes30d";
import Dropdown from "react-bootstrap/Dropdown";
import LineChartPerDayVideos from "./LineChartLikesPerDayVideos";
import LineChartLikesPerWeekVideos from "./LineChartLikesPerWeekVideos";
import LineChartLikesPerMonthVideos from "./LineChartLikesPerMonthVideos";
import LineChartLikesPerDayVideos from "./LineChartLikesPerDayVideos";
import LineChartVideos from "./LineChartVideos30d";
import DoubleLineChart from "./all-charts/DoubleLineChart";



export default function SliderChartsModal({ sliderModalShow, setSliderModalShow, analyticsData, totalVideosPerDay, totalVideosPerMonth, totalVideosPerWeek,totalVideosLast30Days, averageCommentsPerVideo, averageSharesPerVideo, averageBookmarksPerVideo, averageLikesPerVideo }) {
    const [selectedOption, setSelectedOption] = useState('total_bookmarks')

    const handleChangeOption = (e) => {
        setSelectedOption(e.target.value)
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <Modal
            show={sliderModalShow}
            onHide={() => setSliderModalShow(false)}
            size="lg"
            dialogClassName="line-chart"
            aria-labelledby="contained-modal-title-vcenter"
            centered

        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Slider {...settings}>
                    <div>
                        <div className="select-detail-wrapper">
                            {/* <select className="form-select" aria-label="Default select example"
            style={{ color: '#FF165D' }}  value={selectedOption}
            onChange={(e) => handleChangeOption(e)}>
            <option className='select-option' value="total_views" selected>Total Views</option>
            <option className='select-option' value="total_likes">Total Likes</option>
            <option className='select-option' value="total_shares">Total Shares</option>
            <option className='select-option' value="total_comments">Total Comments</option>
            <option className='select-option' value="total_bookmarks">Total Bookmarks</option>
          </select> */}
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic"
                                                 style={{color: `rgba(231, 173, 90, 1)`}}>{false ? selectedOption : 'TOTAL VIDEOs'}</Dropdown.Toggle>
                              {/*  <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleChangeOption({target: {value: 'total_views'}})}>TOTAL
                                        VIEWS</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_likes' } })}>TOTAL
                                        LIKES</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_shares' } })}>TOTAL
                                        SHARES</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_comments' } })}>TOTAL
                                        COMMENTS</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_bookmarks' } })}>TOTAL
                                        BOOKMARKS</Dropdown.Item>
                                </Dropdown.Menu>*/}
                            </Dropdown>
                            <div className="rating">
                                <h3>{totalVideosLast30Days}</h3>
                                {/* <div className="text down">
                                    <img src={cartUpIcon} alt="cartUpIcon" className='up-arrow' />
                                    <img src={chartDownIcon} alt="cartUpIcon" className='down-arrow' />
                                    <span className="ex-small">93 (8.0%)</span>
                                </div> */}
                            </div>
                            <div className="rank">
                                <div className="dot" style={{ background: '#00F2EA' }}></div>
                                <span className="small">Average</span>
                                <img src={infoQuestionMark} alt="infoQuestionMark" />
                                <div className="info-text">
                                    <span className="white small">Graphs labeled as "Average" signify that your content is performing in line with typical expectations or industry standards.</span>
                                </div>
                            </div>
                        </div>
                        <div className="chart-wrapper">
                            {/* <LineChart data={viewsTimeSeries?.map((views) => (views?.Views?.toFixed(0)))} analyticsData={analyticsData} /> */}
                            {/* <div className='graph-chart-wrapper'>
            <canvas id="viewsChart" />
          </div> */}
                            {true && <LineChartVideos analyticsData={analyticsData}/>}
                            <div className="double-line" style={{margin: '0 0 0 15px'}}>
                                <DoubleLineChart/>
                            </div>
                            {/* {selectedOption == 'total_likes' && <LineChartLikes analyticsData={analyticsData}/>}
                            {selectedOption == 'total_shares' && <LineChartShares analyticsData={analyticsData}/>}
                            {selectedOption == 'total_comments' && <LineChartsComments analyticsData={analyticsData}/>}
                            {selectedOption == 'total_bookmarks' && <LineChartBookmarks analyticsData={analyticsData}/>} */}
                        </div>
                        <div className="content-wrapper">
                            {/* <span>The "Total Views" graph is a vital analytics tool that
            tracks the cumulative views your videos receive over time. It helps
            identify trends, peak engagement periods, and the overall effectiveness
            of your content strategy, enabling you to make informed decisions to
            enhance visibility and impact.</span> */}
                        </div>
                    </div>
                    <div>
                        <div className="select-detail-wrapper">
                            {/* <select className="form-select" aria-label="Default select example" style={{ color: '#FF6400' }} value={selectedOption} onChange={(e) => handleChangeOption(e)}>
                        <option value="total_views" selected>Total Views</option>
                        <option value="total_likes">Total Likes</option>
                        <option value="total_shares">Total Shares</option>
                        <option value="total_comments">Total Comments</option>
                        <option value="total_bookmarks">Total Bookmarks</option>
                    </select> */}
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic"
                                                 style={{color: '#FF7A37'}}>{false ? selectedOption : 'VIDEOS PER DAY'}</Dropdown.Toggle>
                               {/* <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleChangeOption({target: {value: 'total_views'}})}>TOTAL
                                        VIEWS</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_likes' } })}>TOTAL
                                        LIKES</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_shares' } })}>TOTAL
                                        SHARES</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_comments' } })}>TOTAL
                                        COMMENTS</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_bookmarks' } })}>TOTAL
                                        BOOKMARKS</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'videos_per_day' } })}>Videos
                                        Per Day</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'videos_per_week' } })}>Videos
                                        Per Week</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'videos_per_month' } })}>Videos
                                        Per Month</Dropdown.Item>
                                </Dropdown.Menu>*/}
                            </Dropdown>
                            <div className="rating">
                                <h3>{ShortenNumber(totalVideosPerDay || 0)}</h3>
                                {/* <div className="text down">
                                    <img src={cartUpIcon} alt="cartUpIcon" className='up-arrow' />
                                    <img src={chartDownIcon} alt="cartUpIcon" className='down-arrow' />
                                    <span className="ex-small">93 (8.0%)</span>
                                </div> */}
                            </div>
                            <div className="rank">
                                <div className="dot" style={{ background: '#00F2EA' }}></div>
                                <span className="small">Average</span>
                                <img src={infoQuestionMark} alt="infoQuestionMark" />
                                <div className="info-text">
                                    <span className="white small">Graphs labeled as "Average" signify that your content is performing in line with typical expectations or industry standards.</span>
                                </div>
                            </div>
                        </div>
                        <div className="chart-wrapper">
                            {true &&
                                <LineChartPerDayVideos analyticsData={analyticsData}/>}
                            <div className="double-line" style={{margin: '0 0 0 15px'}}>
                                <DoubleLineChart/>
                            </div>
                            {/* {selectedOption == 'total_bookmarks' && <LineChartBookmarks/>}
                            {selectedOption == 'total_views' && <LineChartViews analyticsData={analyticsData}/>}
                            {selectedOption == 'total_shares' && <LineChartShares analyticsData={analyticsData}/>}
                            {selectedOption == 'total_comments' && <LineChartsComments analyticsData={analyticsData}/>}
                            {selectedOption == 'total_likes' && <LineChartLikes analyticsData={analyticsData}/>}
                            {selectedOption == 'videos_per_week' &&
                                <LineChartLikesPerWeekVideos analyticsData={analyticsData}/>}
                            {selectedOption == 'videos_per_month' &&
                                <LineChartLikesPerMonthVideos analyticsData={analyticsData}/>} */}
                        </div>
                        <div className="content-wrapper">
                            {/* <span>The "Total Views" graph is a vital analytics tool that
                        tracks the cumulative views your videos receive over time. It helps
                        identify trends, peak engagement periods, and the overall effectiveness
                        of your content strategy, enabling you to make informed decisions to
                        enhance visibility and impact.</span> */}
                        </div>
                    </div>
                    <div>
                        <div className="select-detail-wrapper">
                            {/* <select className="form-select" aria-label="Default select example" style={{ color: '#FF6400' }} value={selectedOption} onChange={(e) => handleChangeOption(e)}>
                        <option value="total_views" selected>Total Views</option>
                        <option value="total_likes">Total Likes</option>
                        <option value="total_shares">Total Shares</option>
                        <option value="total_comments">Total Comments</option>
                        <option value="total_bookmarks">Total Bookmarks</option>
                    </select> */}
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic"
                                                 style={{color: '#0DABEA'}}>{false ? selectedOption : 'VIDEOS PER WEEK'}</Dropdown.Toggle>
                             {/*   <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'videos_per_week' } })}>Videos
                                        Per Week</Dropdown.Item>

                                    <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_views' } })}>TOTAL
                                        VIEWS</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_likes' } })}>TOTAL
                                        LIKES</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_shares' } })}>TOTAL
                                        SHARES</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_comments' } })}>TOTAL
                                        COMMENTS</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_bookmarks' } })}>TOTAL
                                        BOOKMARKS</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'videos_per_month' } })}>Videos
                                        Per Month</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'videos_per_day' } })}>Videos
                                        Per Day</Dropdown.Item>

                                </Dropdown.Menu>*/}
                            </Dropdown>
                            <div className="rating">
                                <h3>{ShortenNumber(totalVideosPerWeek || 0)}</h3>
                                {/* <div className="text down">
                                    <img src={cartUpIcon} alt="cartUpIcon" className='up-arrow' />
                                    <img src={chartDownIcon} alt="cartUpIcon" className='down-arrow' />
                                    <span className="ex-small">93 (8.0%)</span>
                                </div> */}
                            </div>
                            <div className="rank">
                                <div className="dot" style={{ background: '#00F2EA' }}></div>
                                <span className="small">Average</span>
                                <img src={infoQuestionMark} alt="infoQuestionMark" />
                                <div className="info-text">
                                    <span className="white small">Graphs labeled as "Average" signify that your content is performing in line with typical expectations or industry standards.</span>
                                </div>
                            </div>
                        </div>
                        <div className="chart-wrapper">
                            {true && <LineChartLikesPerWeekVideos analyticsData={analyticsData}/>}
                            <div className="double-line" style={{margin: '0 0 0 15px'}}>
                                <DoubleLineChart/>
                            </div>
                            {/* {selectedOption == 'total_views' && <LineChartViews analyticsData={analyticsData}/>}
                            {selectedOption == 'total_shares' && <LineChartShares analyticsData={analyticsData}/>}
                            {selectedOption == 'total_comments' && <LineChartsComments analyticsData={analyticsData}/>}
                            {selectedOption == 'total_likes' && <LineChartLikes analyticsData={analyticsData}/>}
                            {selectedOption == 'videos_per_week' &&
                                <LineChartLikesPerWeekVideos analyticsData={analyticsData}/>}
                            {selectedOption == 'videos_per_month' &&
                                <LineChartLikesPerMonthVideos analyticsData={analyticsData}/>}
                            {selectedOption == 'videos_per_day' &&
                                <LineChartLikesPerDayVideos analyticsData={analyticsData}/>} */}

                        </div>
                        <div className="content-wrapper">
                            {/* <span>The "Total Views" graph is a vital analytics tool that
                        tracks the cumulative views your videos receive over time. It helps
                        identify trends, peak engagement periods, and the overall effectiveness
                        of your content strategy, enabling you to make informed decisions to
                        enhance visibility and impact.</span> */}
                        </div>
                    </div>
                    <div>
                        <div className="select-detail-wrapper">
                            {/* <select className="form-select" aria-label="Default select example" style={{ color: '#FF6400' }} value={selectedOption} onChange={(e) => handleChangeOption(e)}>
                        <option value="total_views" selected>Total Views</option>
                        <option value="total_likes">Total Likes</option>
                        <option value="total_shares">Total Shares</option>
                        <option value="total_comments">Total Comments</option>
                        <option value="total_bookmarks">Total Bookmarks</option>
                    </select> */}
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic"
                                                 style={{color: '#1EB185'}}>{false ? selectedOption : 'VIDEOS PER MONTH'}</Dropdown.Toggle>
                              {/*  <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'videos_per_month' } })}>Videos
                                        Per Month</Dropdown.Item>

                                    <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_views' } })}>TOTAL
                                        VIEWS</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_likes' } })}>TOTAL
                                        LIKES</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_shares' } })}>TOTAL
                                        SHARES</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_comments' } })}>TOTAL
                                        COMMENTS</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_bookmarks' } })}>TOTAL
                                        BOOKMARKS</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'videos_per_day' } })}>Videos
                                        Per Day</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'videos_per_week' } })}>Videos
                                        Per Week</Dropdown.Item>

                                </Dropdown.Menu>*/}
                            </Dropdown>
                            <div className="rating">
                                <h3>{ShortenNumber(totalVideosPerMonth)}</h3>
                                {/* <div className="text down">
                                    <img src={cartUpIcon} alt="cartUpIcon" className='up-arrow' />
                                    <img src={chartDownIcon} alt="cartUpIcon" className='down-arrow' />
                                    <span className="ex-small">93 (8.0%)</span>
                                </div> */}
                            </div>
                            <div className="rank">
                                <div className="dot" style={{ background: '#00F2EA' }}></div>
                                <span className="small">Average</span>
                                <img src={infoQuestionMark} alt="infoQuestionMark" />
                                <div className="info-text">
                                    <span className="white small">Graphs labeled as "Average" signify that your content is performing in line with typical expectations or industry standards.</span>
                                </div>
                            </div>
                        </div>
                        <div className="chart-wrapper">
                            {true && <LineChartLikesPerMonthVideos analyticsData={analyticsData}/>}
                            <div className="double-line" style={{margin: '0 0 0 15px'}}>
                                <DoubleLineChart/>
                            </div>
                            {/* {selectedOption == 'total_views' && <LineChartViews analyticsData={analyticsData}/>}
                            {selectedOption == 'total_shares' && <LineChartShares analyticsData={analyticsData}/>}
                            {selectedOption == 'total_comments' && <LineChartsComments analyticsData={analyticsData}/>}
                            {selectedOption == 'total_likes' && <LineChartLikes analyticsData={analyticsData}/>}
                            {selectedOption == 'videos_per_week' &&
                                <LineChartLikesPerWeekVideos analyticsData={analyticsData}/>}
                            {selectedOption == 'videos_per_month' &&
                                <LineChartLikesPerMonthVideos analyticsData={analyticsData}/>}
                            {selectedOption == 'videos_per_day' &&
                                <LineChartLikesPerDayVideos analyticsData={analyticsData}/>} */}
                        </div>
                        <div className="content-wrapper">
                            {/* <span>The "Total Views" graph is a vital analytics tool that
                        tracks the cumulative views your videos receive over time. It helps
                        identify trends, peak engagement periods, and the overall effectiveness
                        of your content strategy, enabling you to make informed decisions to
                        enhance visibility and impact.</span> */}
                        </div>
                    </div>
                    <div>
                        <div className="select-detail-wrapper">
                            {/* <select className="form-select" aria-label="Default select example"
                        style={{ color: '#FDBD61' }} value={selectedOption}
                        onChange={(e) => handleChangeOption(e)}>
                        <option value="total_views" >Total Views</option>
                        <option value="total_likes">Total Likes</option>
                        <option value="total_shares" selected>Total Shares</option>
                        <option value="total_comments">Total Comments</option>
                        <option value="total_bookmarks">Total Bookmarks</option>
                    </select> */}
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic"
                                                 style={{color: '#A162F7'}}>{false ? selectedOption : 'TOTAL COMMENTS'}</Dropdown.Toggle>
                              {/*  <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleChangeOption({target: {value: 'total_views'}})}>TOTAL
                                        VIEWS</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_likes' } })}>TOTAL
                                        LIKES</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_shares' } })}>TOTAL
                                        SHARES</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_comments' } })}>TOTAL
                                        COMMENTS</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_bookmarks' } })}>TOTAL
                                        BOOKMARKS</Dropdown.Item>
                                </Dropdown.Menu>*/}
                            </Dropdown>
                            <div className="rating">
                                <h3>{ShortenNumber(averageCommentsPerVideo)}</h3>
                                {/* <div className="text down">
                                    <img src={cartUpIcon} alt="cartUpIcon" className='up-arrow' />
                                    <img src={chartDownIcon} alt="cartUpIcon" className='down-arrow' />
                                    <span className="ex-small">93 (8.0%)</span>
                                </div> */}
                            </div>
                            <div className="rank">
                                <div className="dot" style={{ background: '#00F2EA' }}></div>
                                <span className="small">Average</span>
                                <img src={infoQuestionMark} alt="infoQuestionMark" />
                                <div className="info-text">
                                    <span className="white small">Graphs labeled as "Average" signify that your content is performing in line with typical expectations or industry standards.</span>
                                </div>
                            </div>
                        </div>
                        <div className="chart-wrapper">
                            {/* <LineChart data={sharesTimeSeries?.map((share) => (share?.Shares?.toFixed(0)))} analyticsData={analyticsData}/> */}
                            {/* <div className='graph-chart-wrapper'>
                        <canvas id="sharesChart" />
                    </div> */}
                            {true && <LineChartsComments analyticsData={analyticsData}/>}
                            <div className="double-line" style={{margin: '0 0 0 15px'}}>
                                <DoubleLineChart/>
                            </div>
                            {/* {selectedOption == 'total_likes' && <LineChartLikes analyticsData={analyticsData}/>}
                            {selectedOption == 'total_views' && <LineChartViews analyticsData={analyticsData}/>}
                            {selectedOption == 'total_comments' && <LineChartsComments analyticsData={analyticsData}/>}
                            {selectedOption == 'total_bookmarks' && <LineChartBookmarks analyticsData={analyticsData}/>} */}
                        </div>
                        <div className="content-wrapper">
                            {/* <span>The "Total Views" graph is a vital analytics tool that
                        tracks the cumulative views your videos receive over time. It helps
                        identify trends, peak engagement periods, and the overall effectiveness
                        of your content strategy, enabling you to make informed decisions to
                        enhance visibility and impact.</span> */}
                        </div>
                    </div>
                    <div>
                        <div className="select-detail-wrapper">
                            {/* <select className="form-select" aria-label="Default select example"
            style={{ color: '#BA88FF' }} value={selectedOption}
            onChange={(e) => handleChangeOption(e)}>
            <option value="total_views" >Total Views</option>
            <option value="total_likes" selected>Total Likes</option>
            <option value="total_shares">Total Shares</option>
            <option value="total_comments">Total Comments</option>
            <option value="total_bookmarks">Total Bookmarks</option>
          </select> */}
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic"
                                                 style={{color: '#5FC754'}}>{false ? selectedOption : 'TOTAL SHARES'}</Dropdown.Toggle>
                             {/*   <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleChangeOption({target: {value: 'total_views'}})}>TOTAL
                                        VIEWS</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_likes' } })}>TOTAL
                                        LIKES</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_shares' } })}>TOTAL
                                        SHARES</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_comments' } })}>TOTAL
                                        COMMENTS</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_bookmarks' } })}>TOTAL
                                        BOOKMARKS</Dropdown.Item>
                                </Dropdown.Menu>*/}
                            </Dropdown>
                            <div className="rating">
                                <h3>{ShortenNumber(averageSharesPerVideo)}</h3>
                                {/* <div className="text down">
                                    <img src={cartUpIcon} alt="cartUpIcon" className='up-arrow' />
                                    <img src={chartDownIcon} alt="cartUpIcon" className='down-arrow' />
                                    <span className="ex-small">93 (8.0%)</span>
                                </div> */}
                            </div>
                            <div className="rank">
                                <div className="dot" style={{ background: '#00F2EA' }}></div>
                                <span className="small">Average</span>
                                <img src={infoQuestionMark} alt="infoQuestionMark" />
                                <div className="info-text">
                                    <span className="white small">Graphs labeled as "Average" signify that your content is performing in line with typical expectations or industry standards.</span>
                                </div>
                            </div>
                        </div>
                        <div className="chart-wrapper">
                            {/* <LineChart data={likesTimeSeries?.map((like) => (like?.Likes?.toFixed(0)))} analyticsData={analyticsData}/> */}
                            {/* <div className='graph-chart-wrapper'>
                        <canvas id="sharesChart" />
                    </div> */}
                            {true && <LineChartShares analyticsData={analyticsData}/>}
                            <div className="double-line" style={{margin: '0 0 0 15px'}}>
                                <DoubleLineChart/>
                            </div>
                            {/* {selectedOption == 'total_likes' && <LineChartLikes analyticsData={analyticsData}/>}
                            {selectedOption == 'total_views' && <LineChartViews analyticsData={analyticsData}/>}
                            {selectedOption == 'total_comments' && <LineChartsComments analyticsData={analyticsData}/>}
                            {selectedOption == 'total_shares' && <LineChartShares analyticsData={analyticsData}/>}
                            {selectedOption == 'total_views' && <LineChartViews analyticsData={analyticsData}/>} */}
                        </div>
                        <div className="content-wrapper">
                            {/* <span>The "Total Views" graph is a vital analytics tool that
                        tracks the cumulative views your videos receive over time. It helps
                        identify trends, peak engagement periods, and the overall effectiveness
                        of your content strategy, enabling you to make informed decisions to
                        enhance visibility and impact.</span> */}
                        </div>
                    </div>
                    <div>
                        <div className="select-detail-wrapper">
                            {/* <select className="form-select" aria-label="Default select example" style={{ color: '#FF6400' }} value={selectedOption} onChange={(e) => handleChangeOption(e)}>
                        <option value="total_views" selected>Total Views</option>
                        <option value="total_likes">Total Likes</option>
                        <option value="total_shares">Total Shares</option>
                        <option value="total_comments">Total Comments</option>
                        <option value="total_bookmarks">Total Bookmarks</option>
                    </select> */}
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic"
                                                 style={{color: 'rgba(0, 242, 234, 1)'}}>{false ? selectedOption : 'TOTAL BOOKMARKS'}</Dropdown.Toggle>
                              {/*  <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleChangeOption({target: {value: 'total_views'}})}>TOTAL
                                        VIEWS</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_likes' } })}>TOTAL
                                        LIKES</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_shares' } })}>TOTAL
                                        SHARES</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_comments' } })}>TOTAL
                                        COMMENTS</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_bookmarks' } })}>TOTAL
                                        BOOKMARKS</Dropdown.Item>
                                </Dropdown.Menu>*/}
                            </Dropdown>
                            <div className="rating">
                                <h3>{ShortenNumber(averageBookmarksPerVideo)}</h3>
                                {/* <div className="text down">
                                    <img src={cartUpIcon} alt="cartUpIcon" className='up-arrow' />
                                    <img src={chartDownIcon} alt="cartUpIcon" className='down-arrow' />
                                    <span className="ex-small">93 (8.0%)</span>
                                </div> */}
                            </div>
                            <div className="rank">
                                <div className="dot" style={{ background: '#00F2EA' }}></div>
                                <span className="small">Average</span>
                                <img src={infoQuestionMark} alt="infoQuestionMark" />
                                <div className="info-text">
                                    <span className="white small">Graphs labeled as "Average" signify that your content is performing in line with typical expectations or industry standards.</span>
                                </div>
                            </div>
                        </div>
                        <div className="chart-wrapper">
                            {true && <LineChartBookmarks analyticsData={analyticsData}/>}
                            <div className="double-line" style={{margin: '0 0 0 15px'}}>
                                <DoubleLineChart/>
                            </div>
                            {/* {selectedOption == 'total_views' && <LineChartViews analyticsData={analyticsData}/>}
                            {selectedOption == 'total_shares' && <LineChartShares analyticsData={analyticsData}/>}
                            {selectedOption == 'total_comments' && <LineChartsComments analyticsData={analyticsData}/>}
                            {selectedOption == 'total_likes' && <LineChartLikes analyticsData={analyticsData}/>} */}
                        </div>
                        <div className="content-wrapper">
                            {/* <span>The "Total Views" graph is a vital analytics tool that
                        tracks the cumulative views your videos receive over time. It helps
                        identify trends, peak engagement periods, and the overall effectiveness
                        of your content strategy, enabling you to make informed decisions to
                        enhance visibility and impact.</span> */}
                        </div>
                    </div>
                    <div>
                        <div className="select-detail-wrapper">
                            {/* <select className="form-select" aria-label="Default select example"
                        style={{ color: '#00F2EA' }}  value={selectedOption}
                        onChange={(e) => handleChangeOption(e)}>
                        <option value="total_views" >Total Views</option>
                        <option value="total_likes">Total Likes</option>
                        <option value="total_shares">Total Shares</option>
                        <option value="total_comments" selected>Total Comments</option>
                        <option value="total_bookmarks">Total Bookmarks</option>
                    </select> */}
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic"
                                                 style={{color: 'rgba(255, 22, 93, 1)'}}>{false ? selectedOption : 'TOTAL LIKES'}</Dropdown.Toggle>
                                {/*<Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleChangeOption({target: {value: 'total_views'}})}>TOTAL
                                        VIEWS</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleChangeOption({ target: { value: 'total_likes' } })}>TOTAL
                                        LIKES</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_shares' } })}>TOTAL
                                        SHARES</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_comments' } })}>TOTAL
                                        COMMENTS</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => handleChangeOption({ target: { value: 'total_bookmarks' } })}>TOTAL
                                        BOOKMARKS</Dropdown.Item>
                                </Dropdown.Menu>*/}
                            </Dropdown>
                            <div className="rating">
                                <h3>{ShortenNumber(averageLikesPerVideo)}</h3>
                                {/* <div className="text down">
                                    <img src={cartUpIcon} alt="cartUpIcon" className='up-arrow' />
                                    <img src={chartDownIcon} alt="cartUpIcon" className='down-arrow' />
                                    <span className="ex-small">93 (8.0%)</span>
                                </div> */}
                            </div>
                            <div className="rank">
                                <div className="dot" style={{ background: '#00F2EA' }}></div>
                                <span className="small">Average</span>
                                <img src={infoQuestionMark} alt="infoQuestionMark" />
                                <div className="info-text">
                                    <span className="white small">Graphs labeled as "Average" signify that your content is performing in line with typical expectations or industry standards.</span>
                                </div>
                            </div>
                        </div>
                        <div className="chart-wrapper">
                            {/* <LineChart data={commentsTimeSeries?.map((comment) => (comment?.Comments?.toFixed(0)))} analyticsData={analyticsData}/> */}
                            {/* <div className='graph-chart-wrapper'>
            <canvas id="likesChart" />
          </div> */}
                            {true && <LineChartLikes analyticsData={analyticsData}/>}
                            <div className="double-line" style={{margin: '0 0 0 15px'}}>
                                <DoubleLineChart/>
                            </div>
                            {/* {selectedOption == 'total_bookmarks' && <LineChartBookmarks analyticsData={analyticsData}/>}
                            {selectedOption == 'total_comments' && <LineChartsComments analyticsData={analyticsData}/>}
                            {selectedOption == 'total_shares' && <LineChartShares analyticsData={analyticsData}/>}
                            {selectedOption == 'total_views' && <LineChartViews analyticsData={analyticsData}/>}
                            {selectedOption == 'total_bookmarks' && <LineChartBookmarks analyticsData={analyticsData}/>} */}
                        </div>
                        <div className="content-wrapper">
                            {/* <span>The "Total Views" graph is a vital analytics tool that
            tracks the cumulative views your videos receive over time. It helps
            identify trends, peak engagement periods, and the overall effectiveness
            of your content strategy, enabling you to make informed decisions to
            enhance visibility and impact.</span> */}
                        </div>
                    </div>
                </Slider>
            </Modal.Body>
        </Modal>
    )
}
