import React, { useEffect, useState } from 'react';
import userAvatar from "../assets/images/icons/user-avatar.png";
import userCyanIcon from "../assets/images/icons/user-cyan-icon.png";
import cameraCyanIcon from "../assets/images/icons/camera-cyan-icon.png";
import waveIcon from "../assets/images/icons/wave-icon-cyan.png";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaFolderPlus } from "react-icons/fa";
import { BiSolidDownload } from "react-icons/bi";
import { PiFileArrowDownFill, PiShareFatFill } from "react-icons/pi";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Spinner from 'react-bootstrap/Spinner';

import gemBlueIcon from "../assets/images/icons/gem-blue-img.png";
import cameraYellowIcon from "../assets/images/icons/camera-yelloe-icon.png";
import smsPurpleIcon from "../assets/images/icons/sms-purple-icon.png";
import heartRedIcon from "../assets/images/icons/heart-red-icon.png";
import bookmarkCyanIcon from "../assets/images/icons/bookmark-cyan-icon.png";
import cloudDownloadIcon from "../assets/images/icons/cloud-download-icon.png";
import fileDownloadIcon from "../assets/images/icons/file-download-icon.png";
import verifiedIcon from "../assets/images/icons/verified-icon.svg";
import ShortenNumber from '../utilities/ShortenNumber';
import { useSelector } from 'react-redux';
import axios from 'axios';

export default function ScanUserProfileDashboardInfo({ profileData, handleDownloadZip, handleDownloadAnalytics, analyticsData, reelsComplete, videosData, loadingVideos }) {
    console.log('profile data', profileData)
    const [showDownloadVideModal, setShowDownloadVideModal] = useState(false);
    const [showDownloadDataModal, setShowDownloadDataModal] = useState(false);
    // const videosData = useSelector((state) => state?.reels?.reelsData);
    const [firstVideo, setFirstVideo] = useState(null)

    const handleClose = () => setShowDownloadVideModal(false);
    const handleShow = () => setShowDownloadVideModal(true);
    const handleDownloadDataModal = () => {
        setShowDownloadDataModal(true)
    }
    const hideDownloadDataModal = () => {
        setShowDownloadDataModal(false)
    }


    function validateDate(dateString) {
        const date = new Date(dateString);
        // Check if date is valid
        if (date.toString() === 'Invalid Date' || isNaN(date)) {
            return 'N/A';
        }
        return dateString;
    }

    const userData = () => {
        try {
            let user = localStorage.getItem("user");
            if (user) user = JSON.parse(user);
            return user ?? null;
        } catch (error) {
            return null;
        }
    };

    const user = userData()


    const getFirstVideo = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SCRAPING_SERVER_HOST}/api/last-video-from-json?platform=${profileData?.platform}&username=${profileData?.username}`)
            if (response.status == 200) {
                const firstVideo = response.data
                setFirstVideo(validateDate(new Date(profileData?.platform == 'instagram' ? firstVideo?.['Date Posted'] : firstVideo?.date)?.toLocaleDateString()))
            } else {
                setFirstVideo(null)
            }
        } catch (error) {
            console.log(error)
            setFirstVideo(null)
        }
    }

    useEffect(() => {
        if(profileData?.username){
            getFirstVideo()
        }
    }, [profileData])


    useEffect(() => {
        if (!firstVideo) {
            if (!loadingVideos) {
                setFirstVideo(validateDate(new Date(profileData?.platform == 'instagram' ? videosData[videosData?.length - 1]?.['Date Posted'] : videosData[videosData?.length - 1]?.date)?.toLocaleDateString()))

            }
        }
    }, [loadingVideos])


    return (
        <div className="user-scan-dashboard-info profle-scan-info">
            <div className="row">
                <div className="col-xxl-2 col-xl-6 mb-3 mb-xxl-0  col-lg-6 col-md-6">
                    <div className="user-info-wrapper">
                        <div className="img-wrapper">
                            <img src={profileData?.platform == 'instagram' ? `https://instagramserver.tokbackup.com/proxy?media_url=${encodeURIComponent(
                                profileData?.avatar
                            )}` : profileData?.avatar} alt="userAvatar" />
                        </div>
                        <div className="text-wrapper">
                            <strong className='white'>{profileData?.name} {profileData?.verified == true && <img src={verifiedIcon} alt="verifiedIcon" className='verifiedIcon' />}</strong>
                            <span>@{profileData?.username}</span>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-2 col-xl-6 mb-3 mb-xxl-0  col-lg-6 col-md-6">
                    <div className="ratings-wrapper">
                        <div className="main-wrapper">
                            <div className="name">
                                <img src={userCyanIcon} alt="userCyanIcon" />
                                <span>Followers:</span>
                            </div>
                            <span className="white">{ShortenNumber(profileData?.followerCount)}</span>
                        </div>
                        <div className="main-wrapper">
                            <div className="name">
                                <img src={waveIcon} alt="userCyanIcon" />
                                <span>Most viral video:  </span>
                            </div>
                            <span className="white">{analyticsData?.top_videos?.top_views[0] ? ShortenNumber(analyticsData?.top_videos?.top_views[0]?.Views) : 'N/A'}</span>
                        </div>
                        <div className="main-wrapper">
                            <div className="name">
                                <img src={cameraCyanIcon} alt="userCyanIcon" />
                                <span>First Video:</span>
                            </div>
                            <span className="white">{profileData?.videoCount == 0 ? 'N/A' : firstVideo || <span className="white">  Analyzing...</span>}</span>
                        </div>

                    </div>
                </div>
                <div className="col-xxl-4 col-xl-6 mb-3 mb-xxl-0 col-lg-12  col-md-12">
                    <div className="total-social-info-wrapper">
                        <div className="main-wrapper">
                            <div className="name">
                                <img src={cameraYellowIcon} alt="cameraYellowIcon" />
                                <span>Total videos: </span>
                            </div>
                            <span className="white">{ShortenNumber(profileData?.videoCount)}</span>
                        </div>
                        <div className="main-wrapper">
                            <div className="name">
                                <img src={bookmarkCyanIcon} alt="bookmarkCyanIcon" />
                                <span>Total bookmarks:  </span>
                            </div>
                            <span className="white">{analyticsData?.totals?.total_bookmarks ? ShortenNumber(analyticsData?.totals?.total_bookmarks) : 'N/A' ||
                                <span className="white">  <Spinner animation="grow" size="sm" /></span>}</span>
                        </div>
                        <div className="main-wrapper">
                            <div className="name">
                                <img src={smsPurpleIcon} alt="smsPurpleIcon" />
                                <span>Total comments:  </span>
                            </div>
                            <span className="white">{ShortenNumber(analyticsData?.totals?.total_comments) ||
                                <span className="white">  <Spinner animation="grow" size="sm" /></span>}</span>
                        </div>
                        <div className="main-wrapper">
                            <div className="name">
                                <PiShareFatFill style={{ color: '#5FC754' }} />
                                <span>Total shares:  </span>
                            </div>
                            <span className="white">{analyticsData?.totals?.total_shares ? ShortenNumber(analyticsData?.totals?.total_shares) : 'N/A' ||
                                <span className="white">  <Spinner animation="grow" size="sm" /></span>}</span>
                        </div>
                        <div className="main-wrapper">
                            <div className="name">
                                <img src={heartRedIcon} alt="heartRedIcon" />
                                <span>Total likes:  </span>
                            </div>
                            <span className="white">{ShortenNumber(profileData?.heartCount || analyticsData?.totals?.total_likes || 'N/A')}</span>
                        </div>


                        {/* <div className="main-wrapper">
                            <div className="name">
                                <PiShareFatFill style={{color: '#5FC754'}}/>
                                <span>Total shares:  </span>
                            </div>
                            <span className="white">  <Spinner animation="grow" size="sm" /></span>
                        </div> */}
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-lg-12  col-md-12">
                    <div className="actions-main-wrapper">
                        {/* <div className="action-wrapper" >
                            <div className="icon-wrapper">
                                <FaFolderPlus/>
                            </div>
                            <div className="text">
                                <span className="strong white">Add To Folder</span>
                            </div>
                        </div>*/}
                        <div className="action-wrapper" onClick={() => { handleShow(); }}>
                            <div className="icon-wrapper">
                                <BiSolidDownload />
                            </div>
                            <div className="text">
                                <span className="strong white">Download Videos</span>
                            </div>
                        </div>
                        <div className="action-wrapper" onClick={() => { handleDownloadDataModal() }}>
                            <div className="icon-wrapper">
                                <PiFileArrowDownFill />
                            </div>
                            <div className="text">
                                <span className="strong white">Download Data</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal show={showDownloadVideModal} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className='dashboard-download-modal'>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <strong className="white">Download Videos</strong>
                    <div className="img-wrapper">
                        <img src={cloudDownloadIcon} alt="cloudDownloadIcon" />
                    </div>
                    <div className="proagress-main-wrapper">
                        <div className="title">
                            <span className="white">Video download quota:</span>
                            <span className="white">{user?.subscription?.remaining_videos || 0}/{user?.subscription?.total_videos || 0} Videos</span>
                        </div>
                        <ProgressBar now={Math.ceil(((userData?.subscription?.total_videos - userData?.subscription?.remaining_videos) / userData?.subscription?.total_videos * 100)) || 0} />
                    </div>
                    <div className="text-wrapper">
                        <button className="btn-style simple" onClick={() => { handleDownloadZip(); setShowDownloadVideModal(false) }}>Download Videos</button>
                        <span>Your videos would be deleted from our serves according to your subscribed plan after download.</span>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showDownloadDataModal} onHide={hideDownloadDataModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className='dashboard-download-modal'>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <strong className="white">Download Data</strong>
                    <div className="img-wrapper">
                        <img src={fileDownloadIcon} alt="fileDownloadIcon" />
                    </div>
                    {/* <div className="data-wrapper">
                        <span className="strong white">Data delimiter</span>
                        <label className="checkbox-wrapper">Semicolon ;
                            <input type="checkbox"/>
                            <span className="checkmark"></span>
                        </label>
                        <label className="checkbox-wrapper">Comma ,
                            <input type="checkbox" />
                            <span className="checkmark"></span>
                        </label>
                    </div> */}
                    <div className="proagress-main-wrapper">
                        <div className="title">
                            <span className="white">Quota usage</span>
                            <span className="white">{user?.subscription?.remaining_analytics || 0}/{user?.subscription?.total_analytics || 0} downloads</span>
                        </div>
                        <ProgressBar now={Math.ceil(((user?.subscription?.total_analytics - user?.subscription?.remaining_analytics) / user?.subscription?.total_analytics * 100)) || 0} />
                    </div>
                    <div className="text-wrapper">
                        <button className="btn-style simple" onClick={() => { handleDownloadAnalytics(); setShowDownloadDataModal(false) }}>Download Data</button>
                        <span>Your videos would be deleted from our serves 30 days after download</span>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}