import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js"; // Import Chart.js with auto-registration
import "chartjs-adapter-date-fns"; // Import date-fns adapter if needed
import { MatrixController, MatrixElement } from "chartjs-chart-matrix";

Chart.register(MatrixController, MatrixElement);

export default function MatrixChart({ data }) {
  console.log("🚀 ~ MatrixChart ~ data:", data);

  let last4Entries = data && data?.videos_timeseries_by_week?.slice(-4);
  console.log("🚀 ~ MatrixChart ~ last4Entries:", last4Entries);

  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext("2d");

      // Cleanup function to destroy existing chart instance
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const data = last4Entries
        .map((week, weekIndex) => {
          return week.map((day, dayIndex) => {
            // Define colors based on priority of video counts
            let color = "#D9D9D9"; // Default color for None
            if (day.Videos > 0 && day.Videos <= 2) {
              color = "#65B5DC"; // Lowest
            } else if (day.Videos > 2 && day.Videos <= 5) {
              color = "#858DE2"; // Low
            } else if (day.Videos > 5 && day.Videos <= 8) {
              color = "#B58AE4"; // High
            } else if (day.Videos > 8) {
              color = "#EB55B4"; // Highest
            }

            return {
              x: weekIndex, // x-coordinate for the week
              y: dayIndex, // y-coordinate for the day
              value: day.Videos, // value is the number of videos
              color: color,
            };
          });
        })
        .flat();

      chartInstance.current = new Chart(ctx, {
        type: "matrix",
        data: {
          datasets: [
            {
              label: getLabel(), // Get dynamic label based on priority
              data: data,
              borderWidth: 1,
              borderColor: "rgba(0,0,0,0.5)",
              backgroundColor: data.map((d) => d.color),
              width: 210,
              height: 60,
            },
          ],
        },
        options: {
          scales: {
            x: {
              type: "linear",
              position: "bottom",
              display: true,
              ticks: {
                color: "white",
                // Manually specify the tick values for four weeks
                stepSize: 1,
                min: 0,
                max: 3,
                callback: function (value) {
                  // Convert the week index to week number
                  return `Week ${value + 1}`;
                },
              },
            },
            y: {
              type: "linear",
              position: "left",
              display: true,
              ticks: {
                color: "white",
                callback: function (value) {
                  // Format y-axis labels here
                  switch (value) {
                    case 0:
                      return "Sunday";
                    case 1:
                      return "Saturday";
                    case 2:
                      return "Friday";
                    case 3:
                      return "Thursday";
                    case 4:
                      return "Wednesday";
                    case 5:
                      return "Tuesday";
                    case 6:
                      return "Monday";
                    default:
                      return "";
                  }
                },
              },
            },
          },
          plugins: {
            filler: {
              propagate: false,
            },
          },
          elements: {
            point: {
              radius: 5,
            },
          },
          layout: {
            padding: {
              top: 10,
              right: 10,
              bottom: 10,
              left: 20,
            },
          },
          backgroundColor: "#B58AE4",
        },
      });
    }

    // Cleanup function to destroy chart instance on unmount
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [last4Entries]);

  // Function to get dynamic label based on priority
  const getLabel = () => {
    // Define label text based on priority
    if (last4Entries.some((week) => week.some((day) => day.Videos > 8))) {
      return "Highest Priority";
    } else if (
      last4Entries.some((week) => week.some((day) => day.Videos > 5))
    ) {
      return "High Priority";
    } else if (
      last4Entries.some((week) => week.some((day) => day.Videos > 2))
    ) {
      return "Low Priority";
    } else if (
      last4Entries.some((week) => week.some((day) => day.Videos > 0))
    ) {
      return "Lowest Priority";
    } else {
      return "No Priority";
    }
  };

  return <canvas ref={chartRef} />;
}
