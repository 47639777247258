import React, { useEffect, useRef, useState } from 'react';

import SideBarComponent from "../components/SideBarComponent";
import NewHeaderComponent from "../components/NewHeaderComponent";
import DashboardAverageCards from "../components/DashboardAverageCards30d";
import DashboardTotalSmallCharts from "../components/DashboardTotalSmallCharts";
import HorizontalBarChart2 from "../components/HorizontalBarChart2";
import VideosList from "../components/VideosList";
import { BsFillEyeFill, BsFillHeartFill } from "react-icons/bs";
import { CircularProgressbar } from "react-circular-progressbar";

import { FaFolderPlus } from "react-icons/fa";
import { PiFileArrowDownFill } from "react-icons/pi";
import { BiSolidDownload } from "react-icons/bi";

import userAvatar from '../assets/images/icons/user-avatar.png';
import userCyanIcon from '../assets/images/icons/user-cyan-icon.png';
import cameraCyanIcon from '../assets/images/icons/camera-cyan-icon.png';
import waveIcon from '../assets/images/icons/wave-icon-cyan.png';
import infoIcon from '../assets/images/icons/info-icon.png';
import gemBlueIcon from '../assets/images/icons/gem-blue-img.png';
import rightWhiteArrow from '../assets/images/icons/right-white-arrow.png';
import cameraBlackIcon from '../assets/images/icons/camera-black-icon.png';
import chartsBlackIcon from '../assets/images/icons/charts-black-icon.png';
import Charts from "../components/Charts";
import { useLocation, useNavigate } from 'react-router-dom';
import tikTokIcon from "../assets/images/icons/tick-tock-icon.png";
import yellowCameraIcon from "../assets/images/icons/camera-yelloe-icon.png";
import { IoGrid } from "react-icons/io5";
import { PiShareFatFill } from "react-icons/pi";

import youtubeIcon from "../assets/images/icons/youtube-icon.png";
import instagramReelsIcon from "../assets/images/icons/instagram-reels-icon.png";
import smsPurpleIcon from "../assets/images/icons/sms-purple-icon.png";
import heartRedIcon from "../assets/images/icons/heart-red-icon.png";
import bookmarkCyanIcon from "../assets/images/icons/bookmark-cyan-icon.png";
import sharereen from "../assets/images/icons/share-green-icon.png";
import { useDispatch, useSelector } from 'react-redux';
import ShortenNumber from '../utilities/ShortenNumber';
import GetBasicProfileData from "../utilities/GetBasicProfileData";
import BasicProfileInfoNew from '../components/BasicProfileInfoNew';
import { ANALYTICS_HOST, basePath, scrapServerBasepath } from '../services/constents';
import PostingBehaviousNew from '../components/PostingBehaviousNew';

import HorizontalBarChart2Duration from '../components/HorizontalBarChart2Duration';
import HorizontalBarChart2Hashtags from '../components/HorizontalBarChart2Hashtags';
import ProfileNotFound from '../components/ProfileNotFound';
import { toast } from 'react-toastify';
import ScanUserProfileDashboardInfo from "../components/ScanUserProfileDashboardInfo";
import HeaderComponent from "../components/HeaderComponent";
import StatsChartComponent from "../components/StatsChartComponent";
import videoImg1 from "../assets/images/video-img-1.png";
import tikTokFullLogo from "../assets/images/icons/tikTok-full-logo.png";
import { HiMiniChatBubbleOvalLeftEllipsis } from "react-icons/hi2";
import { RiBookmarkFill } from "react-icons/ri";
import { GoClockFill } from "react-icons/go";
import { FaCalendarAlt } from "react-icons/fa";
import { FaPlay } from "react-icons/fa";
import { MdInsertChart } from "react-icons/md";
import { io } from 'socket.io-client';
import LongVsShortVideos from '../components/LongVsShortVideos';
import { resetReels, setLoadingReels, setReelsData, setReelsFinished } from '../store/reelsSlice';
import DoubleLineChart from "../components/all-charts/DoubleLineChart";
import Spinner from "react-bootstrap/Spinner";
import LoadingScreenComponent from '../components/LoadingScreenComponent';
// import CircularGappedProress from "../components/CircularGappedProgess";

export default function ScanNewProfile({ isEmbedded = true }) {

    // const [isSidebarActive, setSidebarActive] = useState(false);
    // const [isSidebarMobileActive, setSidebarMobileActive] = useState(false);
    // const [videoChartTab, setVideoChartTab] = useState('videos')

    // const sidebarActiveHandler = () => {
    //     setSidebarActive(!isSidebarActive);
    // };
    // const sidebarMobileActiveHandler = () => {
    //     setSidebarMobileActive(true);
    // };
    // const sidebarMobileCloseHandler = () => {
    //     setSidebarMobileActive(false);
    // };


    // const videoChartTabHandler = (tab) => {
    //     setVideoChartTab(tab)
    // }

    // const [isSidebarActive, setSidebarActive] = useState(false);
    // const [isSidebarMobileActive, setSidebarMobileActive] = useState(false);
    const [show, setShow] = useState(false);
    const location = useLocation();
    const navigate = useNavigate()

    const [isSidebarActive, setSidebarActive] = useState(false);
    const [isSidebarMobileActive, setSidebarMobileActive] = useState(false);
    const [shortVideosPercentage, setShortVideosPercentage] = useState(0);
    const [longVideosPercentage, setLongVideosPercentage] = useState(0);
    const options = [
        { value: 'tiktok', label: 'TikTok', icon: tikTokIcon },
        { value: 'youtube', label: 'Youtube', icon: youtubeIcon },
        { value: 'instagram', label: 'Instagram', icon: instagramReelsIcon },
    ];
    const sidebarActiveHandler = () => {
        setSidebarActive(!isSidebarActive);
    };
    const sidebarMobileActiveHandler = () => {
        setSidebarMobileActive(true);
    };
    const sidebarMobileCloseHandler = () => {
        setSidebarMobileActive(false);
    };

    const { user, isLoggedIn } = useSelector((s) => s.auth);

    const [data, setData] = useState([]);
    const [loadingVideos, setLoadingVideos] = useState(false);
    const [disableDownload, setDisableDownload] = useState(false);
    const [showCTA, setShowCTA] = useState(false);
    const [CTAText, setCTAText] = useState('')


    const searchParams = new URLSearchParams(location.search);
    const username = searchParams.get('username');
    const platformName = searchParams.get('platform');

    const userData = JSON.parse(localStorage.getItem("userData"));

    const platforms = ["tiktok", "youtube", "instagram"];
    const [usernameInput, setUsernameInput] = useState(username || "");
    const [email, setEmail] = useState("");

    const [resendAnalyticsCall, setResendAnalyticsCall] = useState(false)

    const [submittingUsernameLoading, setSubmittingUsernameLoading] =
        useState(false);

    const [jsonExists, setJsonExists] = useState(false)

    const [
        scrapingAccountVideosErrorMessage,
        setScrapingAccountVideosErrorMessage,
    ] = useState("");

    const [emailInputError, setEmailInputError] = useState("");

    const { profiledata, platform, profilename, platformOption } = location.state ? location.state : {};

    const [profileData, setProfileData] = useState([]);

    const [selectedPlatform, setSelectedPlatform] = useState(platformName ? platformName : platform ? platform : options[0].value);

    const [selectedOption, setSelectedOption] = useState(platformName ? options.find((option) => option.value === platformName) : platformOption ? platformOption : options[0]);
    const [daysFilter, setDaysFilter] = useState(30)
    const [daysFilter1, setDaysFilter1] = useState(30)

    const [chartTabs, setChartTabs] = useState("performance");


    useEffect(() => {
        if (username) {
            HandleGetBasicProfileData(username, true);
            return;
        }
        if (profiledata) {
            setProfileData(profiledata);
        }
        if (!profiledata && !username && !usernameInput) {
            setAnalyticsDataLoading30d(false)
        }
        // HandleGetBasicProfileData(global.DEV ? "bellapoarch" : "bellapoarch");
    }, []);


    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setSelectedPlatform(selectedOption.value)
    };

    const [licenseKey, setLicenseKey] = useState("");
    const [videosLeftInLicenseKey, setVideosLeftInLicenseKey] = useState({});
    const [selectedVideos, setSelectedVideos] = useState(new Set());

    const errorModalOpenRef = useRef(null);
    const [errorModelMessage, setErrorModelMessage] = useState("");
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        if (!localStorage.getItem("licenseKey")) return;
        setLicenseKey(localStorage.getItem("licenseKey"));
    }, []);

    const [limitsData, setLimitsData] = useState(null)

    const [analyticsData, setAnalyticsData] = useState(null)
    const [analyticsData30d, setAnalyticsData30d] = useState(null)

    const [analyticsDataLoading, setAnalyticsDataLoading] = useState(false)
    const [analyticsDataLoading30d, setAnalyticsDataLoading30d] = useState(true)

    const [loadingIndex, setLoadingIndex] = useState(0)

    var loadingStates = [
        // {
        //     id:1,
        //     heading: "Analyzing Your Profile",
        //     text1:'We’re diving into your selected social media profile.',
        //     text2:'Almost there!',
        //     completion:20
        // },
        {
            id: 2,
            heading: "Gathering Data",
            text1: `We’re collecting all the key details from @${profileData?.username} Tiktok`,
            text2: 'profile. Almost there!',
            completion: 40
        },
        {
            id: 3,
            heading: "Crunching Numbers",
            text1: 'Our algorithms are working hard to process and analyze your data.',
            text2: 'Hang tight!',
            completion: 60
        },
        {
            id: 4,
            heading: "Generating Insights",
            text1: 'Crafting detailed insights and strategies to help you boost your online',
            text2: 'presence. Ready soon!',
            completion: 80
        },
        {
            id: 5,
            heading: "Ready Soon",
            text1: 'Get ready to dive into your custom analytics report. This won’t take',
            text2: 'long! 🚀🔍',
            completion: 100
        }
    ]

    const SubmitUsernameToBeDownloaded = async () => {
        if (!profileData?.username) return;
        setScrapingAccountVideosErrorMessage("");
        setEmailInputError("");
        setSubmittingUsernameNoDownloadLoading(false);
        setSubmittingUsernameLoading(true);

        try {
            const token = localStorage.getItem("app-access-token");

            const response = await fetch(`${basePath}/scrape`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token ? `Bearer ${token}` : "",
                },
                method: "POST",
                body: JSON.stringify({
                    username: profileData?.username,
                    // licenseKey: licenseKey?.trim(),
                    // email: email,
                    id: profileData?.id,
                    platform: selectedPlatform,
                    count: profileData?.videoCount,
                    multiPlatform: true,
                    selectedVideos: Array.from(selectedVideos),
                    appuser_id: userData?.appuser_id,
                    reels: [],
                    score: profileData?.score,
                    profileType: profileData?.profileType,
                    profileAvatar: profiledata?.avatar
                }),
            });

            const data = await response.json();
            console.log('scrap response data ', data)

            if (data.code && data.code !== 200) {
                // setScrapingAccountVideosErrorMessage(data.message);
                setErrorModelMessage(data.message);
                setSuccess(false)
                // setShowCTA(true)
                // setCTAText('Signup!')
            }
            if (!data.error) {
                // toast.success(data.message)
                setErrorModelMessage(data.message)
                setSuccess(true)
            }
            setVideosLeftInLicenseKey(data?.remainingCredentials);
            setSubmittingUsernameLoading(false);

            if (data.error) {
                console.log(data.message);
                if (response.status == 403) {
                    throw new Error(`License key is not valid!`);
                } else {
                    throw new Error(data.message);
                }
            }


            //save licenseKey to session storage
            localStorage.setItem("licenseKey", licenseKey);
        } catch (error) {
            // setScrapingAccountVideosErrorMessage(error.message);
            setErrorModelMessage(error.message);
            setSuccess(false)
            setSubmittingUsernameLoading(false);
        } finally {
            setSubmittingUsernameLoading(false);
        }
    };

    const [
        submittingUsernameNoDownloadLoading,
        setSubmittingUsernameNoDownloadLoading,
    ] = useState(false);
    const [
        submitUsernameNoDownloadErrorMessage,
        setSubmitUsernameNoDownloadErrorMessage,
    ] = useState("");

    const SubmitUsernameToBeScraperWithoutDownload = async () => {
        if (submittingUsernameNoDownloadLoading || !profileData?.username) return;

        setScrapingAccountVideosErrorMessage("");
        setEmailInputError("");
        setSubmittingUsernameNoDownloadLoading(true);
        setSubmittingUsernameLoading(true);
        setErrorModelMessage("")
        setSuccess(false)
        try {
            const token = localStorage.getItem("app-access-token");

            const response = await fetch(
                `${basePath}/scrape-no-download`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token ? `Bearer ${token}` : "",
                    },
                    method: "POST",
                    body: JSON.stringify({
                        username: profileData?.username,
                        licenseKey: licenseKey?.trim(),
                        email: email,
                        id: profileData?.id,
                        platform: selectedPlatform,
                        count: profileData?.videoCount,
                        multiPlatform: true,
                        selectedVideos: Array.from(selectedVideos),
                        appuser_id: userData?.appuser_id,
                        reels: [],
                        score: profileData?.score,
                        profileType: profileData?.profileType,
                        profileAvatar: profiledata?.avatar
                    }),
                }
            );

            const data = await response.json();

            if (data.code && data.code !== 200) {
                // setScrapingAccountVideosErrorMessage(data.message);
                // setSubmitUsernameNoDownloadErrorMessage(data.message);
                setErrorModelMessage(data.message)
            } else {
                // toast.success(data.message)
                setErrorModelMessage(data.message)
                setSuccess(true)

            }
            setVideosLeftInLicenseKey(data?.remainingCredentials);
            setSubmittingUsernameNoDownloadLoading(false);
            setSubmittingUsernameLoading(false);

            if (data.error) {
                console.log(data.message);
                if (response.status == 403) {
                    throw new Error(`License key is not valid!`);
                } else {
                    throw new Error(data.message);
                }
            }

            return data;

            //save licenseKey to session storage
            // localStorage.setItem('licenseKey', licenseKey)
        } catch (error) {
            if (
                error.message == "Failed to fetch" ||
                error.name == "Gateway Time-out"
            ) {
                setSubmitUsernameNoDownloadErrorMessage(
                    "Your download is started, please check your portal to check the status"
                );
                setScrapingAccountVideosErrorMessage(
                    "Your download is started, please check your portal to check the status"
                );
            } else {
                setSubmitUsernameNoDownloadErrorMessage(error.message);
                setScrapingAccountVideosErrorMessage(error.message);
            }
            setSubmittingUsernameNoDownloadLoading(false);
            setSubmittingUsernameLoading(false);
        } finally {
            setSubmittingUsernameNoDownloadLoading(false);
            setSubmittingUsernameLoading(false);
        }
    };

    const [basicUserDataLoading, setBasicUserDataLoading] = useState(false);
    const modalOpenRef = useRef(null);

    const [cleanedUsername, setCleanedUsername] = useState("");
    useEffect(() => {
        if (selectedPlatform === "tiktok") {
            if (isUrl(usernameInput)) {
                const atSymbolPattern = /@([^\/]+)/;
                const match = usernameInput.match(atSymbolPattern);
                if (match && match[1]) {
                    const cleanedUsername = match[1].replace(/[@/]/g, "");
                    setCleanedUsername(cleanedUsername?.toLowerCase());
                }
            } else {
                setCleanedUsername(usernameInput?.toLowerCase());
            }
        } else if (selectedPlatform === "instagram") {
            if (isUrl(usernameInput)) {
                const instagramUrlPattern =
                    /(?:https?:\/\/)?(?:www\.)?instagram\.com\/([^\/]+)/;
                const match = usernameInput.match(instagramUrlPattern);
                if (match && match[1]) {
                    const cleanedUsername = match[1].replace(/\//g, "");
                    setCleanedUsername(cleanedUsername?.toLowerCase());
                }
            } else {
                setCleanedUsername(usernameInput?.toLowerCase());
            }
        } else if (selectedPlatform === "youtube") {
            setCleanedUsername(usernameInput?.toLowerCase());
        }
    }, [usernameInput]);

    const HandleGetBasicProfileData = async (preselectedUsername, dontSaveHistory) => {
        if (basicUserDataLoading || submittingUsernameLoading) return;
        if (!preselectedUsername) {
            var finalUsername = usernameInput;
            if (selectedPlatform === "tiktok") {
                if (isUrl(usernameInput)) {
                    //example tiktok profile link https://www.tiktok.com/@asgkcoaching/
                    const atSymbolPattern = /@([^\/]+)/;
                    const match = usernameInput.match(atSymbolPattern);
                    if (match && match[1]) {
                        const cleanedUsername = match[1].replace(/[@/]/g, "");
                        setCleanedUsername(cleanedUsername?.toLowerCase());
                        finalUsername = cleanedUsername;
                    }
                } else {
                    const cleanedUsername = usernameInput.replace(/@/g, "").toLowerCase();
                    setCleanedUsername(cleanedUsername);
                    finalUsername = cleanedUsername;
                }
            } else if (selectedPlatform === "instagram") {
                if (isUrl(usernameInput)) {
                    //example instagram profile link https://www.instagram.com/therock/
                    const instagramUrlPattern =
                        /(?:https?:\/\/)?(?:www\.)?instagram\.com\/([^\/]+)/;
                    const match = usernameInput.match(instagramUrlPattern);
                    if (match && match[1]) {
                        const cleanedUsername = match[1].replace(/\//g, "");
                        setCleanedUsername(cleanedUsername?.toLowerCase());
                        finalUsername = cleanedUsername;
                    }
                } else {
                    setCleanedUsername(usernameInput?.toLowerCase());
                    finalUsername = usernameInput;
                }
            } else if (selectedPlatform === "youtube") {
                if (isUrl(usernameInput)) {
                    // Example YouTube profile link https://www.youtube.com/@justinflom
                    const youtubeUrlPattern = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/@([^/]+)/;
                    const match = usernameInput.match(youtubeUrlPattern);
                    if (match && match[1]) {
                        const cleanedUsername = match[1].toLowerCase();
                        setCleanedUsername(cleanedUsername);
                        finalUsername = cleanedUsername;
                    }
                } else {
                    // Handle non-URL input or direct channel name entry
                    const cleanedUsername = usernameInput.replace(/@/g, "").toLowerCase();
                    setCleanedUsername(cleanedUsername);
                    finalUsername = cleanedUsername;
                }
            }


            if (
                cleanedUsername == profileData?.username &&
                profileData?.platform == selectedPlatform
            ) {
                // modalOpenRef?.current.click();
                return;
            }

            if (selectedPlatform == "tiktok") {
                finalUsername = finalUsername?.toLowerCase();
            }
            const data = await GetBasicProfileData(
                finalUsername?.trim(),
                selectedPlatform,
                setBasicUserDataLoading,
                dontSaveHistory
            );

            console.log('data', data)

            if (data?.belladata || data?.error || !data?.id) {
                setErrorModelMessage(data?.message);
                setProfileData(data?.belladata);
                setShowVideosList(false)
                setShowCTA((data?.message == 'No More Scans Allowed. Click Here to Sign Up ' || data?.message == 'Please subscribe to a package for access to premium features like downloading videos and data. Click Here to Subscribe to a Package. ' || data?.message == `It looks like you’ve gone over the limit allowed for your current tier. Managing our data resources effectively is key to delivering a top-notch experience, and we're here to help you make the most of it.
              To ensure you have continued access to comprehensive data scraping and backup, we invite you to create a subscription for our premium package.` || data?.message == `You've hit the scan limit allowed in your current plan. To keep scanning and accessing data, upgrade your current plan or move to a higher package for more features.
              Click Here to Upgrade Your Package. ` || data?.message == `This platform is restricted in your current plan. To keep scanning and accessing data, upgrade your plan.
              Click Here to Upgrade Your Plan. `) && true)
                setCTAText(data?.message == `Oops! Username is incorrect please add the username without "@" or any other invalid character or just copy / paste the link to the profile!` ? 'Invalid Username' : data?.message == `Oops! It looks like profile you're looking for cound not be found. Double-check username and try again.` ? "Profile Not found" : data?.message == `You've hit the scan limit allowed in your current plan. To keep scanning and accessing data, upgrade your current plan or move to a higher package for more features.
          Click Here to Upgrade Your Package. ` ? 'Upgrade!' : data?.message == 'No More Scans Allowed. Click Here to Sign Up ' ? 'Signip!' : data?.message == `It looks like you’ve gone over the limit allowed for your current tier. Managing our data resources effectively is key to delivering a top-notch experience, and we're here to help you make the most of it.
          To ensure you have continued access to comprehensive data scraping and backup, we invite you to create a subscription for our premium package.` ? 'Subscribe!' : data?.code == 406 ? 'Not Applicable' : '')
                // errorModalOpenRef.current.click();
            } else {
                setProfileData(data);
                setShowVideosList(true);
                if (!username && !platformName) {
                    navigate(location.pathname, { state: { profiledata: data, platform: selectedPlatform, profilename: data?.username, platformOption: selectedOption } });

                }
            }
        } else {
            const data = await GetBasicProfileData(
                preselectedUsername?.trim(),
                platformName,
                setBasicUserDataLoading,
                dontSaveHistory
            );
            if (data?.belladata && data.data.code !== 200) {
                setErrorModelMessage(data.data.message);
                setProfileData(data.belladata);
                // errorModalOpenRef.current.click();
            } else {
                setProfileData(data);
                if (!username && !platformName) {
                    navigate(location.pathname, { state: { profiledata: data, platform: selectedPlatform, profilename: data?.username, platformOption: selectedOption } });
                }
            }
        }
    };

    const joinModalOpenRef = useRef(null);
    const loginModalOpenRef = useRef(null);
    const paymentModalOpenRef = useRef(null);

    const [usernameSubmitted, setUsernameSubmitted] = useState(false);
    const isUrl = (input) => {
        const urlPattern = /^(https?:\/\/www\.|www\.)/;
        if (urlPattern.test(input)) {
            return true;
        }
        try {
            new URL(input);
            return true;
        } catch (_) {
            return false;
        }
    };

    // const [excelExists, setExcelExists] = useState(false);

    const [showVideosList, setShowVideosList] = useState(false);
    // const [excelData, setExcelData] = useState(null);

    const [onlyAnalytics, setOnlyAnalytics] = useState(false);

    const [
        submittingUsernameNoDownloadResult,
        setSubmittingUsernameNoDownloadResult,
    ] = useState(null);

    const SubmitUsernameToBeScraperWithoutDownloadHandler = async () => {
        // alert('no download calling')
        try {
            const result = await SubmitUsernameToBeScraperWithoutDownload();
            console.log('no download result', result);
            setSubmittingUsernameNoDownloadResult(result);
        } catch (error) {
            console.log(error);
        }
    };


    const CheckIfExcelFileExists = async () => {
        try {
            const token = localStorage.getItem("app-access-token");
            const response = await fetch(`${basePath}/checkjson`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token ? `Bearer ${token}` : "",
                },
                method: "POST",
                body: JSON.stringify({
                    username: profileData?.username,
                    platform: selectedPlatform,
                }),
            });
            const data = await response.json();
            setJsonExists(
                !data.error && data.jsonData.length === profileData.videoCount
            );
            // if (data.lastModifiedDate)
            //   setExcelLastModifiedDate(data.lastModifiedDate);
        } catch (error) {
            console.error(error.message);
        }
    };

    const HandleSearch = () => {
        // if(profileData?.platform === 'instagram'){
        //   if(!reelsComplete || reelsComplete == false){
        //     setScrapingAccountVideosErrorMessage('We are analizing your account. Please wait until your all data is loaded.')
        //     modalOpenRef?.current.click();
        //     return
        //   }
        // }
        setUsernameSubmitted(false);
        setOnlyAnalytics(false);
        setScrapingAccountVideosErrorMessage(null);
        setErrorModelMessage('')
        setCTAText('')
        setVideoChartTab('dashboard')
        // if (!isLoggedIn) {
        //   loginModalOpenRef.current.click();
        //   return;
        // }
        if (
            profileData?.usernameInput == cleanedUsername &&
            profileData?.platform == selectedPlatform
        ) {
            if (false) {
                // loginModalOpenRef.current.click();
            } else {
                if (selectedVideos?.size == 0 && profileData.platform !== 'instagram' && limitsData) {
                    if (profileData.videoCount >= limitsData.limit) {
                        setScrapingAccountVideosErrorMessage(
                            "Your account is too large. Please download selected videos or try another account."
                        );
                        setDisableDownload(true);
                        // modalOpenRef?.current.click();
                        return;
                    }
                }
                if (profileData?.videoCount == 0) {
                    setScrapingAccountVideosErrorMessage(
                        "This account has no videos"
                    );
                    setDisableDownload(true);

                    console.log(
                        "%c This account has no videos",
                        "color: #ff0000"
                    );
                    // modalOpenRef?.current.click();

                    return;
                } else {
                    setDisableDownload(false);
                }
                if (!isEmbedded) {
                    setUsernameSubmitted(true);

                    // CheckIfExcelFileExists();

                    // SubmitUsernameToBeDownloaded();

                    // modalOpenRef?.current.click();
                }
            }
        } else {
            if (usernameInput) {
                HandleGetBasicProfileData();
            }
        }
    }


    const GetAnalyticsData = async () => {
        try {
            setAnalyticsDataLoading(true)
            const response = await fetch(`${ANALYTICS_HOST}/api/profile/${profileData?.username}?platform=${selectedPlatform}`)
            const data = await response.json()
            setAnalyticsData(data)
            if (!data.error) {
                setAnalyticsDataLoading(false)
            }
        } catch (e) {
            console.log('GetAnalyticsData : ' + e)
            setAnalyticsData({ error: true })
        }

    }
    const GetAnalyticsData30d = async () => {
        try {
            setAnalyticsDataLoading30d(true)
            const response = await fetch(`${ANALYTICS_HOST}/api/profile30d/${profileData?.username}?platform=${selectedPlatform}`)
            const data = await response.json()
            setAnalyticsData30d(data)
            if (!data.error) {
                setAnalyticsDataLoading30d(false)
            }
        } catch (e) {
            console.log('GetAnalyticsData : ' + e)
            setAnalyticsData30d({ error: true })
        }

    }
    useEffect(() => {
        if (!profileData?.username) {
            console.log('no username')
            return
        }
        GetAnalyticsData()
        GetAnalyticsData30d()

    }, [profileData?.username])

    const handleDownloadZip = () => {
        if (username && platformName) {
            window.location.href = `https://download.tokbackup.com/${platformName}/${username}/${username}.zip?time=${Date.now()}`
            return;
        }
        // alert('hallo')
        setUsernameSubmitted(false);
        setOnlyAnalytics(false);
        setScrapingAccountVideosErrorMessage(null);
        setErrorModelMessage("")
        // if (!isLoggedIn) {
        //   loginModalOpenRef.current.click();
        //   return;
        // }
        if (false) {
            // loginModalOpenRef.current.click();
        } else {
            if (selectedVideos?.size == 0 && profileData.platform !== 'instagram' && limitsData) {
                if (profileData.videoCount >= limitsData.limit) {
                    setErrorModelMessage(
                        "Your account is too large. Please download selected videos or try another account."
                    );
                    setDisableDownload(true);
                    // modalOpenRef?.current.click();
                    return;
                }
            }
            if (profileData?.videoCount == 0) {
                setErrorModelMessage(
                    "This account has no videos"
                );
                setDisableDownload(true);

                console.log(
                    "%c This account has no videos",
                    "color: #ff0000"
                );
                // modalOpenRef?.current.click();

                return;
            } else {

                setDisableDownload(false);
            }

            if (isEmbedded) {

                setUsernameSubmitted(true);

                // CheckIfExcelFileExists();

                SubmitUsernameToBeDownloaded();

                // modalOpenRef?.current.click();
            }
        }


    }

    const handleDownloadAnalytics = () => {
        if (username && platformName) {
            window.location.href = `https://download.tokbackup.com/${platformName}/${username}/${username}.xlsx?time=${Date.now()}`
            return;
        }
        setUsernameSubmitted(false);
        // setScrapingAccountVideosErrorMessage(null);
        setErrorModelMessage("");

        if (profileData?.videoCount == 0) {
            setErrorModelMessage(
                "This account has no videos"
            );
            setSuccess(false)
            // toast.error(
            //   "This account has no videos",
            //   "color: #ff0000"
            // );

            // errorModalOpenRef.current.click();
            return;
        }
        // if (isLoggedIn !== true) {
        //     // loginModalOpenRef.current.click();
        // }

        if (isEmbedded) {
            setOnlyAnalytics(true);
            setUsernameSubmitted(true);

            // CheckIfExcelFileExists();

            SubmitUsernameToBeScraperWithoutDownloadHandler();
            // toast.success('Downlaad Started!')
            setErrorModelMessage('Downlaod has started!')
            setSuccess(true)
            setCTAText('Download Start')
            // modalOpenRef?.current.click();
        }
    }
    const [videoChartTab, setVideoChartTab] = useState('dashboard')

    const videoChartTabHandler = (tab) => {
        setVideoChartTab(tab)
    }

    const [totalStatsChartType, setTotalStatsChartType] = useState('views')
    const [totalStatsChartData, setTotalStatsChartData] = useState([])
    const [totalStatsChartYears, setTotalStatsChartYears] = useState([])



    useEffect(() => {
        const combineTimeseriesData = (data, key) => {
            return data.reduce((acc, dataPoint) => {
                const year = new Date(dataPoint['Date Posted']).getFullYear();
                if (!acc[year]) {
                    acc[year] = { [key]: 0 };
                }
                acc[year][key] += dataPoint[key.charAt(0).toUpperCase() + key.slice(1)];
                return acc;
            }, {});
        };

        switch (totalStatsChartType) {
            case 'views':
                const videosSeries = daysFilter1 ? analyticsData30d?.views_timeseries : analyticsData?.views_timeseries || [];
                const yearlyData = {};
                const dailyData = {};

                // Get today's date and calculate the past date based on daysFilter
                const today = new Date();
                const pastDate = new Date();
                pastDate.setDate(today.getDate() - daysFilter1);

                videosSeries?.forEach(dataPoint => {
                    const datePosted = new Date(dataPoint['Date Posted']);

                    // If daysFilter is set, calculate data per day within the last daysFilter days
                    if (daysFilter1 && datePosted >= pastDate && datePosted <= today) {
                        const day = datePosted.toISOString().split('T')[0]; // Format date as YYYY-MM-DD

                        if (!dailyData[day]) {
                            dailyData[day] = {
                                views: 0
                            };
                        }

                        dailyData[day].views += dataPoint.Views;
                    } else if (!daysFilter1) {
                        // If daysFilter is not set, calculate data per year
                        const year = datePosted.getFullYear();

                        if (!yearlyData[year]) {
                            yearlyData[year] = {
                                views: 0
                            };
                        }

                        yearlyData[year].views += dataPoint.Views;
                    }
                });

                let dataArray = [];

                if (daysFilter1) {
                    // Convert dailyData object to an array of objects
                    dataArray = Object.entries(dailyData).map(([day, data]) => ({
                        day: new Date(day).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }), // Formats to "DD MMM"
                        views: data.views
                    }));


                    // Sort the array by day
                    dataArray.sort((a, b) => new Date(a.day) - new Date(b.day));

                    // Extract days and views into separate arrays
                    const days = dataArray.map(data => data.day);
                    const views = dataArray.map(data => data.views);

                    setTotalStatsChartData(views);
                    setTotalStatsChartYears(days); // Rename this if necessary, e.g., setTotalStatsChartDays
                } else {
                    // Convert yearlyData object to an array of objects
                    dataArray = Object.entries(yearlyData).map(([year, data]) => ({
                        year: parseInt(year),
                        views: data.views
                    }));

                    // Sort the array by year
                    dataArray.sort((a, b) => a.year - b.year);

                    // Extract years and views into separate arrays
                    const years = dataArray.map(data => data.year);
                    const views = dataArray.map(data => data.views);

                    setTotalStatsChartData(views);
                    setTotalStatsChartYears(years);
                }

                break;

            case 'likes':
                const likesSeries = daysFilter1 ? analyticsData30d?.likes_timeseries : analyticsData?.likes_timeseries || [];
                const yearlyDataLikes = {};
                const dailyDataLikes = {};

                // Get today's date and calculate the past date based on daysFilter
                const todayLikes = new Date();
                const pastDateLikes = new Date();
                pastDateLikes.setDate(todayLikes.getDate() - daysFilter1);

                likesSeries.forEach(dataPoint => {
                    const datePosted = new Date(dataPoint['Date Posted']);

                    // If daysFilter is set, calculate data per day within the last daysFilter days
                    if (daysFilter1 && datePosted >= pastDateLikes && datePosted <= todayLikes) {
                        const day = datePosted.toISOString().split('T')[0]; // Format date as YYYY-MM-DD

                        if (!dailyDataLikes[day]) {
                            dailyDataLikes[day] = {
                                likes: 0
                            };
                        }

                        dailyDataLikes[day].likes += dataPoint.Likes;
                    } else if (!daysFilter1) {
                        // If daysFilter is not set, calculate data per year
                        const year = datePosted.getFullYear();

                        if (!yearlyDataLikes[year]) {
                            yearlyDataLikes[year] = {
                                likes: 0
                            };
                        }

                        yearlyDataLikes[year].likes += dataPoint.Likes;
                    }
                });

                let likesDataArray = [];

                if (daysFilter1) {
                    // Convert dailyDataLikes object to an array of objects
                    likesDataArray = Object.entries(dailyDataLikes).map(([day, data]) => ({
                        day: new Date(day).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }), // Formats to "DD MMM"
                        likes: data.likes
                    }));

                    // Sort the array by day
                    likesDataArray.sort((a, b) => new Date(a.day) - new Date(b.day));

                    // Extract days and likes into separate arrays
                    const daysLikes = likesDataArray.map(data => data.day);
                    const likes = likesDataArray.map(data => data.likes);

                    setTotalStatsChartData(likes);
                    setTotalStatsChartYears(daysLikes); // Rename this if necessary, e.g., setTotalStatsChartDays
                } else {
                    // Convert yearlyDataLikes object to an array of objects
                    likesDataArray = Object.entries(yearlyDataLikes).map(([year, data]) => ({
                        year: parseInt(year),
                        likes: data.likes
                    }));

                    // Sort the array by year
                    likesDataArray.sort((a, b) => a.year - b.year);

                    // Extract years and likes into separate arrays
                    const yearsLikes = likesDataArray.map(data => data.year);
                    const likes = likesDataArray.map(data => data.likes);

                    setTotalStatsChartData(likes);
                    setTotalStatsChartYears(yearsLikes);
                }

                break;

            case 'comments':
                const commentsSeries = daysFilter1 ? analyticsData30d?.comments_timeseries : analyticsData?.comments_timeseries || [];
                const yearlyDataComments = {};
                const dailyDataComments = {};

                // Get today's date and calculate the past date based on daysFilter
                const todayComments = new Date();
                const pastDateComments = new Date();
                pastDateComments.setDate(todayComments.getDate() - daysFilter1);

                commentsSeries.forEach(dataPoint => {
                    const datePosted = new Date(dataPoint['Date Posted']);

                    // If daysFilter is set, calculate data per day within the last daysFilter days
                    if (daysFilter1 && datePosted >= pastDateComments && datePosted <= todayComments) {
                        const day = datePosted.toISOString().split('T')[0]; // Format date as YYYY-MM-DD

                        if (!dailyDataComments[day]) {
                            dailyDataComments[day] = {
                                comments: 0
                            };
                        }

                        dailyDataComments[day].comments += dataPoint.Comments;
                    } else if (!daysFilter1) {
                        // If daysFilter is not set, calculate data per year
                        const year = datePosted.getFullYear();

                        if (!yearlyDataComments[year]) {
                            yearlyDataComments[year] = {
                                comments: 0
                            };
                        }

                        yearlyDataComments[year].comments += dataPoint.Comments;
                    }
                });

                let commentsDataArray = [];

                if (daysFilter1) {
                    // Convert dailyDataComments object to an array of objects
                    commentsDataArray = Object.entries(dailyDataComments).map(([day, data]) => ({
                        day: new Date(day).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }), // Formats to "DD MMM"
                        comments: data.comments
                    }));

                    // Sort the array by day
                    commentsDataArray.sort((a, b) => new Date(a.day) - new Date(b.day));

                    // Extract days and comments into separate arrays
                    const daysComments = commentsDataArray.map(data => data.day);
                    const comments = commentsDataArray.map(data => data.comments);

                    setTotalStatsChartData(comments);
                    setTotalStatsChartYears(daysComments); // Rename this if necessary, e.g., setTotalStatsChartDays
                } else {
                    // Convert yearlyDataComments object to an array of objects
                    commentsDataArray = Object.entries(yearlyDataComments).map(([year, data]) => ({
                        year: parseInt(year),
                        comments: data.comments
                    }));

                    // Sort the array by year
                    commentsDataArray.sort((a, b) => a.year - b.year);

                    // Extract years and comments into separate arrays
                    const yearsComments = commentsDataArray.map(data => data.year);
                    const comments = commentsDataArray.map(data => data.comments);

                    setTotalStatsChartData(comments);
                    setTotalStatsChartYears(yearsComments);
                }

                break;

            case 'shares':
                const sharesSeries = daysFilter1 ? analyticsData30d?.shares_timeseries : analyticsData?.shares_timeseries || [];
                const yearlyDataShares = {};
                const dailyDataShares = {};

                // Get today's date and calculate the past date based on daysFilter
                const todayShares = new Date();
                const pastDateShares = new Date();
                pastDateShares.setDate(todayShares.getDate() - daysFilter1);

                sharesSeries.forEach(dataPoint => {
                    const datePosted = new Date(dataPoint['Date Posted']);

                    // If daysFilter is set, calculate data per day within the last daysFilter days
                    if (daysFilter1 && datePosted >= pastDateShares && datePosted <= todayShares) {
                        const day = datePosted.toISOString().split('T')[0]; // Format date as YYYY-MM-DD

                        if (!dailyDataShares[day]) {
                            dailyDataShares[day] = {
                                shares: 0
                            };
                        }

                        dailyDataShares[day].shares += dataPoint.Shares;
                    } else if (!daysFilter1) {
                        // If daysFilter is not set, calculate data per year
                        const year = datePosted.getFullYear();

                        if (!yearlyDataShares[year]) {
                            yearlyDataShares[year] = {
                                shares: 0
                            };
                        }

                        yearlyDataShares[year].shares += dataPoint.Shares;
                    }
                });

                let sharesDataArray = [];

                if (daysFilter1) {
                    // Convert dailyDataShares object to an array of objects
                    sharesDataArray = Object.entries(dailyDataShares).map(([day, data]) => ({
                        day: new Date(day).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }), // Formats to "DD MMM"
                        shares: data.shares
                    }));

                    // Sort the array by day
                    sharesDataArray.sort((a, b) => new Date(a.day) - new Date(b.day));

                    // Extract days and shares into separate arrays
                    const daysShares = sharesDataArray.map(data => data.day);
                    const shares = sharesDataArray.map(data => data.shares);

                    setTotalStatsChartData(shares);
                    setTotalStatsChartYears(daysShares); // Rename this if necessary, e.g., setTotalStatsChartDays
                } else {
                    // Convert yearlyDataShares object to an array of objects
                    sharesDataArray = Object.entries(yearlyDataShares).map(([year, data]) => ({
                        year: parseInt(year),
                        shares: data.shares
                    }));

                    // Sort the array by year
                    sharesDataArray.sort((a, b) => a.year - b.year);

                    // Extract years and shares into separate arrays
                    const yearsShares = sharesDataArray.map(data => data.year);
                    const shares = sharesDataArray.map(data => data.shares);

                    setTotalStatsChartData(shares);
                    setTotalStatsChartYears(yearsShares);
                }

                break;


            case 'bookmarks':
                const bookmarksSeries = daysFilter1 ? analyticsData30d?.bookmarks_timeseries : analyticsData?.bookmarks_timeseries || [];
                const yearlyDataBookmarks = {};
                const dailyDataBookmarks = {};

                // Get today's date and calculate the past date based on daysFilter
                const todayBookmarks = new Date();
                const pastDateBookmarks = new Date();
                pastDateBookmarks.setDate(todayBookmarks.getDate() - daysFilter1);

                bookmarksSeries.forEach(dataPoint => {
                    const datePosted = new Date(dataPoint['Date Posted']);

                    // If daysFilter is set, calculate data per day within the last daysFilter days
                    if (daysFilter1 && datePosted >= pastDateBookmarks && datePosted <= todayBookmarks) {
                        const day = datePosted.toISOString().split('T')[0]; // Format date as YYYY-MM-DD

                        if (!dailyDataBookmarks[day]) {
                            dailyDataBookmarks[day] = {
                                bookmarks: 0
                            };
                        }

                        dailyDataBookmarks[day].bookmarks += dataPoint.Bookmarks;
                    } else if (!daysFilter1) {
                        // If daysFilter is not set, calculate data per year
                        const year = datePosted.getFullYear();

                        if (!yearlyDataBookmarks[year]) {
                            yearlyDataBookmarks[year] = {
                                bookmarks: 0
                            };
                        }

                        yearlyDataBookmarks[year].bookmarks += dataPoint.Bookmarks;
                    }
                });

                let bookmarksDataArray = [];

                if (daysFilter1) {
                    // Convert dailyDataBookmarks object to an array of objects
                    bookmarksDataArray = Object.entries(dailyDataBookmarks).map(([day, data]) => ({
                        day: new Date(day).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }), // Formats to "DD MMM"
                        bookmarks: data.bookmarks
                    }));

                    // Sort the array by day
                    bookmarksDataArray.sort((a, b) => new Date(a.day) - new Date(b.day));

                    // Extract days and bookmarks into separate arrays
                    const daysBookmarks = bookmarksDataArray.map(data => data.day);
                    const bookmarks = bookmarksDataArray.map(data => data.bookmarks);

                    setTotalStatsChartData(bookmarks);
                    setTotalStatsChartYears(daysBookmarks); // Rename this if necessary, e.g., setTotalStatsChartDays
                } else {
                    // Convert yearlyDataBookmarks object to an array of objects
                    bookmarksDataArray = Object.entries(yearlyDataBookmarks).map(([year, data]) => ({
                        year: parseInt(year),
                        bookmarks: data.bookmarks
                    }));

                    // Sort the array by year
                    bookmarksDataArray.sort((a, b) => a.year - b.year);

                    // Extract years and bookmarks into separate arrays
                    const yearsBookmarks = bookmarksDataArray.map(data => data.year);
                    const bookmarks = bookmarksDataArray.map(data => data.bookmarks);

                    setTotalStatsChartData(bookmarks);
                    setTotalStatsChartYears(yearsBookmarks);
                }

                break;

            case 'all':
                const viewsData = combineTimeseriesData(analyticsData?.views_timeseries || [], 'views');
                const likesData = combineTimeseriesData(analyticsData?.likes_timeseries || [], 'likes');
                const commentsData = combineTimeseriesData(analyticsData?.comments_timeseries || [], 'comments');
                const sharesData = combineTimeseriesData(analyticsData?.shares_timeseries || [], 'shares');
                const bookmarksData = combineTimeseriesData(analyticsData?.bookmarks_timeseries || [], 'bookmarks');

                const combinedYearlyData = {};

                const mergeData = (data, key) => {
                    Object.entries(data).forEach(([year, value]) => {
                        if (!combinedYearlyData[year]) {
                            combinedYearlyData[year] = { views: 0, likes: 0, comments: 0, shares: 0, bookmarks: 0 };
                        }
                        combinedYearlyData[year][key] += value[key];
                    });
                };

                mergeData(viewsData, 'views');
                mergeData(likesData, 'likes');
                mergeData(commentsData, 'comments');
                mergeData(sharesData, 'shares');
                mergeData(bookmarksData, 'bookmarks');

                const yearlyAllDataArray = Object.entries(combinedYearlyData).map(([year, data]) => ({
                    year: parseInt(year),
                    total: data.views + data.likes + data.comments + data.shares + data.bookmarks
                })).sort((a, b) => a.year - b.year);

                const allYears = yearlyAllDataArray.map(data => data.year);
                const allDataArray = yearlyAllDataArray.map(data => data.total);

                setTotalStatsChartData(allDataArray);
                setTotalStatsChartYears(allYears);
                break;


        }
    }, [analyticsData, totalStatsChartType, daysFilter1, analyticsData30d])

    // const [shortVideos, setShortvideos] = useState([])
    // const [longVideos, setLongVideos] = useState([])
    // const [shortPercentage, setShortPercentage] = useState(0)
    // const [shortViews, setShortViews] = useState(0)
    // const [longViews, setLongViews] = useState(0)

    const reelsComplete = useSelector((state) => state?.reels?.reelsComplete);
    const videosData = useSelector((state) => state?.reels?.reelsData);

    // useEffect(() => {
    //     const totalVideos = videosData || [];
    //     const shortVideos = []
    //     const longVideos = []
    //     var shortViews = 0
    //     var longViews = 0

    //     totalVideos?.forEach((item) => {
    //         let duration = profiledata?.platform == 'instagram' ? item?.Duration : item.duration
    //         if(duration > 30){
    //             longVideos.push(item)
    //             longViews += profiledata?.platform == 'instagram' ? item?.Views : item.views
    //         }else{
    //             shortVideos.push(item)
    //             shortViews += profiledata?.platform == 'instagram' ? item?.Views : item.views
    //         }
    //     })

    //     setShortvideos(shortVideos)
    //     setLongVideos(longVideos)
    //     setShortViews(shortViews)
    //     setLongViews(longViews)
    //     const shortVideosPercentage = (shortVideos.length / totalVideos.length) * 100

    //     const longVideosPercentage = (longVideos.length / totalVideos.length) * 100
    //     setShortPercentage(Math.ceil(shortVideosPercentage || 0))


    // }, [data])
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetReels())
        setLoadingVideos(true);
        setData([]);
        setSelectedVideos(new Set());
    }, [profileData?.id, profileData?.username]);

    var eventSource;
    var socket;
    var got30dData = false
    console.log('profile data log', profileData)

    useEffect(() => {
        if (!profileData?.id) {
            setLoadingVideos(false);
            return;
        }

        setLoadingVideos(true);
        eventSource = new EventSource(
            `${scrapServerBasepath}/api/${profileData?.platform}/videos/${profileData?.id}?getDataFromJsonFile=${false}&username=${profileData?.username}&userId=${profileData?.id}&secUid=${profileData?.secUid || ''}`
        );
        console.log("event:: ", eventSource);
        // socket = io(scrapServerBasepath); // Create a socket connection

        console.log("event source res:: ", eventSource);

        eventSource.onmessage = (event) => {
            const newVideo = JSON.parse(event.data);
            setData((prevData) => [...prevData, newVideo]);
            // setLoadingVideos(false);
            dispatch(setReelsData([newVideo]));
            const lastItemDate = new Date(newVideo?.date || newVideo?.['Date Posted']).getTime()
            const thirtyDaysAgo = new Date();
            const currentDate = new Date();
            thirtyDaysAgo.setDate(currentDate.getDate() - 30);
            if (lastItemDate < new Date(thirtyDaysAgo).getTime() && !newVideo?.isPinned   &&  !got30dData) {
                GetAnalyticsData30d()
                setAnalyticsDataLoading30d(false)
                got30dData = true
            }
            // Emit the data to the socket server
            // socket.emit("newVideo", newVideo);
        };

        eventSource.addEventListener("no-more-videos", () => {
            console.log("no more videos");
            if (true) {
                GetAnalyticsData()
            }
            if (!got30dData) {
                GetAnalyticsData30d()
                setAnalyticsDataLoading30d(false)
            }
            setLoadingVideos(false);
            setAnalyticsDataLoading(false)
            dispatch(setReelsFinished(true));
            dispatch(setLoadingReels(false));
            eventSource.close();
        });

        // Clean up on component unmount
        return () => {
            eventSource.close();
            // socket.disconnect(); // Disconnect the socket      
        };
    }, [profileData?.id]);



    useEffect(() => {
        if (profiledata?.videoCount === 0) {
            setLoadingVideos(false);
        }
        setLoadingIndex(0)
        setSequenceCompleted(false)
    }, [profileData]);

    const [sequenceCompleted, setSequenceCompleted] = useState(false);


    useEffect(() => {
        if (!sequenceCompleted) {
            const timer = setInterval(() => {
                setLoadingIndex((prevIndex) => {
                    if (prevIndex + 1 < loadingStates.length) {
                        return prevIndex + 1;
                    } else {
                        setSequenceCompleted(true);
                        clearInterval(timer);
                        return prevIndex;
                    }
                });
            }, 3000); // Change message every 3 seconds

            return () => clearInterval(timer); // Clean up the interval on component unmount
        }
    }, [sequenceCompleted]);


    return (
        <div className='scan-profile-new'>
            <div className={` inner-page-wrapper`}>
                <SideBarComponent isSidebarActive={isSidebarActive} isSidebarMobileActive={isSidebarMobileActive}
                    sidebarMobileCloseHandler={sidebarMobileCloseHandler} />
                <div
                    className={`main-dashboard-wrapper ${isSidebarActive ? 'active' : ''} ${isSidebarMobileActive ? 'show' : ''} `}>
                    {/* <NewHeaderComponent sidebarActiveHandler={sidebarActiveHandler} sidebarMobileActiveHandler={sidebarMobileActiveHandler} handleChange={handleChange} options={options} selectedOption={selectedOption} usernameInput={usernameInput} setUsernameInput={setUsernameInput} handleSearch={HandleSearch}/> */}
                    <HeaderComponent sidebarActiveHandler={sidebarActiveHandler}
                        sidebarMobileActiveHandler={sidebarMobileActiveHandler} handleChange={handleChange}
                        options={options} selectedOption={selectedOption} usernameInput={usernameInput}
                        setUsernameInput={setUsernameInput} handleSearch={HandleSearch} showSearch={analyticsDataLoading30d ? false : true} submittingUsernameLoading={basicUserDataLoading} />
                    <div className="dashboard-inner-page">
                        {analyticsDataLoading30d ? <LoadingScreenComponent completion={loadingStates[loadingIndex]?.completion} heading={loadingStates[loadingIndex]?.heading} text1={loadingStates[loadingIndex]?.text1} text2={loadingStates[loadingIndex]?.text2} /> : <div className="scan-new-profile-inner-content">
                            {/*<BasicProfileInfoNew profileData={profileData} handleDownloadZip={handleDownloadZip} handleDownloadAnalytics={handleDownloadAnalytics} />*/}
                            <ScanUserProfileDashboardInfo profileData={profileData} handleDownloadZip={handleDownloadZip} handleDownloadAnalytics={handleDownloadAnalytics} analyticsData={analyticsData} reelsComplete={reelsComplete} videosData={videosData} loadingVideos={loadingVideos} />
                            <div className="dashboard">
                                <div className="container ex-large">
                                    {/* <div className="user-scan-dashboard-info">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2">
                                                    <div className="user-info-wrapper">
                                                        <div className="img-wrapper">
                                                            <img src={userAvatar} alt="userAvatar"/>
                                                        </div>
                                                        <div className="text-wrapper">
                                                            <strong className='white'>Michael Sanchez</strong>
                                                            <span>@michaelsanchez</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <div className="ratings-wrapper">
                                                        <div className="main-wrapper">
                                                            <div className="name">
                                                                <img src={userCyanIcon} alt="userCyanIcon"/>
                                                                <span>Followers:</span>
                                                            </div>
                                                            <span className="white">14.5k</span>
                                                        </div>
                                                        <div className="main-wrapper">
                                                            <div className="name">
                                                                <img src={cameraCyanIcon} alt="userCyanIcon"/>
                                                                <span>Total videos:</span>
                                                            </div>
                                                            <span className="white">29.2k</span>
                                                        </div>
                                                        <div className="main-wrapper">
                                                            <div className="name">
                                                                <img src={waveIcon} alt="userCyanIcon"/>
                                                                <span>Most viral video:  </span>
                                                            </div>
                                                            <span className="white">1.4m</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-5">
                                                    <div className="actions-main-wrapper">
                                                        <div className="action-wrapper">
                                                            <div className="icon-wrapper">
                                                                <FaFolderPlus/>
                                                            </div>
                                                            <div className="text">
                                                                <span className="strong white">Add To Folder</span>
                                                            </div>
                                                        </div>
                                                        <div className="action-wrapper">
                                                            <div className="icon-wrapper">
                                                                <BiSolidDownload/>
                                                            </div>
                                                            <div className="text">
                                                                <span className="strong white">Download Videos</span>
                                                            </div>
                                                        </div>
                                                        <div className="action-wrapper">
                                                            <div className="icon-wrapper">
                                                                <PiFileArrowDownFill/>
                                                            </div>
                                                            <div className="text">
                                                                <span className="strong white">Download Data</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3">
                                                    <div className="features-wrapper">
                                                        <div className="text-wrapper">
                                                            <h2 className=''>2</h2>
                                                            <div className="name-title">
                                                                <img src={gemBlueIcon} alt="gemBlueIcon"/>
                                                                <span className="small white">Emerging</span>
                                                                <img src={infoIcon} alt="infoIcon"/>
                                                            </div>
                                                        </div>
                                                        <ul className="features-list">
                                                            <li>
                                                                <div className="dot"></div>
                                                                Excellent comment to likes ratio
                                                            </li>
                                                            <li>
                                                                <div className="dot"></div>
                                                                Average audience engagement
                                                            </li>
                                                            <li>
                                                                <div className="dot"></div>
                                                                Followers numbers decline
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                    </div> */}
                                    <div className="video-chart-wrapper">
                                        <div className="video-chart-tabs-wrapper">
                                            <div className="tabs-wrapper cyan-bg">
                                                <ul className="tabs">
                                                    <li><a
                                                        className={`btn-style dark simple tab ${videoChartTab == 'dashboard' ? 'active' : ''}`}
                                                        onClick={() => videoChartTabHandler('dashboard')}>
                                                        <MdInsertChart /> Videos</a></li>
                                                    {/* <li><a
                                                        className={`btn-style dark simple tab ${videoChartTab == 'videos' ? 'active' : ''}`}
                                                        onClick={() => videoChartTabHandler('videos')}> <img
                                                            src={cameraBlackIcon} alt="cameraBlackIcon" /> Videos</a></li> */}
                                                    <li><a
                                                        className={`btn-style dark simple tab ${videoChartTab == 'charts' ? 'active' : ''}`}
                                                        onClick={() => videoChartTabHandler('charts')}> <img
                                                            src={chartsBlackIcon} alt="chartsBlackIcon" /> Data</a></li>
                                                </ul>
                                            </div>
                                            <div className="video-chart-main-wrapper">
                                                {videoChartTab == 'dashboard' &&
                                                    <>
                                                        <div className="average-cards-wrapper">
                                                            {/* <div className="average-text-div">
                                                                <div className="d-flex gap-4 mb-4">
                                                                    <strong className="white">Averages</strong>
                                                                    {(!analyticsData || analyticsData?.error) && <span className='white d-inline-flex gap-2'>  <Spinner animation="grow" size="sm" /><span className=' d-inline-block white'>Loading...</span></span>}

                                                                </div>
                                                            </div> */}
                                                            <DashboardAverageCards analyticsData={analyticsData30d} analyticsDataLoading={analyticsDataLoading30d} />
                                                            {/* <DashboardTotalSmallCharts analyticsData={analyticsData} /> */}
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-9 col-lg-12 mb-4 mb-xl-0">
                                                                <div className="upper-charts-wrapper">
                                                                    <div className="row">
                                                                        <div className="col-xl-7 mb-3 mb-xl-0 col-lg-12 col-md-12">
                                                                            <PostingBehaviousNew
                                                                                analyticsData30d={analyticsData30d} analyticsDataAll={analyticsData} analyticsDataLoading={analyticsDataLoading30d} analyticsDataLoadingAll={analyticsDataLoading} />
                                                                        </div>
                                                                        <div className="col-xl-5 mb-3 mb-xl-0 col-lg-12 col-md-12">
                                                                            <LongVsShortVideos profiledata={profiledata || { platform: "tiktok" }} setVideoChartTab={setVideoChartTab} setChartTabs={setChartTabs} loadingVideos={loadingVideos} />
                                                                            {/*<CircularGappedProress/>*/}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="total-cards-wrapper">

                                                                    <div className="d-flex gap-4 mb-3 align-items-center justify-content-between">
                                                                        <strong className="white">Totals stats</strong>
                                                                        {analyticsDataLoading30d ? <span
                                                                            className='white d-inline-flex gap-2'>  <Spinner
                                                                                animation="grow" size="sm" /><span
                                                                                    className=' d-inline-block white'>Analyzing...</span></span> : <ul className="result">
                                                                            <li style={{ color: 'white' }} className={`${daysFilter1 == 7 ? 'active' : ''}`} onClick={() => setDaysFilter1((pre) => pre == 7 ? null : 7)}>7D</li>
                                                                            <li style={{ color: 'white' }} className={`${daysFilter1 == 30 ? 'active' : ''}`} onClick={() => setDaysFilter1((pre) => pre == 30 ? null : 30)}>30D</li>
                                                                            {/* <li className={`${daysFilter == null ? 'active' : ''}`} onClick={() => setDaysFilter(null)}>All</li> */}
                                                                        </ul>}
                                                                        {!daysFilter1 && analyticsDataLoading && <span
                                                                            className='white d-inline-flex gap-2'>  <Spinner
                                                                                animation="grow" size="sm" /><span
                                                                                    className=' d-inline-block white'>Analyzing...</span></span>}
                                                                    </div>
                                                                    {/*<DashboardTotalSmallCharts/>*/}
                                                                    <div className="total-stats-inner-wrapper">
                                                                        <div className="row">
                                                                            <div className="col-xl-2 col-lg-3 col-md-3">
                                                                                <ul className="lists">
                                                                                    <li>
                                                                                        <button
                                                                                            onClick={() => setTotalStatsChartType('views')}>
                                                                                            <BsFillEyeFill
                                                                                                style={{ color: '#FF6400' }} /> Views
                                                                                        </button>
                                                                                    </li>
                                                                                    <li>
                                                                                        <button
                                                                                            onClick={() => setTotalStatsChartType('comments')}>
                                                                                            <img src={smsPurpleIcon}
                                                                                                alt="chartsBlackIcon" /> Comment
                                                                                        </button>
                                                                                    </li>
                                                                                    <li>
                                                                                        <button
                                                                                            onClick={() => setTotalStatsChartType('shares')}>
                                                                                            <PiShareFatFill
                                                                                                style={{ color: '#5FC754' }} /> Shares
                                                                                        </button>
                                                                                    </li>
                                                                                    <li>
                                                                                        <button
                                                                                            onClick={() => setTotalStatsChartType('bookmarks')}>
                                                                                            <img src={bookmarkCyanIcon}
                                                                                                alt="chartsBlackIcon" /> Bookmarks
                                                                                        </button>
                                                                                    </li>
                                                                                    <li>
                                                                                        <button
                                                                                            onClick={() => setTotalStatsChartType('likes')}>
                                                                                            <img src={heartRedIcon}
                                                                                                alt="chartsBlackIcon" /> Likes
                                                                                        </button>
                                                                                    </li>
                                                                                    <li>
                                                                                        <button
                                                                                            onClick={() => setTotalStatsChartType('all')}>
                                                                                            <IoGrid
                                                                                                style={{ color: 'white' }} /> All
                                                                                        </button>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <div
                                                                                className="col-xl-10 col-lg-9 col-md-9">
                                                                                <div className="total-stats-chart">
                                                                                    <StatsChartComponent
                                                                                        totalStatsChartData={totalStatsChartData}
                                                                                        totalStatsChartYears={totalStatsChartYears}
                                                                                        colors={totalStatsChartType == 'views' ? {
                                                                                            gradient: 'rgba(66, 43, 39, 1)',
                                                                                            borderColor: '#FF6400'
                                                                                        } : totalStatsChartType == 'comments' ? {
                                                                                            gradient: 'rgba(42, 46, 86, 1)',
                                                                                            borderColor: 'rgb(83, 82, 176)'
                                                                                        } : totalStatsChartType == 'shares' ? {
                                                                                            gradient: 'rgba(33, 73, 58, 1)',
                                                                                            borderColor: 'rgb(52, 168, 83)'
                                                                                        } : totalStatsChartType == 'bookmarks' ? {
                                                                                            gradient: 'rgba(25, 47, 62, 1)',
                                                                                            borderColor: 'rgb(52, 168, 83)'
                                                                                        } : totalStatsChartType == 'all' ? {
                                                                                            gradient: '#FFFEFA',
                                                                                            borderColor: '#fff'
                                                                                        } : {
                                                                                            gradient: 'rgba(66, 30, 56, 1)',
                                                                                            borderColor: 'rgba(255, 22, 93, 1)'
                                                                                        }} />
                                                                                    <DoubleLineChart />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3  col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-lg-6 col-md-6 col-xl-12">
                                                                        <div className="common-duration-graph-wrapper">
                                                                            <div className="content-wrapper">
                                                                                <div className="title">
                                                                                    <span className="white strong">Most common durations</span>
                                                                                    {analyticsDataLoading30d ? <span
                                                                                        className='white d-inline-flex gap-2'>  <Spinner
                                                                                            animation="grow" size="sm" /><span
                                                                                                className=' d-inline-block white'>Analyzing...</span></span> : <ul className="result">
                                                                                        <li className={`${daysFilter == 7 ? 'active' : ''}`} onClick={() => setDaysFilter((pre) => pre == 7 ? null : 7)}>7D</li>
                                                                                        <li className={`${daysFilter == 30 ? 'active' : ''}`} onClick={() => setDaysFilter((pre) => pre == 30 ? null : 30)}>30D</li>
                                                                                        {/* <li className={`${daysFilter == null ? 'active' : ''}`} onClick={() => setDaysFilter(null)}>All</li> */}
                                                                                    </ul>}
                                                                                </div>
                                                                                <div className="chart-wrapper">
                                                                                    <HorizontalBarChart2Duration
                                                                                        durationData={analyticsData?.video_duration_data || []} daysFilter={daysFilter} />
                                                                                </div>
                                                                            </div>
                                                                            <a onClick={() => {
                                                                                setVideoChartTab('charts');
                                                                                setChartTabs('characteristics');
                                                                                window.scrollTo({
                                                                                    top: 700,
                                                                                    left: 0,
                                                                                    behavior: 'smooth'
                                                                                });
                                                                            }}
                                                                                className="btn-style simple dark view-full-btn">View
                                                                                Full
                                                                                Data <img src={rightWhiteArrow}
                                                                                    alt="rightWhiteArrow" /></a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-12 col-md-6 col-lg-6">
                                                                        <div className="most-used-hashtags">
                                                                            <div className="content-wrapper">
                                                                                <div className="title">
                                                                                    <span className="white strong">Most used hashtags</span>
                                                                                    {/* <ul className="result">
                                                                                <li className={`${daysFilter1 == 7 ? 'active' : ''}`} onClick={() => setDaysFilter1(7)}>7D</li>
                                                                                <li className={`${daysFilter1 == 30 ? 'active' : ''}`} onClick={() => setDaysFilter1(30)}>30D</li>
                                                                            </ul> */}
                                                                                </div>
                                                                                <div className="chart-wrapper">
                                                                                    <HorizontalBarChart2Hashtags
                                                                                        hashtagsData={analyticsData30d?.hashtags_data?.length > 0 ?  analyticsData30d?.hashtags_data : analyticsData?.hashtags_data || []} />
                                                                                </div>
                                                                            </div>
                                                                            <a onClick={() => {
                                                                                setVideoChartTab('charts');
                                                                                setChartTabs('characteristics');
                                                                                window.scrollTo({
                                                                                    top: 700,
                                                                                    left: 0,
                                                                                    behavior: 'smooth'
                                                                                });
                                                                            }}
                                                                                className="btn-style simple dark view-full-btn">View
                                                                                Full Data <img src={rightWhiteArrow}
                                                                                    alt="rightWhiteArrow" /></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*Video Component*/}

                                                        <>
                                                            <VideosList profileInfo={profileData}
                                                                platform={profileData?.platform}
                                                                username={profileData?.username}
                                                                selectedVideos={selectedVideos}
                                                                setSelectedVideos={setSelectedVideos}
                                                                userData={userData}
                                                                getDataFromJsonFile={false}
                                                                analyticsData={analyticsData}
                                                                videoChartTab={videoChartTab}
                                                                loadingVideos={loadingVideos}
                                                                setLoadingVideos={setLoadingVideos} data={data}
                                                                setData={setData}
                                                                handleDownloadZip={handleDownloadZip} />
                                                        </>
                                                    </>
                                                }
                                                {/* {videoChartTab == 'videos' &&
                                                    <>
                                                        <VideosList profileInfo={profileData}
                                                            platform={profileData?.platform}
                                                            username={profileData?.username}
                                                            selectedVideos={selectedVideos}
                                                            setSelectedVideos={setSelectedVideos}
                                                            userData={userData}
                                                            getDataFromJsonFile={false}
                                                            analyticsData={analyticsData} videoChartTab={videoChartTab} loadingVideos={loadingVideos} setLoadingVideos={setLoadingVideos} data={data} setData={setData} />
                                                    </>
                                                } */}
                                                {videoChartTab == 'charts' &&
                                                    <>
                                                        <Charts chartTabs={chartTabs} setChartTabs={setChartTabs} profileData={profileData} allAnalyticsData={analyticsData} thirtyDaysAnalytics={analyticsData30d} videoChartTab={videoChartTab} setVideoChartTab={setVideoChartTab} analyticsDataLoading={analyticsDataLoading} analyticsDataLoading30d={analyticsDataLoading30d} loadingVideos={loadingVideos} />{/*Charts Component*/}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            {errorModelMessage &&
                <ProfileNotFound message={errorModelMessage} setErrorModelMessage={setErrorModelMessage}
                    CTAText={CTAText} success={success} />}
        </div>
    )
}
