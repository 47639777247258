// ChangingProgressProvider.js
import React, { useState, useEffect } from 'react';

const ChangingProgressProvider = ({ values = [0], children }) => {
    const [valuesIndex, setValuesIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setValuesIndex((prevIndex) => (prevIndex + 1) % values.length);
        }, 1000); // Change value every 1 second

        return () => clearInterval(interval);
    }, [values]);

    return typeof children === 'function' ? children(values[valuesIndex]) : null;
};

export default ChangingProgressProvider;
