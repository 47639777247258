import React, { useRef, useEffect } from 'react';
import { Chart, registerables } from 'chart.js/auto'; // Import Chart object
import zoomPlugin from 'chartjs-plugin-zoom';
import { CgZoomIn } from "react-icons/cg";
import { CgZoomOut } from "react-icons/cg";
import { TbZoomReset } from "react-icons/tb";
Chart.register(...registerables, zoomPlugin);

export default function StatsChartComponent({ totalStatsChartData, totalStatsChartYears, colors }) {
    const chartRef = useRef(null);
    useEffect(() => {
        // Create gradient
        const canvas = chartRef.current;
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 400); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, colors?.gradient || 'rgba(66, 43, 39, 1)'); // Adjust as needed

        // Apply gradient as background color
        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: totalStatsChartYears,
                datasets: [
                    {
                        label: 'My second dataset',
                        backgroundColor: gradient,
                        borderColor: colors?.borderColor || '#FF6400',
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
                        data: totalStatsChartData,
                        lineTension: 0.4, // Adjust the tension value for smoothness
                        zIndex: 1,
                    }
                ]
            },
            options: {
                maintainAspectRatio: true,
                aspectRatio: 0,
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    zoom: {
                        pan: {
                            enabled: true,
                            mode: 'xy'
                        },
                        zoom: {
                            wheel: {
                                enabled: false
                            },
                            pinch: {
                                enabled: true
                            },
                            mode: 'xy'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            beginAtZero: true,
                        },
                        grid: {
                            display: false,
                            z: 99,
                        }
                    },
                    y: {
                        ticks: {
                            display: false // Hide the ticks (labels) on the x-axis
                        },
                        grid: {
                            display: true,
                            color: 'rgba(100, 111, 124, .2)',
                            zIndex: 9999,
                            drawOnChartArea: true,
                        },
                    }
                },

            },
        });

        chartRef.current.chartInstance = chartInstance;

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [totalStatsChartData, totalStatsChartYears]);

    return (
        <div className='graph-chart-wrapper with-magnifier-icons'>
            <canvas id="lineChart" ref={chartRef} />
            <ul className="magnifiers-list">
                <li><a onClick={() => chartRef.current.chartInstance.zoom(1.1)}><CgZoomIn/></a>                </li>
                <li><a onClick={() => chartRef.current.chartInstance.zoom(0.9)}><CgZoomOut/></a>                </li>
                <li><a onClick={() => chartRef.current.chartInstance.resetZoom()}><TbZoomReset/></a>                </li>
            </ul>
        </div>
    );
}
