import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto'; // Import Chart object
import ShortenNumber from '../utilities/ShortenNumber';

export default function TotalSharesChart({ analyticsData }) {
    const [sharesTimeSeries, setSharesTimeSeries] = useState([]);

    // useEffect(() => {
    //     if (!analyticsData) return;

    //     let sharesTimeSeries = analyticsData.shares_timeseries || [];

    //     if (sharesTimeSeries.length > 100) {
    //         const sharesTimeSeriesByMonth = {};

    //         sharesTimeSeries?.forEach((shares) => {
    //             const date = new Date(shares['Date Posted']);
    //             const month = date.toLocaleString('default', { month: 'short' });
    //             const year = date.getFullYear();
    //             const monthYear = `${month}-${year}`;

    //             if (sharesTimeSeriesByMonth[year]) {
    //                 sharesTimeSeriesByMonth[year].Shares += shares.Shares;
    //             } else {
    //                 sharesTimeSeriesByMonth[year] = {
    //                     Shares: shares.Shares,
    //                     'Date Posted': year
    //                 };
    //             }
    //         });

    //         sharesTimeSeries = Object.values(sharesTimeSeriesByMonth);
    //     }

    //     setSharesTimeSeries(sharesTimeSeries);
    //     console.log('shares timeseries ', sharesTimeSeries)



    // }, [analyticsData])

    useEffect(() => {
        if (!analyticsData) return;

        let sharesTimeSeries = analyticsData.shares_timeseries || [];

        if (sharesTimeSeries.length > 100) {
            const sharesTimeSeriesByMonth = {};

            sharesTimeSeries.forEach((shares) => {
                const date = new Date(shares['Date Posted']);
                const month = date.toLocaleString('default', { month: 'short' });
                const year = date.getFullYear();
                const monthYear = `${month}-${year}`;

                if (sharesTimeSeriesByMonth[monthYear]) {
                    sharesTimeSeriesByMonth[monthYear].Shares += shares.Shares;
                } else {
                    sharesTimeSeriesByMonth[monthYear] = {
                        Shares: shares.Shares,
                        'Date Posted': monthYear
                    };
                }
            });

            sharesTimeSeries = Object.values(sharesTimeSeriesByMonth);
        }

        setSharesTimeSeries(sharesTimeSeries);
    }, [analyticsData]);

    useEffect(() => {
        // Create gradient
        const canvas = document.getElementById('totalShares');
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 100); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, 'rgba(95, 199, 84, 0)'); // A djust as needed
        // rgba(231, 173, 90, .4)

        // Apply gradient as background color
        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: sharesTimeSeries?.map((share) => share['Date Posted']),
                datasets: [
                    {
                        label: 'Dataset',
                        backgroundColor: gradient,
                        borderColor: '#5FC754',
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
                        // data: [20,15,30,40,34,30,20],
                        data: sharesTimeSeries?.map((share) => (share?.Shares?.toFixed(0))),
                        lineTension: 0.4 // Adjust the tension value for smoothness
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false, // Disable the tooltip
                    },
                },
                scales: {
                    // yAxes: [{
                    //     ticks: {
                    //         beginAtZero: true
                    //     }
                    // }],
                    x: {
                        ticks: {
                            display: false // Hide the ticks (labels) on the x-axis
                        },
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        ticks: {
                            display: false, // Hide the ticks (labels) on the x-axis
                            beginAtZero: true,
                            callback: function (value) {
                                return ShortenNumber(value);
                            },
                        },
                        grid: {
                            display: false
                        }
                    }
                }
            }
        });

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [analyticsData, sharesTimeSeries]);

    return (
        <div className='graph-chart-wrapper'>
            <canvas id="totalShares" height={25} width='100%' />
        </div>
    );
}