import React from 'react'
import { Spinner } from 'react-bootstrap'

const PostFrequency = ({ profileData, postFrequencyPerDay, postFrequencyPerWeek, infoQuestionMark, postFrequencyPerMonth, postFrequencyPerYear, analyticsDataLoading }) => {
  return (
    <div className="chart-content-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-xl-7">
            <div className="title">
              <strong className="white">Post Frequency</strong>
              {analyticsDataLoading && <span
                className='white d-inline-flex gap-2'>  <Spinner
                  animation="grow" size="sm" /><span
                    className=' d-inline-block white'>Analyzing...</span></span>}
            </div>
            <div className="text">
              <span>
                Analyzing Post Frequency: Investigating How Regularly @
                {profileData?.username} Shares Content with his Audience
                and Its Impact on Engagement and Reach
              </span>
            </div>
          </div>
          <div className="col-md-5"></div>
        </div>
      </div>
      <div className="post-frequency-cards-wrapper">
        <div className="post-frequency-card">
          <div className="title">
            <span className="strong">Per Day</span>
          </div>
          <div className="content">
            <h3>{postFrequencyPerDay}</h3>
            {/*  <div className="text">
            <div className="dot"></div>
            <span className="ex-small">Average</span>
            <img src={infoQuestionMark} alt="infoQuestionMark" />
          </div>*/}
          </div>
        </div>
        <div className="post-frequency-card">
          <div className="title">
            <span className="strong">Per Week</span>
          </div>
          <div className="content">
            <h3>{postFrequencyPerWeek}</h3>
            {/* <div className="text">
            <div className="dot"></div>
            <span className="ex-small">Average</span>
            <img src={infoQuestionMark} alt="infoQuestionMark" />
          </div>*/}
          </div>
        </div>
        <div className="post-frequency-card">
          <div className="title">
            <span className="strong">Per Month</span>
          </div>
          <div className="content">
            <h3>{postFrequencyPerMonth}</h3>
            {/*   <div className="text">
            <div className="dot"></div>
            <span className="ex-small">Average</span>
            <img src={infoQuestionMark} alt="infoQuestionMark" />
          </div>*/}
          </div>
        </div>
        <div className="post-frequency-card">
          <div className="title">
            <span className="strong">Per Year</span>
          </div>
          <div className="content">
            <h3>{postFrequencyPerYear}</h3>
            {/*  <div className="text">
            <div className="dot"></div>
            <span className="ex-small">Average</span>
            <img src={infoQuestionMark} alt="infoQuestionMark" />
          </div>*/}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostFrequency
