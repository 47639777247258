// src/DoubleLineChart.js
import React from 'react';
import {Line} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

// Register the necessary components with Chart.js
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

const DoubleChart = ({dataset, analyticsData, filterType, colors}) => {
    console.log('dataset ', dataset)
    const data = {
        labels: [...new Set([...(dataset[0]?.labels || []), ...(dataset[1]?.labels || [])])].sort(),
        datasets: [
            {
                label: 'Long',
                data: dataset[1]?.data,
                borderColor: colors?.border2,
                backgroundColor: 'rgba(66, 30, 56, 1)',
                fill: false,
                lineTension: 0.4,
                pointRadius: 0, // Set the radius of circular points
                pointHoverRadius: 0,
            },
            {
                label: 'Short',
                data: dataset[0]?.data,
                borderColor: colors?.border1,
                backgroundColor: 'rgba(25, 47, 62, 1)',
                fill: false,
                lineTension: 0.4,
                pointRadius: 0, // Set the radius of circular points
                pointHoverRadius: 0,
            },
        ],
    };

    const options = {
        maintainAspectRatio: true,
        aspectRatio: 0,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: false,
            },
            title: {
                display: false,
                text: 'Double Line Chart Example',
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#D9D9D9',
                },
                grid: {
                    display: false,
                    color: '#ffffff',
                },
            },
            y: {
                ticks: {
                    maxTicksLimit: 5,
                    color: '#D9D9D9',
                },
                grid: {
                    display: true,
                    color: 'rgba(100, 111, 124, .2)',
                },
            },
        },
    };

    return (
        <div className='graph-chart-wrapper'>
            <Line data={data} options={options} className='content-characteristic-chart'/>
        </div>
    )
};

export default DoubleChart;
