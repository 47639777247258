// settingsActions.js
import { ApiRequests } from '../services/apiRequests';

export const fetchSettings = ({accessToken}) => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_SETTINGS_REQUEST' });
  try {
    const { accessToken, profileData } = getState();
    const settings = await ApiRequests.getSettings({
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    });
    dispatch({ type: 'FETCH_SETTINGS_SUCCESS', payload: settings?.data });
  } catch (error) {
    dispatch({ type: 'FETCH_SETTINGS_FAILURE', payload: error.message });
  }
};

