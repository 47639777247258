import React from "react";

// **************************** Images *****************************

import profileNotFoundIcon from "../assets/images/icons/profile-not-found-icon.png";
import errorMsgIcon from "../assets/images/icons/erroe-msg-icon.png";
import successIcon from "../assets/images/icons/success.png";
import closeIcon from "../assets/images/icons/close-icon.png";
export default function ProfileNotFound({message, setErrorModelMessage, CTAText, success}){
    return(
        <div className='profile-not-found'>
            <div className="img-wrapper">
                <img src={success ? successIcon : errorMsgIcon} alt="errorMsgIcon"/>
            </div>
            <div className="text-wrapper">
                 <strong>{CTAText || ' '}</strong>
                {/* <strong>{CTAText}</strong> */}
                 <span className="small">{message}</span>
                {/*<span className="small">{message}</span>*/}
                <img onClick={() => setErrorModelMessage('')} src={closeIcon} alt="closeIcon" className='closeIcon'/>
            </div>
        </div>
    )
}