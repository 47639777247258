import React from 'react';
import VideoComponent from "./VideoComponent";
import videoImg1 from "../assets/images/video-img-1.png";
import {FaCalendarAlt, FaPlay} from "react-icons/fa";
import {BsFillHeartFill} from "react-icons/bs";
import {HiMiniChatBubbleOvalLeftEllipsis} from "react-icons/hi2";
import {RiBookmarkFill} from "react-icons/ri";
import {PiShareFatFill} from "react-icons/pi";
import {GoClockFill} from "react-icons/go";

export default function VideosSection({data, platform, selectedVideos, setSelectedVideos}){
    return (
        <div className="videos-section">
            <div className="container-fluid">
                <div className="main-wrapper">
                    {/* <VideoComponent/>
                    <VideoComponent/>
                    <VideoComponent/>
                    <VideoComponent/>
                    <VideoComponent/>
                    <VideoComponent/>
                    <VideoComponent/>
                    <VideoComponent/>
                    <VideoComponent/> */}
                    {/* <div className="video-wrapper">
                        <div className="img-wrapper">
                            <a href='#' target="_blank"><img src={videoImg1} alt="videoImg1"/></a>
                        </div>
                        <div className="content-wrapper">
                            <ul className="list left">
                                <li>
                                    <div className="icon">
                                        <a><FaCalendarAlt/></a>
                                        <span
                                            className=" white">3/21/2024</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <a><FaPlay/></a>
                                        <span
                                            className=" white">278,9K</span>
                                    </div>
                                </li>
                            </ul>
                            <ul className="list right">
                                <li>
                                    <div className="icon">
                                        <a><BsFillHeartFill/></a>
                                        <span
                                            className=" white">250,5K</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <a href="#"><HiMiniChatBubbleOvalLeftEllipsis/></a>
                                        <span
                                            className=" white">100K</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <a><RiBookmarkFill/></a>
                                        <span
                                            className=" white">89K</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <a><PiShareFatFill/></a>
                                        <span
                                            className=" white">132,5K</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <a><GoClockFill/></a>
                                        <span
                                            className=" white">00:32</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="video-wrapper">
                        <div className="img-wrapper">
                            <a href='#' target="_blank"><img src={videoImg1} alt="videoImg1"/></a>
                        </div>
                        <div className="content-wrapper">
                            <ul className="list left">
                                <li>
                                    <div className="icon">
                                        <a><FaCalendarAlt/></a>
                                        <span
                                            className=" white">3/21/2024</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <a><FaPlay/></a>
                                        <span
                                            className=" white">278,9K</span>
                                    </div>
                                </li>
                            </ul>
                            <ul className="list right">
                                <li>
                                    <div className="icon">
                                        <a><BsFillHeartFill/></a>
                                        <span
                                            className=" white">250,5K</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <a href="#"><HiMiniChatBubbleOvalLeftEllipsis/></a>
                                        <span
                                            className=" white">100K</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <a><RiBookmarkFill/></a>
                                        <span
                                            className=" white">89K</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <a><PiShareFatFill/></a>
                                        <span
                                            className=" white">132,5K</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <a><GoClockFill/></a>
                                        <span
                                            className=" white">00:32</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                    {data?.map((item, index) => (
                        <VideoComponent key={index} item={item} platform={platform} selectedVideos={selectedVideos}
                                        setSelectedVideos={setSelectedVideos}/>
                    ))}
                </div>
            </div>
        </div>
    )
}