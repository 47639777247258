
// import React, {useEffect, useRef, useState} from 'react';
// import Chart from 'chart.js/auto'; // Import Chart object

// export default function LineChartsComments({ analyticsData }) {
//     const [commentsTimeSeries, setCommentsTimeSeries] = useState([]);
//     const canvasRef = useRef(null);
//     useEffect(() => {
//       if (!analyticsData) return
//       var commentsTimeSeries = (analyticsData?.comments_timeseries)
//       //if commentsTimeSeries length is bigger than 100, calculate the total views  for each month, instad hcing the data for each day 
//       if (commentsTimeSeries?.length > 100) {
  
//         var commentsTimeSeriesByMonth = {}
//         commentsTimeSeries.forEach((comments) => {
  
//           var date = new Date(comments['Date Posted'])
//           var month = date.getMonth()
//           //get month word 
//           month = new Date(date.getFullYear(), month, 1).toLocaleString('default', { month: 'short' })
//           var year = date.getFullYear()
//           var monthYear = month + '-' + year
//           if (commentsTimeSeriesByMonth[monthYear]) {
//             commentsTimeSeriesByMonth[monthYear].Comments += comments.Comments
//           } else {
//             commentsTimeSeriesByMonth[monthYear] = {
//               Comments: comments.Comments,
//               'Date Posted': monthYear
//             }
//           }
//         }
//         )
  
//         commentsTimeSeries = Object.values(commentsTimeSeriesByMonth)
//       }
//       setCommentsTimeSeries(commentsTimeSeries)
  
  
  
//     }, [analyticsData])
//     useEffect(() => {
//         // Create gradient
//         const canvas = canvasRef.current;
//         const ctx = canvas.getContext('2d');
//         const gradient = ctx.createLinearGradient(0, 0, 0, '400'); // Adjust according to your chart size
//         gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
//         gradient.addColorStop(0, 'rgba(21, 69, 80, 1)'); // A djust as needed

//         // Apply gradient as background color
//         const chartInstance = new Chart(ctx, {
//             type: 'line',
//             data: {
//                 labels: commentsTimeSeries.map((share) => share['Date Posted']),
//                 datasets: [
//                     {
//                         label: 'Dataset',
//                         backgroundColor: gradient,
//                         borderColor: '#00F2EA',
//                         borderWidth: 2,
//                         fill: true,
//                         pointBackgroundColor: 'rgba(0, 0, 0, 0)',
//                         pointBorderColor: 'rgba(0, 0, 0, 0)',
//                         pointHoverBackgroundColor: '#fff',
//                         pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
//                         data: commentsTimeSeries?.map((comment) => (comment?.Comments?.toFixed(0))),
//                         lineTension: 0.4, // Adjust the tension value for smoothness
//                         zIndex: 1,
//                     }
//                 ]
//             },
//             options: {
//                 plugins: {
//                     legend: {
//                         display: false,
//                     }
//                 },
//                 scales: {
//                     // yAxes: [{
//                     //     ticks: {
//                     //         beginAtZero: true
//                     //     }
//                     // }],
//                     // xAxes: [{
//                     //     gridLines: {
//                     //         z: 99
//                     //     }
//                     // }],
//                     x: {
//                         // ticks: {
//                         //     display: false // Hide the ticks (labels) on the x-axis
//                         // },
//                         grid: {
//                             display: false,
//                             z: 99
//                         }
//                     },
//                     y: {
//                         ticks: {
//                             beginAtZero: true
//                         },
//                         grid: {
//                             display: true,
//                             color: 'rgba(100, 111, 124, .2)',
//                             zIndex: 9999,
//                             drawOnChartArea: true,
//                         },
//                     }
//                 },

//             },
//         });

//         return () => {
//             chartInstance.destroy(); // Cleanup chart instance when component unmounts
//         };
//     }, [analyticsData, commentsTimeSeries]);

//     return (
//         <div className='graph-chart-wrapper'>
//             <canvas ref={canvasRef}/>
//         </div>
//     );
// }


import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto'; // Import Chart object
import ShortenNumber from '../utilities/ShortenNumber';

export default function LineChartsComments({ analyticsData }) {
    const [commentsTimeSeries, setCommentsTimeSeries] = useState([]);
    const canvasRef = useRef(null);

    // useEffect(() => {
    //     if (!analyticsData) return;

    //     var commentsTimeSeries = analyticsData?.comments_timeseries;
    //     var commentsTimeSeriesByYear = {};

    //     commentsTimeSeries?.forEach((comments) => {
    //         var date = new Date(comments['Date Posted']);
    //         var year = date.getFullYear();

    //         if (commentsTimeSeriesByYear[year]) {
    //             commentsTimeSeriesByYear[year].totalComments += comments.Comments;
    //             commentsTimeSeriesByYear[year].count += 1;
    //         } else {
    //             commentsTimeSeriesByYear[year] = {
    //                 totalComments: comments.Comments,
    //                 count: 1
    //             };
    //         }
    //     });

    //     var averagedCommentsTimeSeries = Object.entries(commentsTimeSeriesByYear).map(([year, data]) => ({
    //         year: year,
    //         averageComments: (data.totalComments / data.count).toFixed(0)
    //     }));

    //     setCommentsTimeSeries(averagedCommentsTimeSeries);

    // }, [analyticsData]);

    useEffect(() => {
        if (!analyticsData) return;
    
        const commentsTimeSeries = analyticsData?.comments_timeseries;
        const commentsTimeSeriesByMonth = {};
    
        commentsTimeSeries?.forEach((comments) => {
            const date = new Date(comments['Date Posted']);
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // getMonth() returns 0-11, so add 1 to make it 1-12
            const yearMonth = `${year}-${month.toString().padStart(2, '0')}`; // Format as YYYY-MM
    
            if (commentsTimeSeriesByMonth[yearMonth]) {
                commentsTimeSeriesByMonth[yearMonth].totalComments += comments.Comments;
                commentsTimeSeriesByMonth[yearMonth].count += 1;
            } else {
                commentsTimeSeriesByMonth[yearMonth] = {
                    totalComments: comments.Comments,
                    count: 1
                };
            }
        });
    
        const averagedCommentsTimeSeries = Object.entries(commentsTimeSeriesByMonth).map(([yearMonth, data]) => ({
            year: yearMonth,
            averageComments: (data.totalComments / data.count).toFixed(0)
        }));
    
        setCommentsTimeSeries(averagedCommentsTimeSeries);
    
    }, [analyticsData]);
    

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 400); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, '#A162F700'); // Adjust as needed

        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: commentsTimeSeries?.map((data) => data.year),
                datasets: [
                    {
                        label: 'Average Comments per Year',
                        backgroundColor: gradient,
                        borderColor: '#A162F7',
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
                        data: commentsTimeSeries.map((data) => data.averageComments),
                        lineTension: 0.4, // Adjust the tension value for smoothness
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    x: {
                        ticks : {
                            color:'#D9D9D9',
                        },
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        ticks: {
                            color:'#D9D9D9',
                            beginAtZero: true,
                            callback: function (value) {
                                return ShortenNumber(value);
                            },
                            maxTicksLimit:5
                        },
                        grid: {
                            display: true,
                            color: 'rgba(100, 111, 124, .2)',
                        },
                    }
                },
            },
        });

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [commentsTimeSeries]);

    return (
        <div className='graph-chart-wrapper'>
            <canvas ref={canvasRef} />
        </div>
    );
}
