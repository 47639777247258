import { toast } from "react-toastify";
import apiService from "./apiService";
import { APIurls, basePath, paymentAPIurls } from "./constents";
import payment from "./payment";

import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: basePath, // Set your base URL here
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  async (response) => {
    // Check if the response status is 401
    if (response.status === 401) {
      try {
        const refreshToken = localStorage.getItem("app-refresh-token");

        await ApiRequests.authenticate({ refreshToken });

        // Once authentication is successful, retry the original request
        return axiosInstance(response.config);
      } catch (error) {
        console.error("Authentication failed:", error);
        throw error;
      }
    }
    // For other response status codes, return the response as is
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const ApiRequests = {
  register: async (data) =>
    await apiService.post(`${basePath}/${APIurls.register}`, data),
  login: async (data) =>
    await apiService.post(`${basePath}/${APIurls.login}`, data),
  authenticate: async (data) =>
    await apiService.get(`${basePath}/${APIurls.authenticate}`),
  logout: async (data) =>
    await apiService.post(`${basePath}/${APIurls.logout}`, data),
  forget: async (data) =>
    await apiService.post(`${basePath}/${APIurls.forget}`, data),
  reset: async (data) =>
    await apiService.post(
      `${basePath}/${APIurls.reset}`,
      { password: data.password },
      {
        params: { token: data.token },
      }
    ),
  getUserProfile: async ({ data, headers }) => {
    try {
      const response = await apiService.get(basePath + APIurls.user + '/profile', data, { headers })

      return response
    } catch (error) {
      console.error('Error verifying token:', error)
      throw error
    }
  },
  getCurrentUser: async () => {
    try {
      const response = await apiService.get(basePath  + '/auth/current_user',{
        withCredentials: true
      })

      return response
    } catch (error) {
      console.error('Error verifying token:', error)
      throw error
    }
  },
  updateProfile: async ({ data, headers }) => {
    try {
      const response = await apiService.patch(basePath + APIurls.user + '/profile', data, { headers })

      return response
    } catch (error) {
      console.error('Error verifying token:', error)
      throw error
    }
  },
  getUserSubscription: async ({ id, headers }) => {
    console.log('id is ', id)
    try {
      const response = await axios.get(basePath + APIurls.subscription + '/user/get/user-sub', { headers })

      return response
    } catch (error) {
      console.error('Error getting user subscription:', error)
    }
  },
  sendMessage: async ({ data, headers }) => {
    console.log('headers is ', headers)
    try {
      const response = await axios.post(basePath + APIurls.contact, { name: data?.contactName, message: data?.contactMessage, email: data?.contactEmail }, { headers })

      return response
    } catch (error) {
      console.error('Error sending message:', error)
    }
  },
  changePassword: async ({ data, headers }) => {
    try {
      const response = await apiService.patch(basePath + APIurls.user + '/profile/chage-password', data, { headers })

      return response
    } catch (error) {
      console.error('Error verifying token:', error)
      throw error
    }
  },
  SaveSearchHistory: async (data, token) =>
    await apiService.post(`${basePath}/${APIurls.history}`, data.data, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    }),

  subscription: async (data, token) => {
    const response = await apiService.patch(
      `${basePath}/${APIurls.subscription}/update-subscription`,
      data,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );

    return response
  },
  pauseSubscription: async ({ id, pauseReason, headers }) => {
    try {
      const response = await apiService.patch(
        basePath + APIurls.subscription + '/pause-subscription',
        { subscriptionId: id, reason: pauseReason },
        {
          headers
        }
      )

      return response
    } catch (error) {
      console.error('Error while pausing subscription', error)
      if (error?.response && error?.response?.data && error?.response?.data?.message) {
        toast.error(error?.response?.data?.message)
      }
    }
  },
  unpauseSubscription: async ({ id, headers }) => {
    try {
      const response = await apiService.patch(
        basePath + APIurls.subscription  + '/unpause-subscription',
        { subscriptionId: id },
        {
          headers
        }
      )

      return response
    } catch (error) {
      console.error('Error :', error)
    }
  },
  cancelSubscription: async ({ id, cancelReason, headers }) => {
    try {
      const response = await apiService.delete(basePath + APIurls.subscription + '/cancelled-subscription?id=' + id + `reason=` + cancelReason, {
        headers
      })

      return response
    } catch (error) {
      console.error('Error cancelling subscription:', error)
      if (error?.response && error?.response?.data && error?.response?.data?.message) {
        toast.error(error?.response?.data?.message)
      }
    }
  },
  getProducts: async (data) => {
    const response = await apiService.get(
      `${basePath}/${APIurls.subscription}/packeges?store_id=${data.store_id}`,
      {
        headers: {
          Authorization: `bearer ${data.token}`,
        },
      }
    );
    return response
  },
  getProduct: async (data) => {
    const response = await apiService.get(
      `${basePath}/${APIurls.subscription}/packeges/${data.id}`,
      {
        headers: {
          Authorization: `bearer ${data.token}`,
        },
      }
    );
    return response
  },
  getLimits: async (data) => {
    try {
      const response = await apiService.get(
        `${basePath}/video-mimits/get?name=${data}`,
        {
          headers: {
            Authorization: `bearer ${data.token}`,
          },
        }
      );
      return response
    } catch (error) {
      console.log('error ', error.message)
    }
  },
  getUserBoard: async (token) => {
    const response = await apiService.get(
      `${basePath}/boards/user`,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
    return response
  },
  getSharedBoard: async (id, token) => {
    const response = await apiService.get(
      `${basePath}/boards/shared/${id}/`,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
    return response
  },


  getUserVideoBoard: async ({ boardId, token, ...params }) => {
    const response = await apiService.get(
      `${basePath}/boards/videos/user/?boardId=${boardId}`,
      {
        params,
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
    return response
  },
  getSharedVideoBoard: async ({ boardId, videoid, token, ...params }) => {
    console.log('PARAMS ', params);

    // Construct the base URL without videoid
    let url = `${basePath}/boards/videos/shared/?boardId=${boardId}`;

    // Add videoid to the URL if it is defined
    if (videoid !== undefined) {
      url += `&videoid=${videoid}`;
    }

    const response = await apiService.get(url, {
      params,
      headers: {
        Authorization: `bearer ${token}`,
      },
    });

    return response;
  },

  getAllDownloads: async ({ params, headers }) => {
    try {
      const response = await apiService.get(basePath + APIurls.downloads, { params, headers })

      return response
    } catch (error) {
      console.log('Error while fetching downloads: ', error)
    }
  },



  getAllScrapHistory: async ({ params, headers }) => {
    try {
      const response = await apiService.get(basePath + APIurls.profileScraps, { params, headers })

      return response
    } catch (error) {
      console.log('Error while fetching history: ', error)
    }
  },


  updateSetting: async ({ data, headers }) => {
    try {
      const response = await apiService.post(
        basePath + APIurls.settings + '/',
        data,
        {
          headers
        }
      )

      return response
    } catch (error) {
      console.error('Error while updating settings', error)
      if (error?.response && error?.response?.data && error?.response?.data?.message) {
        toast.error(error?.response?.data?.message)
      }
    }
  },
  getSettings: async ({  headers }) => {
    try {
      const response = await apiService.get(
        basePath + APIurls.settings + '/user-settings',
        {
          headers
        }
      )

      return response
    } catch (error) {
      console.error('Error while getting settings', error)
      if (error?.response && error?.response?.data && error?.response?.data?.message) {
        console.log(error?.response?.data?.message)
      }
    }
  },

  createBoardVideo: async ({ token, data }) => {
    try {
      const response = await apiService.post(
        `${basePath}/boards/videos`,
        data,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      console.log('response in save ', response)
      toast.success("Video Saved Successfully")
      return response
    } catch (error) {
      // console.log('error ', error.message)
      // toast.error(error.message)
      if (error.response && error.response.status === 400) {
        // If the error has a response and the status code is 400, display the specific error message
        const errorMessage = error.response.data && error.response.data.message ? error.response.data.message : 'Bad Request';
        console.log('API error message:', errorMessage);
        toast.error(errorMessage);
      } else {
        // For other types of errors, display a generic message
        toast.error(error.message);
      }
    }
  },
  subscription: async (data, token) => {
    const response = await apiService.patch(
      `${basePath}/${APIurls.subscription}/update-subscription`,
      data,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );

    return response
  },
  getProducts: async (data) => {
    const response = await apiService.get(
      `${basePath}/${APIurls.subscription}/packeges?store_id=${data.store_id}`,
      {
        headers: {
          Authorization: `bearer ${data.token}`,
        },
      }
    );
    return response
  },
  getProduct: async (data) => {
    const response = await apiService.get(
      `${basePath}/${APIurls.subscription}/packeges/${data.id}`,
      {
        headers: {
          Authorization: `bearer ${data.token}`,
        },
      }
    );
    return response
  },

  // reset: async (data, token) => await apiService.post(`${basePath}/${APIurls.reset }` + "?token=" + token, data, {withCredentials: true,}),
};



export const paymentApiRequests = {
  getStores: async (data) => await payment.get(paymentAPIurls.getStores),
  getProducts: async ({ data, store_id }) =>
    await payment.get(
      `${paymentAPIurls.getProducts}?filter[store_id]=${store_id}`
    ),
  getProduct: async ({ id, store_id }) =>
    await payment.get(`${paymentAPIurls.getProducts}/${id}`),
  checkOut: async (data) =>
    await payment.post(paymentAPIurls.checkouts, data, {
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
      },
    }),
};
