// import React from 'react';

// import Form from 'react-bootstrap/Form';
// import { MdKeyboardArrowLeft } from "react-icons/md";
// import { MdOutlineKeyboardArrowRight } from "react-icons/md";

// export default function Pagination(){
//     return(
//         <div className='pagination-component'>
//             <div className="container-fluid">
//                 <div className="row">
//                     <div className="col-md-6 align-self-center">
//                         <div className="select-wrapper">
//                             <p>Rows per page: </p>
//                             <Form.Select aria-label="Default select example">
//                                 <option>10</option>
//                                 <option value="1">10</option>
//                                 <option value="2">20</option>
//                                 <option value="3">30</option>
//                             </Form.Select>
//                         </div>
//                     </div>
//                     <div className="col-md-6 align-self-center">
//                         <ul className="pagination-list">
//                             <li><a href="#"><MdKeyboardArrowLeft/></a></li>
//                             <li><a href="#" className='active'>1</a></li>
//                             <li><a href="#">2</a></li>
//                             <li><a href="#">3</a></li>
//                             <li><a href="#"><MdOutlineKeyboardArrowRight/></a></li>
//                         </ul>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }



// import React from 'react';
// import Form from 'react-bootstrap/Form';
// import { MdKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

// export default function Pagination({ paginationModel, setPaginationModel, data }) {
//     const pageNumbers = [];
//     for (let i = 1; i <= data?.totalPages; i++) {
//         pageNumbers.push(i);
//     }

//     return (
//         <div className='pagination-component'>
//             <div className="container-fluid">
//                 <div className="row">
//                     <div className="col-md-6 align-self-center">
//                         <div className="select-wrapper">
//                             <p>Rows per page: </p>
//                             <Form.Select aria-label="Default select example" value={limit}>
//                                 <option>10</option>
//                                 <option value="10">10</option>
//                                 <option value="20">20</option>
//                                 <option value="30">30</option>
//                             </Form.Select>
//                         </div>
//                     </div>
//                     <div className="col-md-6 align-self-center">
//                         <ul className="pagination-list">
//                             <li><a href="#"><MdKeyboardArrowLeft /></a></li>
//                             {pageNumbers.map((number) => (
//                                 <li key={number} className={page === number ? 'active' : ''}>
//                                     <a href="#">{number}</a>
//                                 </li>
//                             ))}
//                             <li><a href="#"><MdOutlineKeyboardArrowRight /></a></li>
//                         </ul>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }



import React from 'react';
import Form from 'react-bootstrap/Form';
import { MdKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

export default function Pagination({ paginationModel, setPaginationModel, data }) {
    // Destructure page and pageSize from paginationModal
    const { page, pageSize } = paginationModel;

    const pageNumbers = [];
    for (let i = 1; i <= data?.totalPages; i++) {
        pageNumbers.push(i);
    }

    const handlePageChange = (pageNumber) => {
        setPaginationModel({ ...paginationModel, page: pageNumber });
    };

    return (
        <div className='pagination-component'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 align-self-center">
                        <div className="select-wrapper">
                            <p>Rows per page: </p>
                            <Form.Select
                                aria-label="Default select example"
                                value={pageSize}
                                onChange={(e) => setPaginationModel({ ...paginationModel, pageSize: e.target.value })}
                            >
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </Form.Select>
                        </div>
                    </div>
                    <div className="col-md-6 align-self-center">
                        <ul className="pagination-list">
                            <li><a onClick={() => handlePageChange(page - 1)} disabled={page === 1}><MdKeyboardArrowLeft /></a></li>
                            {pageNumbers.map((number) => (
                                <li key={number} className={page === number ? 'active' : ''}>
                                    <a style={{cursor:'pointer'}} onClick={() => handlePageChange(number)}>{number}</a>
                                </li>
                            ))}
                            <li><a onClick={() => handlePageChange(page + 1)} disabled={page === data?.totalPages}><MdOutlineKeyboardArrowRight /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
